import React from 'react'
import useGramElemTypeHelp from './HelpHooks/useGramElemTypeHelp'
import useSentPartTypeHelp from './HelpHooks/useSentPartTypeHelp'
import useGramElemCatTypeHelp from './HelpHooks/useGramElemCatTypeHelp'
import useGamesTypeHelp from './HelpHooks/useGamesTypeHelp'
import { helpName } from './HelpHooks/helpData'
import { Link } from "react-router-dom";
import { gramElemTypeHelp, sentPartTypeHelp, gramElemCatTypeHelp, gamesTypeHelp } from 'components/Help/Help'
import styled from 'styled-components'


const HelpIndexStyle = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  padding: 0px 20px;
  margin: 30px 0px;
  overflow: auto;
`;

const Title = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
`

const LinkStyle = styled(Link)`
  font-family: 'Roboto', sans-serif;  
  padding: 0px 10px;
`;


const HelpIndex = ({helpType}) => {

  const gramElemTypeHelpItems = useGramElemTypeHelp()
  const sentPartTypeHelpItems = useSentPartTypeHelp()
  const gramElemCatTypeHelpItems = useGramElemCatTypeHelp()
  const gamesTypeHelpItems = useGamesTypeHelp()

  const getIndexTitle = () => {
  	let helpTypeTitle = ""

  	switch (helpType) {
  	  case gramElemTypeHelp:
  	    helpTypeTitle = "Grammar Element"
  	    break

  	  case sentPartTypeHelp:
  	    helpTypeTitle = "Sentence Part"
  	    break

  	  case gramElemCatTypeHelp:
  	    helpTypeTitle = "Grammar Element Category"
  	    break

      case gamesTypeHelp:
        helpTypeTitle = "Games"
        break

  	  default:
  	    break
  	}

  	return "All " + helpTypeTitle + " Help Items"
  }


  const createLinks = (titleArr) => {

  	titleArr.sort()

  	return titleArr.map(helpTitle => {
  		const name = helpName(helpTitle)
  		return (
  		  <LinkStyle key={name} to={"/help/" + helpType + "/" + name}>
  		    {helpTitle}
  		  </LinkStyle>
  		)
  	})
  }

  const getGramElemHelpLinks = () => {

  	let activeHelpItems = []
  	gramElemTypeHelpItems.forEach((helpItem, gramElemId) => {
  	  // Filter out a few items that we don't want to display, at least for now.
  	  switch (helpItem.title) {
  	  	case 'Verb Phrase':
  	  	case "Complex Determiner":
  	  	case "Possessive Determiner":
  	  	  break

  	  	default:
  	  	  return activeHelpItems.push(helpItem.title)
  	  }
  	})

  	return createLinks(activeHelpItems)
  }

  const getSentPartHelpLinks = () => {
  	let activeHelpItems = []
  	sentPartTypeHelpItems.forEach((helpItem, gramElemId) => {
  	  // We don't want to show each compound Sentence Part
  	  switch (helpItem.title) {
  	  	case "Adverb Object 1":
  	  	case "Adverb Object 2":
  	  	case "Adverb Object 3":
  	  	case "Adverb Object 4":
  	  	case "Aux Verb 1":
   	  	case "Aux Verb 2":
   	  	case "Aux Verb 3":
  	  	case "Independent Clause 1":
  	  	case "Independent Clause 2":
  	  	case "Independent Clause 3":
  	  	case "Independent Clause 4":
  	  	case "Noun Object 1":
  	  	case "Noun Object 2":
  	  	case "Noun Object 3":
  	  	case "Noun Object 4":
  	  	case "PreAdjective Object 1":
  	  	case "PreAdjective Object 2":
  	  	case "PreAdjective Object 3":
  	  	case "PreAdjective Object 4":
  	  	case "PostAdjective Object 1":
  	  	case "PostAdjective Object 2":
  	  	case "PostAdjective Object 3":
  	  	case "PostAdjective Object 4":
  	  	case "Predicate 1":
   	  	case "Predicate 2":
   	  	case "Predicate 3":
   	  	case "Predicate 4":
   	  	case "PreDeterminer":
   	  	case "Main Determiner":
   	  	case "Ordinal Determiner":
   	  	case "Cardinal Determiner":
   	  	case "Sentence":
  	  		break
  	  	default:
  	  	  return activeHelpItems.push(helpItem.title)
  	  }
  	})

  	return createLinks(activeHelpItems)
  }

  const getGramElemCatHelpLinks = () => {
  	let activeHelpItems = []
  	gramElemCatTypeHelpItems.forEach((helpItem, gramElemId) => {
  	  activeHelpItems.push(helpItem.title)
  	})

  	return createLinks(activeHelpItems)
  }

  const getGamesHelpLinks = () => {
    let activeHelpItems = []
    gamesTypeHelpItems.forEach((helpItem, gramElemId) => {
      activeHelpItems.push(helpItem.title)
    })

    return createLinks(activeHelpItems)
  }


  const getHelpItemLinks = () => {

  	switch (helpType) {
  	  case gramElemTypeHelp:
  	    return getGramElemHelpLinks()

  	  case sentPartTypeHelp:
  	    return getSentPartHelpLinks()

  	  case gramElemCatTypeHelp:
 	      return getGramElemCatHelpLinks()

      case gamesTypeHelp:
        return getGamesHelpLinks()

  	  default:
  	    return []
  	}
  }


  return (
  	<HelpIndexStyle>
  	  <Title>{getIndexTitle()}</Title>
  	  {getHelpItemLinks()}
  	</HelpIndexStyle>
  )

}

export default HelpIndex
