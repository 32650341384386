import { makeAutoObservable, action } from "mobx";

export class MainStore {

	selectedSection = "1"
	sectionSelectOpts = []

	constructor() {
		this.initSelectOptions()

		makeAutoObservable(this, {
			setSelectedSection: action,
		})
	}

	initSelectOptions() {

		this.sectionSelectOpts.push({
		  label: "What's The Goal?",
		  value: "1",
		})
		this.sectionSelectOpts.push({
		  label: "How Does The Database Work?",
		  value: "2",
		})
		this.sectionSelectOpts.push({
		  label: "How Do The Games Work?",
		  value: "3",
		})
		this.sectionSelectOpts.push({
		  label: "Tell Me More About The Games",
		  value: "4",
		})
		this.sectionSelectOpts.push({
		  label: "Frequently Asked Game Questions",
		  value: "5",
		})
	}

	setSelectedSection(sectionNum) {
		this.selectedSection = sectionNum
	}
}