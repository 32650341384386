import React from 'react'
import { GameType } from "proto_files/grammar_games_enums_pb"
import { GrpcComms } from "components/DataStores/CommsStore/GrpcCommsStore"
import { MainStore } from "components/DataStores/MainStore"
import { PlayersStore } from "components/DataStores/PlayerStore/PlayersStore"
import { makeAutoObservable } from "mobx";


export class RootStore {

	commsStore = new GrpcComms(this)
	playersStore = new PlayersStore(this.commsStore)
	mainStore = new MainStore(this)
	gameLevelsMap = new Map([
			[GameType.MAKE_THEM_AGREE, 10],
			[GameType.FIND_THE_PATTERN, 4],
		])

	// The colors are duplicated from GlobalContext. I'm somewhat changing strategies on
	// handling the global data and don't feel like dealing with it right now, so I'm 
	// just copying it for now.
	noHighlightColor = '#ffffff'
	elemColors = new Map(
		[
			[0, '#ff3e3ec0'], 		// red
			[1, '#fc8c00c0'],		// orange
			[2, '#ffd14cc0'],		// yellow
			[3, '#00c852c0'],		// green
			[4, '#91679fc0'],		// purple
			[5, '#e96594c0'],		// pink
			[6, '#4fc3f7c0'],		// light blue
			[7, '#1876d3c0'],		// dark blue
		]
	)

	constructor() {

		makeAutoObservable(this, {

		})

		// For spy, we also need to import spy from mobx above.
		// spy(function (spyReport) {
		// 	// 
		// 	if (spyReport.type === 'action') {
		// 		console.log(spyReport.type + ': name: ', spyReport.name);
		// 	} else if (spyReport.type === 'update' || spyReport.type === 'add') {
		// 		console.log(spyReport.type + ': object: ' + spyReport.debugObjectName + ', name: ' + spyReport.name + ", newValue: " + spyReport.newValue );
		// 	} else if (spyReport.type === 'splice') {
		// 		console.log(spyReport.type + ': object: ' + spyReport.debugObjectName)
		// 	} else if (spyReport.type === 'remove') {
		// 		console.log(spyReport.type + ': object: ' + spyReport.debugObjectName + ', name: ' + spyReport.name)
		// 	}			
		// })

	}

	numGameLevels(gameType) {
		return this.gameLevelsMap.get(gameType)
	}

	get activePlayer() {
		return this.playersStore.activePlayer
	}
}


const RootStoreContext = React.createContext(new RootStore())

export const RootStoreProvider = RootStoreContext.Provider

export default RootStoreContext

// let _store
// export const getRootStore = () => {

// 	if (!_store) {
// 		_store = new RootStore()
// 	}

// 	return _store
// }

// export const getCommsStore = () => {
// 	const rootStore = getRootStore()
// 	return rootStore.commsStore
// }