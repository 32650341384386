import React from 'react';
import { getAnsInterjection } from 'components/GameElements/GameUtils'
import HelpModal from 'components/Help/HelpModal';
import { FaRegQuestionCircle } from "react-icons/fa"
import { action } from "mobx"
import { observer } from "mobx-react-lite"
import styled from 'styled-components'


const RadioQuesStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
  margin: 10px 0px;
`;

const MessageLine = styled.div`
  padding: 4px 0px;
`;

const OptionsGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  flex-basis: auto;
  padding-top: 5px;
`;

const RadioOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-end;
  margin: 3px 0px;
`;

const CategoryLabel = styled.label`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
`;

const ControlButton = styled.button`
  background-color: ${props => props.backgroundColor};
  width: 100px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  border: 0px;
  border-radius: 100px 100px 100px 100px;
  padding: 5px 4px;
  margin: 10px 2px 20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);  
  outline: none;
  cursor: pointer;
`;

const IconButton = styled.button`
  border: none;
  color: black;
  margin-left: 5px;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  background: transparent;
`;


const RadioQuestion = observer(({gameQues, finishHandler}) => {

  const maxLineChars = 50;

  const [showHelp, setShowHelp] = React.useState(false)
  const [helpType, setHelpType] = React.useState("")
  const [helpId, setHelpId] = React.useState(0)


  const correctAnswer = () => {
    return gameQues.multiAnswers[gameQues.multiAnswerInd-1]
  }

  // They selected the right answer, so we just display the correct message
  // briefly and move them on (i.e., they won't have to press Continue.)
  const automateTransition = action("Radio automateTransition", () => {
    setTimeout(() => finishHandler(), 1500)
    gameQues.autoTrans = true
  })

  // Used when they press Select or Continue
  const handleSelection = () => {

  	if (gameQues.isAnswered) {
  	  finishHandler()
  	} else {

      const isCorrect = gameQues.curSelection === correctAnswer().value
      // They didn't select the right answer immediately, so we make them hit the Select button
      // before evaluating their answer. We do that here.
      gameQues.setAnswer(isCorrect)
  	  if (isCorrect) {
        automateTransition()
  		}
  	}
  }

  const handleHelpRequest = (answer) => {
    setShowHelp(true)
    setHelpType(answer.helpType)
    setHelpId(answer.helpId)
  }

  const clearHelp = () => {
    setShowHelp(false)
  }

  const getQuestionMessage = () => {

    const quesText = gameQues.questionText

    if (quesText.length <= maxLineChars) {
      return (
        <Message>
          {quesText}
        </Message>
      )
    } else {
      const wsInd = quesText.indexOf(" ", maxLineChars)
      let message1, message2
      if (wsInd !== -1) {
        message1 = quesText.slice(0, wsInd)
        message2 = quesText.slice(wsInd+1)
      }

      return (
        <Message>
          <MessageLine>{message1}</MessageLine>
          <MessageLine>{message2}</MessageLine>
        </Message>
      )
    }
  }

  const getAnswerMessage = () => {
    const message1 = getAnsInterjection(gameQues.isCorrect) + " " + gameQues.answerText
    //const message2 = correctAnswer().label

    return (
      <Message>
        <MessageLine>{message1}</MessageLine>
        {/*<MessageLine>{message2}</MessageLine> */}
      </Message>
    )
  }

  const getMessage = () => {
  	if (gameQues.isAnswered !== undefined) {
      if (gameQues.isAnswered) {
        return getAnswerMessage()
      } else {
        return getQuestionMessage()
      }
  	} else {
      // Init the params for this question.
      gameQues.isAnswered = false
      gameQues.autoTrans = false
      gameQues.curSelection = undefined
      gameQues.firstSelect = true
      return getQuestionMessage()
    }
  }

  const buttonLabel = () => {
    if (gameQues.isAnswered !== undefined) {
      if (gameQues.isAnswered) {
        return "Continue"
      } else {
        return "Select"
      }
    }
  }

  const handleSelectionChange = action("Radio handleSelectionChange", (changeEvent) => {

    if (changeEvent.target.checked) {
      const newSelection = Number(changeEvent.target.value)
      if (gameQues.firstSelect && newSelection === correctAnswer().value) {
        // 2/3's of the time, we'll transition them automatically if they get the right answer,
        // but sometimes we won't. If we don't do this, then, if they select an answer and it
        // doesn't transition automatically, they can know it's wrong and essentially get a 
        // 2nd try for free. 
        if (Math.floor(Math.random() * 3) !== 1) {
          gameQues.setAnswer(true)
          automateTransition()
        }
      }
      gameQues.firstSelect = false
      gameQues.curSelection = newSelection
    }
  })
    
  const getAnswerOptions = () => {

  	const allOptions = gameQues.multiAnswers.map((answer, ind) => {

  		return <RadioOption key={answer.value}>
  		  <input 
  		    type="radio" 
  		    id={answer.value} 
  		    name="answerOption" 
  		    value={answer.value}
          checked={(answer.value === gameQues.curSelection)}
  		    onChange={handleSelectionChange}
  		  />
  		  <CategoryLabel htmlFor={answer.value}>{answer.label}</CategoryLabel>
        <IconButton onClick={() => handleHelpRequest(answer)} ><FaRegQuestionCircle /></IconButton>
  		</RadioOption>
  	})

    return allOptions
  }

  const getButtonElems = () => {
    if (gameQues.autoTrans) {
      return null
    } else {
      return <React.Fragment>
                <OptionsGroup>
                  {getAnswerOptions()}
                </OptionsGroup>
                <ControlButton 
                  backgroundColor={gameQues.colorArray[0]}
                  onClick={handleSelection}
                  // If they haven't selected an answer yet, then the Select button is disabled.
                  disabled={gameQues.curSelection === undefined}
                >
                  {buttonLabel()}
                </ControlButton>
              </React.Fragment>
    }
  }

  return (
    <RadioQuesStyle>
      {getMessage()}
      {getButtonElems()}
      <HelpModal 
        showHelp={showHelp}
        clearHelp={clearHelp}
        helpType={helpType}
        helpId={helpId}
      />
    </RadioQuesStyle>
  )
})

export default RadioQuestion