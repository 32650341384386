import React from 'react'
import HomeSelectContent from "components/Main/HomeSelectContent"
import RootStoreContext from "components/DataStores/RootStore"
import TitleHeader from 'components/Main/TitleHeader'
import FormDiv from './FormDiv'
import { Link } from "react-router-dom";
import styled from 'styled-components'

const HomeStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainStyle = styled.div`
  width: 600px;
  font-family: 'Roboto', sans-serif;  
  font-size: 16px;
  font-weight: 400;
  padding: 0px 10px;
  text-align: left;
  padding: 20px 10px;
`;

const TopQuesStyle = styled.div`
  text-align: center;
  font-family: 'Roboto', sans-serif;  
  font-size: 18px;
  font-weight: 500;
`;

const Title = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0px;
`;

// No idea why, but Chrome refuses to use a custom color. Frustrating.
const Divider = styled.hr`
  width: 500px;
  border: 1px solid black;
  border-color: '#101c3d';
  background-color: '#101c3d';
  text-align: center;
`;

const Subscription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
`;


const Home = () => {
  const formDiv = React.createRef()

  const rootStore = React.useContext(RootStoreContext)

  React.useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://leadingstep.ck.page/2926ec42d0/index.js'
    script.async = true
    script.setAttribute("data-uid", '2926ec42d0')

    formDiv.current.appendChild(script)
  }, [formDiv])

      	  // Our goal is to help people understand not just what is right, but <b><i>why</i></b>. 
      	  // Native speakers often go by what may <i>"sound right"</i> to them 
      	  // without really understanding the fundamentals, so they make mistakes without
      	  // realizing it. <br/><br/>


  return (
  	<HomeStyle>
      <TitleHeader title="Welcome to Leading Step!" />
      <MainStyle>
        <TopQuesStyle>
          Interested in understanding the structure of the English language?<br/><br/>
          Fantastic! That's the reason we're here! Join us!<br/><br/>
        </TopQuesStyle>
        <Divider/>
        <HomeSelectContent mainStore={rootStore.mainStore} />
        {/*
        <Divider/>
        <Title>How Can I Help?</Title>
        <p>

          We want to be able to continue to develop new games and build the system out, but we
          need additional funds to be able to do that. If you like what you see and want to see it
          continue, please check out our Go Fund Me page and consider contributing to the effort
          using this link:<br/><br/>

          <center><Link to='/gofundme'>Grammar Games for English Language Learners</Link><br/><br/></center>

          As noted there, if you prefer to make a direct contribution, we'll be happy to arrange that.
          Please contact us at support@leadingstep.com.<br/><br/>

          <center>Thanks for considering it!<br/><br/></center>
        </p>
        */}
        <Divider/>
        <Title>Connect With Us!</Title>
        <p>

      	  We're just getting started with all of this and have plans for much more to come. Check out
      	  the <Link to='/games'>Games page</Link> for details.<br/><br/>

      	  Want to know when new things become available? Join our email list 
      	  and we’ll keep you updated! And please, tell your friends!
      	</p>
      	<Subscription>
          <FormDiv ref={formDiv}/>
        </Subscription>
      </MainStyle>
    </HomeStyle>
  )
}

export default Home
