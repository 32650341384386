import { makeAutoObservable, action } from "mobx";

export class SentenceWord {

	position = 0
	label = ""
	modLabel = ""
	displayedLabel = ""
	isPunctuation = false
	nextIsPunctuation = false
	noHighlightColor = '#ffffff'
	curColor = this.noHighlightColor

	// We use checkpoint1 to manage colors while the player is actively highlighting words
	checkpoint1Color = this.noHighlightColor

	// We use checkpoint2 to restore colors if the player highlighted words incorrectly.
	checkpoint2Color = this.noHighlightColor
	correctColor = this.noHighlightColor
	isDisabled = true
	isActive = true
	isUnderlined = false
	checkpoint1IsUnderlined = false
	wordSet = undefined

	constructor(noHighlightColor) {
		makeAutoObservable(this, {
			noHighlightColor: false,
            setCheckpoint1: action,
            setCheckpoint2: action,
            restoreCheckpoint1: action,
            restoreCheckpoint2: action,
            setIsDisabled: action,
            clearHighlight: action,
            updateDisplayedLabel: action,
        })

		// The caller can override noHighlightColor if they want
        this.noHighlightColor = noHighlightColor === undefined ? this.noHighlightColor : noHighlightColor
	}

	// The word object passed in should have the following properties defined:
	//   position - int
	//   label - string
	//   modLabel (opt) - an override string if appropriate
	//   isPunctuation - bool
	//   color (opt) - string representation for a color (e.g., '#ffffff' or '#ff3e3ec0')

	initWord(word, wordSet) {
		this.position = word.position
		this.label = word.label
		this.modLabel = word.modLabel === "" ? "" : word.modLabel
		this.displayedLabel = word.modLabel === "" ? word.label : word.modLabel
		this.isPunctuation = word.isPunctuation === undefined ? false : word.isPunctuation
		this.nextIsPunctuation = word.nextIsPunctuation
		this.curColor = word.color === undefined ? this.noHighlightColor : word.color
		this.checkpoint1Color = this.curColor
		this.checkpoint2Color = this.curColor
		this.correctColor = this.noHighlightColor
		this.isDisabled = true
		this.isActive = false
		this.isUnderlined = false
		this.checkpoint1IsUnderlined = false
		this.wordSet = wordSet
	}

	// Checkpoint tracks all committed changes since a highlighting question began. THe player can
	// click and unclick or click and drag as much as they want. Every time they lift the mouse, the
	// current state is checkpointed as checkpoint1.
	setCheckpoint1() {
		this.checkpoint1Color = this.curColor
		this.checkpoint1IsUnderlined = this.isUnderlined
	}

	// This is called only at the application's request, normally prior to a highlight game question
	// so the words can be restored to their original highlight if the player's answer is incorrect.
	setCheckpoint2() {
		this.checkpoint2Color = this.curColor
	}

	// We restore the checkpoint1 color if the player is highlighting words and moves the
	// position of the mouse so a word should no longer be highlighted, all before a mouseUp event.
	restoreCheckpoint1() {
		this.curColor = this.checkpoint1Color
		this.isUnderlined = this.checkpoint1IsUnderlined
		this.wordSet.wordColorChanged()
	}

	// Called only at the application's request, normally after an incorrect highlight answer is given.
	restoreCheckpoint2() {
		this.curColor = this.checkpoint2Color
		this.wordSet.wordColorChanged()
	}

	get currentColor() {
		return this.curColor
	}

	set currentColor(color) {
		// If a word is disabled, the color can't be updated (e.g., it can't be highlighted)
		if (!this.isDisabled) {
			this.curColor = color
			this.wordSet.wordColorChanged()
		}
	}

	setIsDisabled(isDisabled) {
		// Punctuation words should never be enabled.
		if (!this.isPunctuation) {
			this.isDisabled = isDisabled
		}
	}

	// The player is currently dragging through the words to clear the highlights. This particular word may or
	// may not actually be highlighted. If the color matches the given highlight color, then restore the previous
	// checkpoint2 color. Otherwise, leave it as is.
	clearHighlight(highlightColor) {
		if (this.curColor === highlightColor) {
			this.restoreCheckpoint2()
			this.isUnderlined = false
		}
	}

	// Display the correct word
	updateDisplayedLabel() {
		this.displayedLabel = this.label
	}
}