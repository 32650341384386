import React from 'react'
import { helpItemName, helpEntries } from './helpData'

const getIdHelpMap = () => {
	let helpMap = new Map()
	helpEntries.forEach(helpEntry => {
	  if (helpEntry.gramElemTypeId !== undefined) {
	  	helpMap.set(helpEntry.gramElemTypeId, helpEntry.gramElemTypeId)
	  }
	})
return helpMap  	
}

const getNameHelpMap = () => {
	let helpMap = new Map()
	helpEntries.forEach(helpEntry => {
	  if (helpEntry.gramElemTypeId !== undefined) {
	  	// This is a GrammarElement help item, so add it to the map indexed by name
	  	helpMap.set(helpItemName(helpEntry), helpEntry.gramElemTypeId)
	  }
	}) 
	return helpMap
}

const useGramElemHelpId = (typeRef) => {

  // A map of only the GrammarElement help items indexed by the GramElemTypeId
  const [gramElemTypeIdHelpMap] = React.useState(getIdHelpMap())

  // A map of only the GrammarElement help items indexed by the name
  const [gramElemTypeNameHelpMap] = React.useState(getNameHelpMap())


  if (typeRef !== null) {
    if (gramElemTypeIdHelpMap.has(typeRef)) {
	  return typeRef
    }
    if (gramElemTypeNameHelpMap.has(typeRef)) {
	  return gramElemTypeNameHelpMap.get(typeRef)
    }
  }

  return 0
}

export default useGramElemHelpId
