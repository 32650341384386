import React from 'react'
import styled from 'styled-components';

const HelpLink = styled.button`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  padding: 0px;
  border-radius: 0px;
  border: none;
  outline: none;	
  cursor: pointer;
  text-decoration: underline;
  text-align: left;
  background: transparent;

  &:hover {
  	color: blue;
  }
`;

const RelatedHelpLink = ({relatedHelpId, title, handleHelp}) => {

  return (
  	<HelpLink onClick={() => handleHelp(relatedHelpId)}>
  	  {title}
  	</HelpLink>
  )
}

export default RelatedHelpLink
