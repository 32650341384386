import React from 'react';
import { observer } from "mobx-react-lite";
import styled from 'styled-components';


const GrammarWordStyle = styled.button`
  background-color: ${props => props.backgroundColor};
  font-family: 'Roboto', sans-serif;
  font-weight: ${props => props.inactive ? 100 : 400};
  font-size: 20px;
  text-decoration: ${props => props.underlined ? 'underline' : ''};
  padding-right: ${props => props.padRight ? '4px' : '0px'};
  padding-left: ${props => props.padLeft ? '4px' : '0px'};
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 10px;
  border-radius: 0px;
  border: none;
  outline: none;	
  cursor: ${props => props.disable ? 'default' : 'pointer'};
`;

// Note: We want to be able to disable the word as in, not process any
// events for it, but still not show it in a lighter font. If we set the 
// 'disabled' attribute for the button, it will do both automatically, so
// we set 'disable' instead and control the events ourselves.
const DisplaySentWord = observer(({word, mouseDown, wordHandler}) => {

  const handleMouseDown = event => {     
    if (event.button !== 2 && !word.isDisabled) {
      wordHandler(word.position, "down")
    }
  }

  const handleMouseEnter = event => {
    if (event.button !== 2 && !word.isDisabled && mouseDown) {
      wordHandler(word.position, "enter")
    }
  }

  return (
  	<GrammarWordStyle 
  	  disable={word.isDisabled} 
      inactive={word.isInactive}
  	  backgroundColor={word.curColor}
      underlined={word.isUnderlined}
      padRight={!word.nextIsPunctuation}
      padLeft={!word.isPunctuation}
      onMouseDown={handleMouseDown}
      onMouseEnter={handleMouseEnter}
  	>
  	  {word.displayedLabel}
  	</GrammarWordStyle>
  )
})

export default DisplaySentWord;

