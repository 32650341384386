import React, { Fragment } from 'react'
import ReactHtmlParser from 'react-html-parser'


const HelpExample = ({exampleData}) => {

  return (
    <Fragment>
      {ReactHtmlParser(exampleData)}
    </Fragment>
  )
}

export default HelpExample
