import { Player } from 'components/DataStores/PlayerStore/Player'
import { makeAutoObservable, computed } from "mobx";


export class PlayersStore {

	players = []
	currentPlayerInd = 0 
	commsStore = undefined

	constructor(commsStore) {

		this.commsStore = commsStore

		// We'll automatically initialize a first player. The name can be updated later.
		if (this.players.length === 0) {
			this.players.push(new Player(1, commsStore))
		}

		makeAutoObservable(this, {
			commsStore: false,
			activePlayer: computed,
		})
	}

	// We'll just use the first defined player as the default.
	get defaultPlayer() {
		return this.players[0]
	}

	get activePlayer() {
		return this.players[this.currentPlayerInd]
	}
		
}
