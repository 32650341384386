import React from 'react'
import FTPGameMgr from 'components/QuesLineGames/FTPGameMgr'
import FTPGameDesc from 'components/QuesLineGames/FTPGameDesc'
import GamesInfo from './GamesInfo'
import { Switch, Route, useRouteMatch } from "react-router-dom"


const Games = (props) => {

  let match = useRouteMatch()

  return (
  	<React.Fragment>
      <Switch>
        <Route 
          path={`${match.path}/ftp`} 
          render={() => (
            <FTPGameMgr />
          )}
        />
        <Route 
          path={`${match.path}/ftp-intro`} 
          render={() => (
            <FTPGameDesc />
          )}
        />
        <Route path={match.path} component={GamesInfo} />
      </Switch>
    </React.Fragment>
  )
}

export default Games
