import React from 'react';

const noHighlightColor = '#ffffff'

const elemColors = new Map(

	[
		[0, '#ff3e3ec0'], 		// red
		[1, '#fc8c00c0'],		// orange
		[2, '#ffd14cc0'],		// yellow
		[3, '#00c852c0'],		// green
		[4, '#91679fc0'],		// purple
		[5, '#e96594c0'],		// pink
		[6, '#4fc3f7c0'],		// light blue
		[7, '#1876d3c0'],		// dark blue
	]
)

export const gramElemCategories = new Map(

	[
	  [1, { 
	  		catId: 1,
	  		label: 'Noun Object', 
	  		types: [2, 3, 16, 17, 36, 41, 38, 43, 33, 25],
	  	  }],

	  [2,  { catId: 2, label: 'Aux Verb Object', types: [],}],
	  [3,  { catId: 3, label: 'Verb Object', types: [],}],
	  [4,  { catId: 4, label: 'Verb Phrase Object', types: [],}],
	  [5,  { catId: 5, label: 'Verbal Phrase Object', types: [],}],

	  [6, { 
	  		catId: 6,
	  		label: 'PreAdjective Object',
	  		types: [7, 37, 22, 27],
	  	  }],

	  [7, { 
	  		catId: 7,
	  		label: 'PostAdjective Object',
	  		types: [32, 39, 44, 42, 34, 28],
	  	  }],

	  [8, { 
	  		catId: 8,
	  		label: 'Adverb Object',
	  		types: [6, 19, 31, 20, 40, 45, 35, 26],
	  	  }],

	  [9,  { catId: 9, label: 'Conjunction', types: [],}],
	  [10, { catId: 10, label: 'Preposition', types: [],}],
	  [11, { catId: 11, label: 'Interjection Object', types: [],}],
	  [12, { catId: 12, label: 'Determiner Object', types: [11, 14],}],

	  [13, { 
	  		catId: 13,
	  		label: 'Predicate Object',
	  		types: [46, 47, 48, 49, 50, 51, 52, 53, 54, 55],
	  	  }],


	  [14, { catId: 14, label: 'Independent Clause Object', types: [56, 30],}],
	  [15, { catId: 15, label: 'Sentence', types: [57, 58, 59, 60, 61],}],

	  // Undefined is used for additional Grammar Elements that aren't used in sentences,
	  // but that we want to define for help purposes.
	  [16, { catId: 16, label: 'Undefined', types: [],}],
	]
)

export const grammarElementTypes = new Map(

	[

	  [2,   {gramElemTypeId: 2,  label: 'Noun', category: 1}],
	  [3,   {gramElemTypeId: 3,  label: 'Pronoun', category: 1}],
	  [4,   {gramElemTypeId: 4,  label: 'Aux Verb', category: 2}],
	  [5,   {gramElemTypeId: 5,  label: 'Verb', category: 3}],
	  [6,   {gramElemTypeId: 6,  label: 'Adverb', category: 8}],
	  [7,   {gramElemTypeId: 7,  label: 'Adjective', category: 6}],
	  [8,   {gramElemTypeId: 8,  label: 'Preposition', category: 10}],
	  [9,   {gramElemTypeId: 9,  label: 'Conjunction', category: 9}],
	  [10,  {gramElemTypeId: 10, label: 'Interjection', category: 11}],
	  [11,  {gramElemTypeId: 11, label: 'Determiner', category: 12}],
	  [12,  {gramElemTypeId: 12, label: 'Verb Phrase', category: 4}],
	  [13,  {gramElemTypeId: 13, label: 'Possessive Determiner', category: 12}],
	  [14,  {gramElemTypeId: 14, label: 'Complex Determiner', category: 12}],
	  // 15, Determiner Object
	  [16,  {gramElemTypeId: 16, label: 'Noun Phrase', category: 1}],
	  [17,  {gramElemTypeId: 17, label: 'Noun Appositive', category: 1}],
	  // 18, Noun Object
	  [19,  {gramElemTypeId: 19, label: 'Modified Adverb', category: 8}],
	  [20,  {gramElemTypeId: 20, label: 'Adverb Noun Phrase', category: 8}],
	  // 21, Adverb Object
	  [22,  {gramElemTypeId: 22, label: 'Modified Adjective', category: 6}],
	  // 23, PreAdjective Object
	  // 24, PostAdjective Object
	  [25,  {gramElemTypeId: 25, label: 'Compound Noun Object', category: 1}],
	  [26,  {gramElemTypeId: 26, label: 'Compound Adverb', category: 8}],
	  [27,  {gramElemTypeId: 27, label: 'Compound PreAdjective', category: 6}],
	  [28,  {gramElemTypeId: 28, label: 'Compound PostAdjective', category: 7}],	  
	  [29,  {gramElemTypeId: 29, label: 'Compound Predicate', category: 13}],	  
	  [30,  {gramElemTypeId: 30, label: 'Compound Clause', category: 14}],
	  [31,  {gramElemTypeId: 31, label: 'Adverb Prep Phrase', category: 8}],
	  [32,  {gramElemTypeId: 32, label: 'Adjective Prep Phrase', category: 7}],
	  [33,  {gramElemTypeId: 33, label: 'Noun Dependent Clause', category: 1}],
	  [34,  {gramElemTypeId: 34, label: 'Adjective Dependent Clause', category: 7}],
	  [35,  {gramElemTypeId: 35, label: 'Adverb Dependent Clause', category: 8}],
	  [36,  {gramElemTypeId: 36, label: 'Gerund', category: 1}],
	  [37,  {gramElemTypeId: 37, label: 'Participle', category: 6}],
	  [38,  {gramElemTypeId: 38, label: 'Noun Infinitive', category: 1}],
	  [39,  {gramElemTypeId: 39, label: 'Adjective Infinitive', category: 7}],
	  [40,  {gramElemTypeId: 40, label: 'Adverb Infinitive', category: 8}],
	  [41,  {gramElemTypeId: 41, label: 'Gerund Phrase', category: 1}],
	  [42,  {gramElemTypeId: 42, label: 'Participial Phrase', category: 7}],
	  [43,  {gramElemTypeId: 43, label: 'Noun Infinitive Phrase', category: 1}],
	  [44,  {gramElemTypeId: 44, label: 'Adjective Infinitive Phrase', category: 7}],
	  [45,  {gramElemTypeId: 45, label: 'Adverb Infinitive Phrase', category: 8}],
	  [46,  {gramElemTypeId: 46, label: 'Be Verb with Adverb (I)', category: 13}],
	  [47,  {gramElemTypeId: 47, label: 'Be Verb with Predicate Adjective (II)', category: 13}],
	  [48,  {gramElemTypeId: 48, label: 'Be Verb with Predicate Nominative (III)', category: 13}],
	  [49,  {gramElemTypeId: 49, label: 'Linking Verb with Predicate Adjective (IV)', category: 13}],
	  [50,  {gramElemTypeId: 50, label: 'Linking Verb with Predicate Nominative (V)', category: 13}],
	  [51,  {gramElemTypeId: 51, label: 'Intransitive Action Verb (VI)', category: 13}],
	  [52,  {gramElemTypeId: 52, label: 'Direct Object (VII)', category: 13}],
	  [53,  {gramElemTypeId: 53, label: 'Direct and Indirect Object (VIII)', category: 13}],
	  [54,  {gramElemTypeId: 54, label: 'Noun Object Complement (IX)', category: 13}],
	  [55,  {gramElemTypeId: 55, label: 'Adjective Object Complement (X)', category: 13}],
	  [56,  {gramElemTypeId: 56, label: 'Simple Clause', category: 14}],
	  [57,  {gramElemTypeId: 57, label: 'Declarative Sentence', category: 15}],
	  [58,  {gramElemTypeId: 58, label: 'Imperative Sentence', category: 15}],
	  [59,  {gramElemTypeId: 59, label: 'Exclamatory Sentence', category: 15}],
	  [60,  {gramElemTypeId: 60, label: 'Interrogative Sentence', category: 15}],
	  [61,  {gramElemTypeId: 61, label: 'Imperative Exclamatory Sentence', category: 15}],

	]
)

export const sentenceParts = new Map(
	[
	  [2,   {sentPartTypeId: 2,   label: 'Independent Clause Object'}],
	  [3,   {sentPartTypeId: 3,   label: 'Independent Clause 1'}],
	  [4,   {sentPartTypeId: 4,   label: 'Independent Clause 2'}],
	  [5,   {sentPartTypeId: 5,   label: 'Independent Clause 3'}],
	  [6,   {sentPartTypeId: 6,   label: 'Independent Clause 4'}],
	  [7,   {sentPartTypeId: 7,   label: 'Predicate Object'}],
	  [8,   {sentPartTypeId: 8,   label: 'Predicate 1'}],
	  [9,   {sentPartTypeId: 9,   label: 'Predicate 2'}],
	  [10,  {sentPartTypeId: 10,  label: 'Predicate 3'}],
	  [11,  {sentPartTypeId: 11,  label: 'Predicate 4'}],
	  [12,  {sentPartTypeId: 12,  label: 'Subject'}],
	  [13,  {sentPartTypeId: 13,  label: 'Direct Object'}],
	  [14,  {sentPartTypeId: 14,  label: 'Indirect Object'}],
	  [15,  {sentPartTypeId: 15,  label: 'Predicate Nominative'}],
	  [16,  {sentPartTypeId: 16,  label: 'Predicate Adjective'}],
	  [17,  {sentPartTypeId: 17,  label: 'Noun Object Complement'}],
	  [18,  {sentPartTypeId: 18,  label: 'Noun Appositive'}],
	  [19,  {sentPartTypeId: 19,  label: 'Gerund'}],
	  [20,  {sentPartTypeId: 20,  label: 'Noun Infinitive'}],
	  [21,  {sentPartTypeId: 21,  label: 'Noun of Address'}],
	  [22,  {sentPartTypeId: 22,  label: 'Noun Object'}],
	  [23,  {sentPartTypeId: 23,  label: 'Noun Object 1'}],
	  [24,  {sentPartTypeId: 24,  label: 'Noun Object 2'}],
	  [25,  {sentPartTypeId: 25,  label: 'Noun Object 3'}],
	  [26,  {sentPartTypeId: 26,  label: 'Noun Object 4'}],
	  [27,  {sentPartTypeId: 27,  label: 'Beginning Adverb Object'}],
	  [28,  {sentPartTypeId: 28,  label: 'Ending Adverb Object'}],
	  [29,  {sentPartTypeId: 29,  label: 'Embedded Verb Adverb Object'}],
	  [30,  {sentPartTypeId: 30,  label: 'Sentence Adverb Object'}],
	  [31,  {sentPartTypeId: 31,  label: 'Adverb Infinitive'}],
	  [32,  {sentPartTypeId: 32,  label: 'Adverb Object'}],
	  [33,  {sentPartTypeId: 33,  label: 'Adverb Object 1'}],
	  [34,  {sentPartTypeId: 34,  label: 'Adverb Object 2'}],
	  [35,  {sentPartTypeId: 35,  label: 'Adverb Object 3'}],
	  [36,  {sentPartTypeId: 36,  label: 'Adverb Object 4'}],
	  [37,  {sentPartTypeId: 37,  label: 'PrePredicateAdjective'}],
	  [38,  {sentPartTypeId: 38,  label: 'Adjective Object Complement'}],
	  [39,  {sentPartTypeId: 39,  label: 'Participle'}],
	  [40,  {sentPartTypeId: 40,  label: 'PreAdjective Object'}],
	  [41,  {sentPartTypeId: 41,  label: 'PreAdjective Object 1'}],
	  [42,  {sentPartTypeId: 42,  label: 'PreAdjective Object 2'}],
	  [43,  {sentPartTypeId: 43,  label: 'PreAdjective Object 3'}],
	  [44,  {sentPartTypeId: 44,  label: 'PreAdjective Object 4'}],
	  [45,  {sentPartTypeId: 45,  label: 'PostPredicateAdjective'}],
	  [46,  {sentPartTypeId: 46,  label: 'Beginning Participial Phrase'}],
	  [47,  {sentPartTypeId: 47,  label: 'Ending Participial Phrase'}],
	  [48,  {sentPartTypeId: 48,  label: 'Adjective Infinitive'}],
	  [49,  {sentPartTypeId: 49,  label: 'PostAdjective Object'}],
	  [50,  {sentPartTypeId: 50,  label: 'PostAdjective Object 1'}],
	  [51,  {sentPartTypeId: 51,  label: 'PostAdjective Object 2'}],
	  [52,  {sentPartTypeId: 52,  label: 'PostAdjective Object 3'}],
	  [53,  {sentPartTypeId: 53,  label: 'PostAdjective Object 4'}],
	  [54,  {sentPartTypeId: 54,  label: 'Preposition'}],
	  [55,  {sentPartTypeId: 55,  label: 'Relative Pronoun'}],
	  [56,  {sentPartTypeId: 56,  label: 'Coordinating Conjunction'}],
	  [57,  {sentPartTypeId: 57,  label: 'Correlative Conjunction'}],
	  [58,  {sentPartTypeId: 58,  label: 'Subordinating Conjunction'}],
	  [59,  {sentPartTypeId: 59,  label: 'PreDeterminer'}],
	  [60,  {sentPartTypeId: 60,  label: 'Main Determiner'}],
	  [61,  {sentPartTypeId: 61,  label: 'Ordinal Determiner'}],
	  [62,  {sentPartTypeId: 62,  label: 'Cardinal Determiner'}],
	  [63,  {sentPartTypeId: 63,  label: 'Determiner Object'}],
	  [64,  {sentPartTypeId: 64,  label: 'Interjection'}],
	  [65,  {sentPartTypeId: 65,  label: 'Aux Verb 1'}],
	  [66,  {sentPartTypeId: 66,  label: 'Aux Verb 2'}],
	  [67,  {sentPartTypeId: 67,  label: 'Aux Verb 3'}],
	  [68,  {sentPartTypeId: 68,  label: 'Verb'}],
	  [69,  {sentPartTypeId: 69,  label: 'Be Verb Phrase'}],
	  [70,  {sentPartTypeId: 70,  label: 'Aux Verb Phrase'}],
	  [71,  {sentPartTypeId: 71,  label: 'Sentence'}],
	]
);

// key = GrammarElementTypeId, value = ordered list of SentencePartTypeIds
export const grammarElementParts = new Map(

	[
		// Dummy GrammarElement
		[1, []],

		// Noun
		[2, []],

		// Pronoun
		[3, []],

		// Aux Verb
		[4, []],

		// Verb
		[5, []],

		// Adverb
		[6, []],

		// Adjective
		[7, []],

		// Preposition
		[8, []],

		// Conjunction
		[9, []],

		// Interjection
		[10, []],

		// Determiner
		[11, []],

		// Verb Phrase
		[12, [65, 29, 66, 67]],

		// Possessive Determiner
		[13, [22]],

		// Complex Determiner
		[14, [59, 60, 61, 62]],

		// Determiner Object
		[15, []],

		// Noun Phrase
		[16, [46, 63, 40, 22, 49, 47]],

		// Noun Appositive
		[17, [22, 18]],

		// Noun Object
		[18, []],

		// Modified Adverb
		[19, [27, 32, 28]],

		// Adverb Noun Phrase
	  	[20, [63, 40, 22, 49]],

	  	// Adverb Object
		[21, []],

		// Modified Adjective
	  	[22, [27, 40, 28]],

		// PreAdjective Object
	  	[23, []],

	  	// PostAdjective Object
		[24, []],

		// Compound Noun Object
		[25, [56, 23, 24, 25, 26, 57]],

		// Compound Adverb Object
		[26, [56, 33, 34, 35, 36, 57]],

		// Compound PreAdjective Object
		[27, [56, 41, 42, 43, 44, 57]],

		// Compound PostAdjective Object
		[28, [56, 50, 51, 52, 53]],

		// Compound Predicate
		[29, [56, 8, 9, 10, 11]],

		// Compound Independent Clause
		[30, [56, 3, 4, 5, 6]],

		// Adverb Prep Phrase
		[31, [54, 22]],

		// Adjective Prep Phrase
		[32, [54, 22]],

		// Noun Dependent Clause
		[33, [55, 58, 27, 12, 7, 28]],

		// Adjective Dependent Clause
		[34, [55, 27, 12, 7, 28]],

		// Adverb Dependent Clause
		[35, [58, 27, 12, 7, 28]],

		// Gerund
		[36, []],

		// Participle
		[37, []],

		// Noun Infinitive
		[38, []],

		// Adjective Infinitive
		[39, []],

		// Adverb Infinitive
		[40, []],

		// Gerund Phrase
		[41, [19, 14, 13, 32, 16, 38]],

		// Participial Phrase
		[42, [39, 14, 13, 32, 16, 38]],

		// Noun Infinitive Phrase
		[43, [20, 14, 13, 32, 16, 38]],

		// Adjective Infinitive Phrase
		[44, [48, 14, 13, 32, 16, 38]],

		// Adverb Infinitive Phrase
		[45, [31, 14, 13, 32, 16, 38]],

		// Be Verb With Adverb
		[46, [69, 29, 28]],

		// Be Verb with Predicate Adjective
		[47, [69, 29, 37, 45, 28]],

		// Be Verb with Predicate Nominative
		[48, [69, 29, 15, 28]],

		// Linking Verb with Predicate Adjective
		[49, [70, 29, 68, 37, 45, 28]],

		// Linking Verb with Predicate Nominative
		[50, [70, 29, 68, 15, 28]],

		// Intransitive Action Verb
		[51, [70, 29, 68, 28]],

		// Direct Object
		[52, [70, 29, 68, 13, 28]],

		// Direct and Indirect Object
		[53, [70, 29, 68, 14, 13, 28]],

		// Adjective Object Complement
		[54, [70, 29, 68, 13, 38, 28]],

		// Noun Object Complement
		[55, [70, 29, 68, 13, 17, 28]],

		// Independent Clause
		[56, [27, 12, 7, 28]],

		// Declarative Sentence
		[57, [64, 30, 2]],

		// Imperative Sentence
		// (We treat 'please' as a Sentence Adverb Object)
		[58, [64, 21, 30, 2]],

		// Exclamatory Sentence
		[59, [64, 30, 2]],

		// Interrogative Sentence
		[60, [64, 21, 2]],

		// Imperative Exclamatory Sentence
		// (We treat 'please' as a Sentence Adverb Object)
		[61, [64, 21, 30, 2]],

	]
)

const nounObjFunctionArray = [

	{
		nounObjFunctionId: 1,
		sentPartTypeIds: [22], 
		parentGramElemTypeIds: [16], 
		helpSentPartId: 22,
		functionLabel: "The Noun Object of a Noun Phrase",
	},

	{
		nounObjFunctionId: 2,
		sentPartTypeIds: [22], 
		parentGramElemTypeIds: [20], 
		helpSentPartId: 22,
		functionLabel: "The Noun Object of an Adverb Noun Phrase",
	},

	{
		nounObjFunctionId: 3,
		sentPartTypeIds: [23, 24, 25, 26], 
		parentGramElemTypeIds: [25], 
		helpSentPartId: 22,
		functionLabel: "A Noun Object in a Compound Noun Object",
	},

	{
		nounObjFunctionId: 4,
		sentPartTypeIds: [22], 
		parentGramElemTypeIds: [31, 32], 
		helpSentPartId: 22,
		functionLabel: "The Noun Object of a Prepositional Phrase",
	},

	{
		nounObjFunctionId: 5,
		sentPartTypeIds: [22], 
		parentGramElemTypeIds: [17], 
		helpSentPartId: 22,
		functionLabel: "The Noun Object of a Noun Appositive Phrase",
	},

	{
		nounObjFunctionId: 6,
		sentPartTypeIds: [18], 
		parentGramElemTypeIds: [17], 
		helpSentPartId: 18,
		functionLabel: "The Appositive of a Noun Appositive Phrase",
	},

	{
		nounObjFunctionId: 7,
		sentPartTypeIds: [12], 
		parentGramElemTypeIds: [33, 34, 35], 
		helpSentPartId: 12,
		functionLabel: "The Subject of a Dependent Clause",
	},

	{
		nounObjFunctionId: 8,
		sentPartTypeIds: [12], 
		parentGramElemTypeIds: [56], 
		helpSentPartId: 12,
		functionLabel: "The Subject of an Independent Clause",
	},

	{
		nounObjFunctionId: 9,
		sentPartTypeIds: [13], 
		parentGramElemTypeIds: [41, 42, 43, 44, 45], 
		helpSentPartId: 13,
		functionLabel: "A Direct Object in a Verbal Phrase",
	},

	{
		nounObjFunctionId: 10,
		sentPartTypeIds: [14], 
		parentGramElemTypeIds: [41, 42, 43, 44, 45], 
		helpSentPartId: 14,
		functionLabel: "An Indirect Object in a Verbal Phrase",
	},

	{
		nounObjFunctionId: 11,
		sentPartTypeIds: [15], 
		parentGramElemTypeIds: [48, 50], 
		helpSentPartId: 15,
		functionLabel: "A Predicate Nominative in a Predicate",
	},

	{
		nounObjFunctionId: 12,
		sentPartTypeIds: [13], 
		parentGramElemTypeIds: [52, 53, 54], 
		helpSentPartId: 13,
		functionLabel: "A Direct Object in a Predicate",
	},

	{
		nounObjFunctionId: 13,
		sentPartTypeIds: [14], 
		parentGramElemTypeIds: [53], 
		helpSentPartId: 14,
		functionLabel: "An Indirect Object in a Predicate",
	},

	{
		nounObjFunctionId: 14,
		sentPartTypeIds: [17], 
		parentGramElemTypeIds: [54], 
		helpSentPartId: 17,
		functionLabel: "A Noun Object Complement in a Predicate",
	},

]

export const GlobalContext = React.createContext(
	{
		noHighlightColor: 		noHighlightColor,
		colors: 				elemColors,
		categories:         	gramElemCategories, 
		geTypes: 				grammarElementTypes,
		sentParts: 				sentenceParts,
		geParts:  				grammarElementParts,
		nounFunctionArray:      nounObjFunctionArray,
		sentenceSentPartTypeId: 71,
	}
)
GlobalContext.displayName = 'GlobalContext';
