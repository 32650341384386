import React from 'react'
import { GameType } from "proto_files/grammar_games_enums_pb"
import RootStoreContext from "components/DataStores/RootStore"
import { QuesLineGame } from "components/DataStores/GameStore/QuesLineGame"
import TitleHeader from 'components/Main/TitleHeader'
import QuesLineGameMgr from "components/QuesLineGames/QuesLineGameMgr"
//import styled from 'styled-components'

const FTPGameMgr = () => {

	const rootStore = React.useContext(RootStoreContext)

	const getQuesLineGame = () => {
		const player = rootStore.activePlayer

		let game = player.getQuesLineGame(GameType.FIND_THE_PATTERN)

		if (game === undefined) {
			game = new QuesLineGame(rootStore, player, GameType.FIND_THE_PATTERN)
			player.addQuesLineGame(GameType.FIND_THE_PATTERN, game)
		}

		return game
	}

	return (
    	<React.Fragment>
    		<TitleHeader title="Find the Pattern" />
    		<QuesLineGameMgr
				quesLineGame={getQuesLineGame()}
			/>
	    </React.Fragment>
	)

}

export default FTPGameMgr
