import React from 'react';
import Faq from 'react-faq-component';

const FAQ = () => {

  const data = {
    rows: [
      {
        title: "How do I select words for a highlight question?",
        content: "If you want to select just one word, simply click on it. If you want to select multiple adjacent words, click on the first word and then drag to the last word, even if it's on the next line. Continue selecting and unselecting words until you are happy with your selection and then click the Check My Answer button."
      },
      {
        title: "How can I tell how I'm doing on a given level?",
        content: "Look for the progress circle on the top right of the screen. It also shows you the level you're working on. The circle will show you the progress you've made so far."
      },
      {
        title: "When will I advance to the next level?",
        content: "We track how you're doing with the various types of questions at each level so we can make sure you're understanding all of the different elements. If you're getting some right, but struggling with others, we'll keep you at that level so you can keep practicing until you get correct answers for all of the different question types. Then we'll automatically move you to the next level."
      },
      {
        title: "Do I lose points for incorrect answers?",
        content: "Yes. If you sometimes answer correctly, but other times not, we want you to continue practicing until you get the answers correct consistently."
      },
      {
        title: "How can I choose my own game level?",
        content: "Click on the game level that's displayed under the progress circle. It will bring up a screen giving you information about the current level. You can choose a specific level there, but be careful because once you choose a new level, you lose all of your progress at the current level."
      },
      {
        title: "Can I start the current level over?",
        content: "Yes! Just follow the instructions to set a new level, but then select the same level you are on currently."
      },
      {
        title: "Can I see what types of questions are asked at each game level?",
        content: "Yes! Click on the current game level below the progress circle. You'll see information about the current level along with a button to View Game Level Info. Clicking that will show you detailed information for all levels."
      }
    ]
  }

  return (
    <div>
      <Faq 
        data={data}
        styles={{
          rowTitleTextSize: "medium",
          rowContentTextSize: "14px",
          rowContentPaddingTop: "5px",
          rowContentPaddingBottom: "10px",
          rowContentPaddingLeft: "10px",
          rowContentPaddingRight: "50px",
        }}
      />
      <p><br/></p>
    </div>
  )
}

export default FAQ;
