import React, { useEffect } from 'react'
import TitleHeader from 'components/Main/TitleHeader';
import RootStoreContext from "components/DataStores/RootStore"
import { GameType } from "proto_files/grammar_games_enums_pb"
import { Link } from "react-router-dom";
import styled from 'styled-components'

const BuildStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainStyle = styled.div`
  width: 600px;
  font-family: 'Roboto', sans-serif;  
  font-size: 16px;
  font-weight: 400;
  padding: 0px 10px;
  text-align: justify;
  padding: 20px 10px;
`;

const Divider = styled.hr`
  width: 500px;
  border: 1px solid black;
  border-color: '#101c3d';
  background-color: '#101c3d';
  text-align: center;
`;

const Title = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0px;
`;

const CenteredText = styled.p`
  text-align: center;
  font-size: 20px;
`;

const Button = styled.button`
  background-color: ${props => props.backgroundColor};
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  border: 0px;
  border-radius: 100px 100px 100px 100px;
  padding: 5px 20px;
  margin: 10px 4px 20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);  
  outline: none;
  cursor: pointer;
`;

// isGenDesc tells us how we're coming to this page, so that tells us what we want the continue button
// to say. If we're coming to it from within a game and the player asked to see the game level info, 
// then the continue button will say, 'Got It!', and the player will go back to that screen. If we're
// coming from anywhere else (e.g., the home page where we link to FTP directly), then this is a step
// before going to the actual game and when the player wants to continue, they'll select "Let's Play!"
const FTPGameDesc = () => {

  const rootStore = React.useContext(RootStoreContext)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getContinueButton = () => {

    const player = rootStore.activePlayer

    let quesLineGame = player.getQuesLineGame(GameType.FIND_THE_PATTERN)

    let buttonText = "Let's Play!"
    if (quesLineGame !== undefined && quesLineGame.showLevelInfo) {
      buttonText = "Back to Setting the Game Level!"
    }
    return (
        <Button 
          backgroundColor={'#4fc3f7c0'}
        >
          {buttonText}
        </Button>
    )
  }

  return (
    <BuildStyle>
      <TitleHeader title="Find the Pattern" />
      <MainStyle>
        <Title>
          How Does 'Find the Pattern' work?
        </Title>
        <p>
          All sentences in the English language follow a set number of patterns. Your goal in <i>Find the 
          Pattern</i> is to identify those patterns by identifying the parts that make up the 
          sentence. We help you learn to identify the structure and purpose of 
          the sentence and then break down each clause and predicate into its parts. We also 
          help you learn to identify the type of each verb that is used such as whether it's an action or linking
          verb and that gives you a hint about the parts that can be found in the predicate.<br/><br/>

          This game has four different levels so you can start out with shorter, easier sentences
          and work your way up to identifying the patterns in more complex sentences. If you're looking for more
          of a challenge, you can jump right to a higher level, but if you need more practice,
          you can stick with the first levels until you feel more confident. See the level descriptions below to 
          find out what you'll work on at each level.<br/><br/>

        </p>

        <CenteredText>
          <Link to='/games/ftp'>
            {getContinueButton()}
          </Link>
        </CenteredText>

        <Divider/>

        <Title>
          Level 1
        </Title>

        <p>
          At level 1, you're just getting started so we only focus on simple sentences. (Just a reminder: this means those 
          that have one independent clause and no dependent clauses.) The question types you'll encounter 
          are:<br/><br/>

          <b>Identify Sentence Purpose</b>: <br/>
          <ul>
            <li>Declarative Sentence</li>
            <li>Exclamatory Sentence</li>
            <li>Imperative Sentence</li>
            <li>Interrogative Sentence</li>
            <li>Imperative and Exclamatory Sentence</li>
          </ul>

          This last type is a combination of two types. It's for sentences like "Let's go!"<br/><br/>

          <b>Identify Verb Type</b>:<br/>
          <ul>
            <li>Action Verb</li>
            <li>Linking Verb</li>
            <li>Transitive Verb</li>
            <li>Intransitive Verb</li>
          </ul>

          Identifying the verb type will help you understand what parts there might be in the predicate.<br/><br/>

          <b>Identify the Subject of the Sentence:</b><br/>
          <ul>
            <li>There may or may not be one, depending on the purpose of the sentence.</li>
          </ul>

          <b>Identify the Parts of the Predicate</b>:<br/>
          <ul>
            <li>Predicate Adjective</li>
            <li>Predicate Nominative (sometimes called Predicate Noun)</li>
            <li>Direct Object</li>
            <li>Indirect Object</li>
            <li>Adjective Object Complement</li>
            <li>(We'll get to the Noun Object Complements at level 2)</li>
          </ul>

          <br/>
        </p>
 
        <Divider/>

        <Title>
          Level 2
        </Title>

        <p>
          At level 2, the sentences will be more complicated, so you'll practice identifying the structure
          of the sentence and then break down the parts for each clause. The sentences still won't be
          that complex though. You'll answer questions about: <br/><br/>

          <b>Identify Sentence Structure</b>:<br/>
          <ul>
            <li>Simple Sentence</li>
            <li>Complex Sentence</li>
            <li>Compound Sentence</li>
            <li>Compound/Complex Sentence</li>
          </ul>

          <b>Identify Sentence Purpose</b>: <br/>
          <ul>
            <li>This will be the same as level 1.</li>
          </ul>
          
          <b>Identify Verb Type</b>: <br/>
          <ul>
            <li>This will also be the same as level 1.</li>
          </ul>
          
          <b>Clause Questions</b>: <br/>
          <ul>
            <li>Identify each independent clause in the sentence.</li>
            <li>Identify the dependent clause, if there is one.</li>
            <li>Identify the subject of each clause, if there is one.</li>
            <li>Determine if a clause has a compound predicate or not.</li>
          </ul>

          At this level, the sentences may have an adjective or adverb dependent clause,
          but they won't have a noun dependent clause yet.<br/><br/>

          <b>Predicate Questions</b>: <br/>
          <ul>
            <li>Identify each predicate including those that are part of a compound predicate.</li>
            <li>Identify the parts of the predicate, just as in level 1.</li>
            <li>Identify noun object complements, in addition to all the other parts.</li>
          </ul>
          
          <br/>
        </p>
 
        <Divider/>
        
        <Title>
          Level 3
        </Title>

        <p>
          At level 3, the sentence complexity will increase again and there may be more than
          one dependent clause. In addition, we'll stop asking you to identify the verb type,
          so that can make it more challenging to determine the parts of each predicate. You'll have
          practice with that at the lower levels, though, so we figure you've got plenty of experience
          with that by this point. You'll answer questions about:<br/><br/>

          <b>Sentence Structure</b>: <br/>
          <ul>
            <li>We'll still ask you to identify the structure since that helps you to know how to 
            break down the sentence.</li>
          </ul>

          <b>Clause Questions</b>: <br/>
          <ul>
            <li>Mostly the same questions as level 2.</li>
            <li>Identify all dependent clauses by type, if they exist. There may be more than one!</li>
            <li>Noun dependent clauses will be included.</li>
          </ul>
          
          <b>Predicate Questions</b>: <br/>
          <ul>
            <li>This will be the same as level 2.</li>
          </ul>

          <br/>
        </p>
 
        <Divider/>
        
        <Title>
          Level 4
        </Title>

        <p>
          At this top level, the sentences will have the most complexity and they may have more than
          one dependent clause of the same type. The question types will be the same as
          level 3, other than identifying more than one of the same dependent clause type where
          necessary.<br/><br/>
          
          <br/>
        </p>
 
      </MainStyle>
    </BuildStyle>
  )

}

export default FTPGameDesc
