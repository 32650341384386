import React from 'react'
import styled from 'styled-components'


const FormDivStyle = styled.div`
  margin: 10px 0px;
`;


const FormDiv = React.forwardRef((props, ref) => {
  return (
    <FormDivStyle ref={ref} />
  )
})

export default FormDiv
