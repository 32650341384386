import React from 'react'
import { getAnsInterjection } from 'components/GameElements/GameUtils'
import { action } from "mobx"
import { observer } from "mobx-react-lite"
import styled from 'styled-components'

const QuesAnsGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const QuesAnsText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Button = styled.button`
  background-color: ${props => props.backgroundColor};
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  border: 0px;
  border-radius: 100px 100px 100px 100px;
  padding: 5px 20px;
  margin: 10px 4px 20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);  
  outline: none;
  cursor: pointer;
`;

const ButtonQuestion = observer(({gameQues, finishHandler}) => {

  // They selected the right answer, so we just display the correct message
  // briefly and move them on (i.e., they won't have to press Continue.)
  const automateTransition = action("Radio automateTransition", () => {
    setTimeout(() => finishHandler(), 1500)
    gameQues.autoTrans = true
  })

  const handleAnswer = (buttonValue) => {
    const correctValue = gameQues.multiAnswers[gameQues.multiAnswerInd-1].value
    const isCorrect = Number(buttonValue) === correctValue
    gameQues.setAnswer(isCorrect)
    if (isCorrect) {
      automateTransition()
    }
  }

  const getButtons = () => {
    return gameQues.multiAnswers.map((buttonData, ind) => {
      return <Button
  		  key={ind}
  		  value={buttonData.value}
  		  backgroundColor={ gameQues.colorArray[ind]}
  		  onClick={(e) => handleAnswer(e.target.value)}
  		>
  		  {buttonData.label}
  		</Button>
    })
  }

  const getQuestionComponent = () => {
  	return (
  		<QuesAnsGroup>
	  	  <QuesAnsText>
	  		 {gameQues.questionText}
	  	  </QuesAnsText>
	  	  <ButtonGroup>
	  	  	{getButtons()}
	  	  </ButtonGroup>
  		</QuesAnsGroup>
  	)
  }

  const getAnswerComponent = () => {

    // If we're auto transitioning, we just display the message, not the Continue button.
    if (gameQues.autoTrans) {
      return (
        <QuesAnsGroup>
          <QuesAnsText>
            {getAnsInterjection(gameQues.isCorrect) + " " + gameQues.answerText}
          </QuesAnsText>
        </QuesAnsGroup>
      )
    } else {
      return (
        <QuesAnsGroup>
          <QuesAnsText>
            {getAnsInterjection(gameQues.isCorrect) + " " + gameQues.answerText}
          </QuesAnsText>
          <Button 
            // The last color is for the Continue button
            backgroundColor={gameQues.colorArray[gameQues.multiAnswers.length]}
            onClick={() => finishHandler()}
          >
            Continue
          </Button>
        </QuesAnsGroup>
      )      
    }

  }  

  const getPhaseComponent = () => {
    if (gameQues.isAnswered === undefined) {
        // Some question types need to do some type of initialization. Not needed for ButtonQuestions.
      gameQues.isAnswered = false
    } else {
      if (gameQues.isAnswered) {
        return getAnswerComponent()
      } else {
        return getQuestionComponent()
      }      
    }
  }

  return (
  	<React.Fragment>
  	  {getPhaseComponent()}
  	</React.Fragment>
  )
})

export default ButtonQuestion
