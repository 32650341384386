import { makeAutoObservable, action } from "mobx";


const RefreshStatus = {

	REQUIRES_REFRESH: "requiresRefresh",
	IS_REFRESHING: "isRefreshing",
	REFRESH_DELAY: "refreshDelay",
	REFRESHED: "refreshed",
}

const RefreshInitialDelay = 5
const RefreshMaxDelay = 300

export class Player {

	playerName = "Player1" 
	playerID = "00000000-0000-0000-0000-000000000000"
	accessKey = 0
	isActive = false
	refreshStatus = RefreshStatus.REQUIRES_REFRESH
	refreshDelay = RefreshInitialDelay
	gameMap = new Map()
	gameLevelMap = new Map()

	commsStore = undefined

	constructor(ind, commsStore, playerName) {
		this.commsStore = commsStore
		this.playerName = playerName !== undefined ? playerName : this.playerName

		this.storageKey = 'Player' + ind.toString()

		let configRestored = this.restorePlayerConfig()
		if (!configRestored) {
			// We weren't able to restore the player, so it must be a new one.
			// Store the config we currently have.
			this.storePlayerConfig()
		}

		makeAutoObservable(this, {
			commsStore: false,
			addQuesLineGame: action,
			requestPlayerRefresh: action,
			playerRefreshed: action,
			setCurrentGameType: action,
			refreshFailed: action,
		})
	}

	storePlayerConfig() {
		// Create an array of gameType to current gameLevel
		let gameLevelArr = []
		this.gameMap.forEach((quesLineGame, gameType) => {
			gameLevelArr.push([gameType, quesLineGame.gameLevel])
		})

		let playerConfig = {
			playerName: this.playerName,
			playerID: this.playerID,
			accessKey: this.accessKey,
			gameLevels: gameLevelArr,
		}

		const jsonValue = JSON.stringify(playerConfig)	
		localStorage.setItem(this.storageKey, jsonValue)
	}

	restorePlayerConfig() {
		const json = localStorage.getItem(this.storageKey)
		if (json != null) {
			let playerConfig = JSON.parse(json)

			this.playerName = playerConfig.playerName
			this.playerID = playerConfig.playerID
			this.accessKey = playerConfig.accessKey
			this.gameLevelMap = new Map(playerConfig.gameLevels)

			return true
		} else {
			return false
		}
	}

	getQuesLineGame(gameType) {
		if (!this.gameMap.has(gameType)) {
			return undefined
		} else {
			return this.gameMap.get(gameType)
		}
	}

	// We may have stored a previous gameLevel. If not, return the default.
	getGameLevel(gameType) {
		if (!this.gameLevelMap.has(gameType)) {
			// The default gameLevel is 1
			return 1
		} else {
			return this.gameLevelMap.get(gameType)
		}
	}

	addQuesLineGame(gameType, quesLineGame) {
		this.gameMap.set(gameType, quesLineGame)
	}

	// If setInactive is true, it means that the server no longer recognizes this player, so the game
	// cannot continue until the player is refreshed.
	requestPlayerRefresh(quesLineGame, setInactive) {
		if (setInactive) {
			this.isActive = false
		}

		if (this.refreshStatus === RefreshStatus.IS_REFRESHING) {
			return
		}

		this.refreshStatus = RefreshStatus.IS_REFRESHING
		this.commsStore.refreshPlayer(this, quesLineGame)
	}

	// If any message fails, it means we've lost connection with the server, so we'll go back to
	// trying to refresh the player using a backoff timer.
	refreshFailed(quesLineGame) {
		this.isActive = false
		this.refreshStatus = RefreshStatus.REFRESH_DELAY
		setTimeout(() => this.commsStore.refreshPlayer(this, quesLineGame), (1000 * this.refreshDelay))

		// Make the delay back-off up to a max time.
		if (this.refreshDelay < RefreshMaxDelay) {
			this.refreshDelay *= 2
			if (this.refreshDelay > RefreshMaxDelay) {
				this.refreshDelay = RefreshMaxDelay
			}
		}
	}

	playerRefreshed(playerID, accessKey, quesLineGame) {
		this.isActive = true
		this.playerID = playerID
		this.accessKey = accessKey
		this.refreshStatus = RefreshStatus.REFRESHED
		this.refreshDelay = RefreshInitialDelay

		this.storePlayerConfig()

		quesLineGame.playerRefreshed()
	}

}
