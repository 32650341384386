import React, { Fragment } from 'react'
import HelpExample from './HelpExample'
import RelatedHelpLink from './RelatedHelpLink'
import useHelpItem from './HelpHooks/useHelpItem'
import { useHistory } from "react-router-dom"
import ReactHtmlParser from 'react-html-parser'
import { helpItemName, helpEntries } from './HelpHooks/helpData'
import { gramElemTypeHelp, sentPartTypeHelp, gramElemCatTypeHelp, genElemTypeHelp, gamesTypeHelp } from 'components/Help/Help'
import styled from 'styled-components'


const HelpItemStyle = styled.div`
  margin-left: ${props => props.margin};
  margin-right: ${props => props.margin};
  display: flex;
  flex-direction: column;
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  padding: 0px 20px;
  overflow: auto;
`;

const ExampleHeader = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin: 5px 0px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
`

const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
`

const Description = styled.p``

const helpItemMap = new Map(helpEntries.map(helpEntry => [helpEntry.helpId, helpEntry]))


const HelpItem = ({helpType, helpId, addHistory, margin}) => {

  let history = useHistory()
  const helpItem = useHelpItem(helpType, helpId)

  const getHelpTypeStr = (helpItem) => {
    let helpTypeStr = ""
    if (helpItem.gramElemTypeId !== undefined) {
      helpTypeStr = gramElemTypeHelp
    } else if (helpItem.sentPartTypeId !== undefined) {
      helpTypeStr = sentPartTypeHelp
    } else if (helpItem.gramElemCatTypeId !== undefined) {
      helpTypeStr = gramElemCatTypeHelp
    } else if (helpItem.genElemTypeId !== undefined) {
      helpTypeStr = genElemTypeHelp
    } else if (helpItem.answerStr !== undefined) {
      helpTypeStr = gamesTypeHelp
    }

    return helpTypeStr    
  }

  const getHelpUrl = (helpItem) => {
    const helpTypeStr = getHelpTypeStr(helpItem)

    if (helpTypeStr.length === 0) {
      return ""
    }

    const itemName = helpItemName(helpItem)
    if (itemName === "undefined-help") {
      return ""
    }

    return "/help/" + helpTypeStr + "/" + itemName
  }

  // If this is called from the modal during a game, we want to add the url for
  // the current helpItem before adding the one we want to transition to so the
  // user can come back to it. See the GrammarGames React Design doc for detais.
  const handleRelatedHelp = (relatedHelpId) => {
    if (addHistory) {
      const helpUrl = getHelpUrl(helpItem)
      if (helpUrl.length > 0) {
        history.push(helpUrl)
      }
    }

    if (helpItemMap.has(relatedHelpId)) {
      const helpUrl = getHelpUrl(helpItemMap.get(relatedHelpId))
      if (helpUrl.length > 0) {
        history.push(helpUrl)
      }
    }
  }

  const helpExamples = () => {
  	if (helpItem.examples.length === 0) {
  		return null
  	}
    const examples = helpItem.examples.map((example, ind) => 
  		<HelpExample key={ind} exampleData={example} />
  	)
  	const exampleHeader = (examples.length === 1) ? "Example:" : "Examples:"

  	return (<Fragment>
  			     <ExampleHeader>{exampleHeader}</ExampleHeader>
  			     <p>{examples}</p>
  			   </Fragment>)
  }

  const relatedHelpLinks = () => {
    if (helpItem.relatedHelpIds === undefined || helpItem.relatedHelpIds.length === 0) {
      return null
    }
    const links = helpItem.relatedHelpIds.map((helpId) => {
      if (helpItemMap.has(helpId)) {
        const relatedItem = helpItemMap.get(helpId)
        return (<RelatedHelpLink 
          key={helpId} 
          relatedHelpId={helpId} 
          title={relatedItem.title} 
          handleHelp={handleRelatedHelp} />)
      } else {
        return null
      }
    })

    if (links.length === 0) {
      return null
    }

    const linksHeader = "Related Help Links:"

    return (<Fragment>
              <ExampleHeader>{linksHeader}</ExampleHeader>
              {links}
            </Fragment>)
  }

  const getMarginStr = () => {
    let marginStr = "10%"
    if (margin !== undefined) {
      marginStr = margin.toString() + '%'
    }

    return marginStr
  }

  const getSubtitle = () => {
    const helpTypeStr = getHelpTypeStr(helpItem)
    switch (helpTypeStr) {
      case gramElemTypeHelp:
        return "( Grammar Element )"
      case sentPartTypeHelp:
        return "( Sentence Part )" 
      case gramElemCatTypeHelp:
        return "( Grammar Element Category )"
      default:
        return ""
    }
    
  }


  return (
  	<HelpItemStyle margin={getMarginStr} >
  	  <Title>{helpItem.title}</Title>
      <Subtitle>{getSubtitle()}</Subtitle>
  	  <Description>{ ReactHtmlParser(helpItem.description) }</Description>
  	  {helpExamples()}
      {relatedHelpLinks()}
  	</HelpItemStyle>
  )
}

export default HelpItem
