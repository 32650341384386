import React from 'react';

class GoFundMe extends React.Component {

  componentDidMount(){
    window.location.href = "https://www.gofundme.com/f/grammar-games-for-english-language-learners";
  }

  render() {
    return (
      <div>
       <h2>Go Fund Me</h2>
      </div>
    )
  }
}

export default GoFundMe;