import React from 'react'
import { getAnsInterjection } from 'components/GameElements/GameUtils'
import { action } from "mobx"
import { observer } from "mobx-react-lite"
import styled from 'styled-components'


const QuesAnsGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const QuesAnsText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
`;

const InputText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-end;
  margin: 3px 0px;
`;

const Button = styled.button`
  background-color: ${props => props.backgroundColor};
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  border: 0px;
  border-radius: 100px 100px 100px 100px;
  padding: 5px 20px;
  margin: 10px 2px 20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);  
  outline: none;
  cursor: pointer;
`;


const InputQuestion = observer(({gameQues, finishHandler}) => {

  //let textInput = React.createRef();

  const handleAnswer = () => {
  	const isCorrect = gameQues.inputAnswer === gameQues.correctInputStr
  	gameQues.setAnswer(isCorrect)
  }

  const getQuestionComponent = () => {
  	// let noInput = true
  	// if (textInput !== null && textInput.current !== null && 
  	// 	textInput.current.value !== undefined && textInput.current.value.length > 0) {
  	// 	noInput = false
  	// }
  	return (
  		<QuesAnsGroup>
	  	  <QuesAnsText>
	  		{gameQues.questionText}
	  	  </QuesAnsText>
	  	  <InputText>
	  	  	<input
	  	  	  type="text" 
	  	  	  maxLength="50" 
	  	  	  placeholder="Enter value"
	  	  	  size={gameQues.correctInputStr.length+10}
	  	  	  onChange={action(e => gameQues.inputAnswer = e.target.value)}
	  	  	/>
	  	  </InputText>
	      <Button 
	        backgroundColor={gameQues.colorArray[0]}
	        onClick={handleAnswer}
	        disabled={gameQues.inputAnswer.length===0}
	      >
	        Check My Answer
	      </Button>
  		</QuesAnsGroup>
  	)
  }

  const getAnswerComponent = () => {
  	return (
  		<QuesAnsGroup>
  		  <QuesAnsText>
  		    {getAnsInterjection(gameQues.isCorrect) + " " + gameQues.answerText}
  		  </QuesAnsText>
  		  <Button 
          // The last color is for the Continue button
          backgroundColor={gameQues.colorArray[0]}
  		    onClick={() => finishHandler()}
  		  >
  		    Continue
  		  </Button>
  		</QuesAnsGroup>
  	)
  }  

  const getPhaseComponent = () => {
    if (gameQues.isAnswered === undefined) {
        // Some question types need to do some type of initialization. Not needed for InputQuestions.
      gameQues.isAnswered = false
      return getQuestionComponent()
    } else {
      if (gameQues.isAnswered) {
        return getAnswerComponent()
      } else {
        return getQuestionComponent()
      }      
    }
  }

  return (
  	<React.Fragment>
  	  {getPhaseComponent()}
  	</React.Fragment>
  )
})

export default InputQuestion
