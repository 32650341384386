import React from 'react'
import { helpEntries } from './helpData'


const useGramElemTypeHelp = () => {

  const [gramElemTypeHelpItems, setGramElemTypeHelpItems] = React.useState(new Map())

  if (gramElemTypeHelpItems.size === 0) {
  	let gramElemMap = new Map()
  	helpEntries.forEach(helpEntry => {
  	  if (helpEntry.gramElemTypeId !== undefined) {
  	  	gramElemMap.set(helpEntry.gramElemTypeId, helpEntry)
  	  }
  	}) 
  	setGramElemTypeHelpItems(gramElemMap)
  }

  return gramElemTypeHelpItems
}

export default useGramElemTypeHelp
