import { SentenceWord } from './SentenceWord'
import { makeAutoObservable, action, computed } from "mobx";

export class WordSet {

	sentenceWords = []

	noHighlightColor = '#ffffff'
	curHighlightColor = this.noHighlightColor
	maxLinePixels = 600
	anyWordHighlighted = false

	// A callback used to save the current state of the words
	//saveWordsCallback = undefined

	constructor(noHighlightColor) {

		makeAutoObservable(this, {
			noHighlightColor: false,
            initWordSet: action,
            clearUnderlines: action,
            setAllDisabled: action,
            setDisabled: action,
            setWordCurrentColor: action,
            setWordCorrectColor: action,
            checkpoint2AllWords: action,
            restoreCheckpoint2AllWords: action,
            wordColorChanged: action,
            lineBounds: computed,
        })

		// The caller can override noHighlightColor if they want
        this.noHighlightColor = noHighlightColor === undefined ? this.noHighlightColor : noHighlightColor
	}

	// Reset with a new set of words
	initWordSet(words) {
		this.sentenceWords = []
		words.forEach((word, ind) => {

			// We use isPunctuation to determine the spacing to use for a 'word'. If it is punctuation, we don't
			// want the space on the left, but we do still want it on the right. We also need to handle the 
			// words that come before punctuation since for them, we don't want the spacing on the right.
			// We add the nextIsPunctuation property here before initializing the SentenceWord. 
			word.nextIsPunctuation = false
			if (ind < words.length-1) {
				word.nextIsPunctuation = words[ind+1].isPunctuation
			}

			const sentWord = new SentenceWord()
			sentWord.initWord(word, this)
			this.sentenceWords.push(sentWord)
		})

		this.setAllDisabled(true)
		this.curHighlightColor = this.noHighlightColor
	}

	clearUnderlines() {
		this.sentenceWords.forEach(word => {
			word.isUnderlined = false
			word.setCheckpoint1()
		})
	}

	// Transition from displaying an incorrect word to the correct word.
	displayCorrectWords(wordPosArr) {
		wordPosArr.forEach(wordPos => {
			this.sentenceWords[wordPos].updateDisplayedLabel()
		})
	}

	// Helper routine to set all SentenceWords enabled or disabled
	setAllDisabled(isDisabled) {
		this.sentenceWords.forEach(word => {
			word.setIsDisabled(isDisabled)
		})
	}

	// Helper routine to set the given words enabled or disabled
	setDisabled(isDisabled, wordList) {
		wordList.forEach(ind => {
			this.sentenceWords[ind].isDisabled = isDisabled
		})
	}

	// Helper routine to set the currentColor of the given words and set the underline if specified. 
	// We always checkpoint the color as well.
	setWordCurrentColor(color, wordList, underline) {
		wordList.forEach(ind => {
			this.sentenceWords[ind].curColor = color
			if (underline) {
				this.sentenceWords[ind].isUnderlined = true
			}
			this.sentenceWords[ind].setCheckpoint1()
		})
	}

	// Helper routine to set the correctColor of the given words.
	setWordCorrectColor(color, wordList) {
		this.curHighlightColor = color
		wordList.forEach(ind => {
			this.sentenceWords[ind].correctColor = color
		})
	}

	// Helper routine to do a checkpoint2 on all words.
	checkpoint2AllWords() {
		this.sentenceWords.forEach(word => {
			word.setCheckpoint2()
		})
	}

	// Helper routine to do a checkpoint2 on all words.
	restoreCheckpoint2AllWords() {
		this.sentenceWords.forEach(word => {
			word.restoreCheckpoint2()
		})
	}

	wordColorChanged() {
		this.anyWordHighlighted = this.sentenceWords.reduce((isSet, word) => {
			if (word.currentColor === this.curHighlightColor) {
				return true
			} else {
				return isSet
			}
		}, false)
	}

	// Check to see if words associated with the given color are all correct. It is the
	// caller's responsibility to set the correctColor for each SentenceWord initially
	// and to track what the various highlight colors represent.
	isColorCorrect(color) {
		return this.sentenceWords.reduce((correct, word) => {
			// If this word has the color we are checking as either currentColor (i.e., it is)
			// highlighted in that color) or correctColor (it should be highlighted), then
			// factor it into whether the color is correct. Otherwise ignore it.
			if (word.currentColor === color || word.correctColor === color) {
				return correct && word.currentColor === word.correctColor
			} else {
				return correct
			}
		}, true)
	}

	allColorsCorrect() {
		return this.sentenceWords.reduce((correct, word) => {
			// All words should match whatever correctColor is specified
			return correct && word.currentColor === word.correctColor
		}, true)
	}

	// Indices of the sentenceWords that begin each line with a final value of max sentenceWord index.
	get lineBounds() {

	    let lineBounds = []

		let maxLineChars = this.maxLinePixels / 10

        // We base the line breaks on the total number of characters
        const charPadLen = 1
        let totalNumChars = (this.sentenceWords.length * charPadLen)
        for (let i=0; i < this.sentenceWords.length; i++) {
          totalNumChars += this.sentenceWords[i].displayedLabel.length
        }

        // See how many lines we need
        const numLines = Math.ceil(totalNumChars / maxLineChars)
        const avgLineLen = totalNumChars / numLines

        // Now break up the lines
        lineBounds.push(0)
        let curNumChars = 0
        for (let i=0; i < this.sentenceWords.length; i++) {
            curNumChars += (this.sentenceWords[i].displayedLabel.length + charPadLen)
            if (curNumChars > avgLineLen) {
                // First make sure we're not creating a line boundary right before punctuation.
                if (i < this.sentenceWords.length-1 && this.sentenceWords[i+1].isPunctuation) {
                  i += 1
                }
                lineBounds.push(i+1)
                curNumChars = 0
            } else if (i === this.sentenceWords.length-1) {
                lineBounds.push(i+1)
                break
            }
        }

	    return lineBounds
	}
}
