import React, { Fragment } from 'react';
import TitleHeader from 'components/Main/TitleHeader';
import { Link, useRouteMatch } from "react-router-dom"
import styled from 'styled-components'

// const StyledLink = styled(Link)`
//   font-family: 'Roboto', sans-serif;  
//   padding: 0px 10px;
// `;

const GamesContent = styled.div`
  width: 600px;
  font-family: 'Roboto', sans-serif;  
  padding: 0px 10px;
`;

const Title = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0px;
`;

const GameType = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const Divider = styled.hr`
  width: 500px;
  border: 1px solid black;
  border-color: '#101c3d';
  background-color: '#101c3d';
  text-align: center;
`;

const Button = styled.button`
  background-color: ${props => props.backgroundColor};
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  border: 0px;
  border-radius: 100px 100px 100px 100px;
  padding: 5px 20px;
  margin: 10px 4px 20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);  
  outline: none;
  cursor: pointer;
`;



const Home = () => {

  let match = useRouteMatch()

  const getBreak = () => {
    return (
      <Fragment>
        <p></p>
        <Divider/>
        <p></p>
      </Fragment>
    )
  }

  return (
  	<React.Fragment>
      <TitleHeader title="Grammar Games" />
      <GamesContent>
        <Title>
          What Games Do We Have?<br/>
        </Title>
        {getBreak()}
        <GameType>Find the Pattern</GameType>
        <p>
          Start by learning to identify the patterns in sentences 
          with <Link to={`${match.path}/ftp-intro`}>Find the Pattern</Link>. How many clauses does a sentence
          have? How many predicates in each clause? And what's the pattern in each predicate? Does it 
          have a direct object? An indirect object? A predicate adjective? You'll have to see if you 
          can tell. Don't worry. We'll guide you along the way whenever you need help until you get
          comfortable identifying the patterns!<br/>
        </p>

        <Link to='games/ftp'>
          <Button 
            backgroundColor={'#4fc3f7c0'}
          >
            Let's Play!
          </Button>
        </Link>

        {getBreak()}
 
        <Title>
          What Other Games Are Coming?<br/>
        </Title>

        {getBreak()}
        <GameType>What Part Is It?</GameType>
        <p>
          Part of speech, that is. Learn to identify nouns and pronouns, adjectives and adverbs,
          verbs, prepositions, conjunctions, and more. We'll have you focus on these in groupings
          so you can build up your knowledge in each area. Then we'll combine them and mix it up to
          give you more of a challenge. Be careful! Some words can be more than one part of speech,
          depending on how they are used! Did I mail the letter? Or did I pick up the mail? Did I 
          complete my work? Or did I work until I finished? You'll have to figure out what's happening!<br/><br/>
        </p>
 
        {getBreak()}
        <GameType>All About Adverbs. Or Adjectives. Or Nouns.</GameType>
        <p>
          There are many different types of adverb objects. There are simple adverbs,
          compound adverbs, adverb prepositional phrases, adverb infinitives or 
          infinitive phrases, adverb dependent clauses, and so on.<br/><br/>

          The same is true for adjective objects and noun objects. There are a variety
          of different types of each and these games will help you learn to identify 
          the objects along with their types.<br/><br/>

          In addition to identifying the types of objects, we'll also help you to figure
          out how they operate in the sentence. For adjective and adverb objects, what
          is each one modifying in the sentence? For a noun object, what is its function?
          Is it the subject of a clause? Is is a direct object? The object of a prepositional
          phrase? We'll help you figure it out!<br/><br/>
        </p>
 
        {getBreak()}
        <GameType>Verb or Verbal?</GameType>
        <p>
          Verbals can make it hard to figure out where the real action is in a sentence. Is 
          it really a verb? Or is it a verb functioning as a noun? An adjective? 
          An adverb? Root out the real function of the verb with this game!<br/><br/>
        </p>
 
        {getBreak()}
        <GameType>Make Them Agree!</GameType>
        <p>
          The subject and verb must always agree. No arguments allowed! Can you identify these
          parts and figure out if they are in alignment? And, if they aren't playing nicely,
          what change will help them get along?<br/><br/>

          This is a lot easier to do in simple sentences, but let's put some dependent clauses
          in the mix and see if you can still figure it out. Or, let's add some modifiers to 
          the subject so the noun of the subject and the verb aren't right next to each other 
          and see how you do!<br/><br/>
        </p>
 
        {getBreak()}
        <GameType>What's Wrong With This Sentence?</GameType>
        <p>
          Is there anything wrong with this sentence? Maybe. Maybe not. You have to figure it out.
          If there is, what's the problem? And how do you fix it? You'll have to see if you 
          can tell us.<br/><br/>
        </p>
 
        {getBreak()}
        <GameType>Which Homophone Is It?</GameType>
        <p>
          Is it to, too, or two? Is it your or you're? Is it their, they're, or there? They sound
          the same, but they're all so different! (See what I did there? And here! Ha!) It all
          depends on the context for these and so many other homophones. Understand the role it plays 
          in the sentence and that may help!<br/><br/>
        </p>
 
        {getBreak()}
        <GameType>Confusing Pairs!</GameType>
        <p>
          I'm confused. It doesn't sound exactly the same, but it's so similar. Which do I need?
          You'll have to tell us. <br/><br/>
          Did I loose it? Or did I lose it? Did I accept it? Or did I
          except it? Does he need to be quiet? Or does he need to quit? It can be quite confusing,
          but see if you can figure it out!<br/><br/>
        </p>
 
        {getBreak()}
        <GameType>It's So Irregular!</GameType>
        <p>
          I begin. I beginned? No, that's not right. So what did I do? I might forgive. But I hadn't forgived.
          And I hadn't even forgave. I eat. I eated? I had ate? Nope. See if you can figure out what 
          happened in the past. Or the past participle.<br/><br/>
        </p>
 
        {getBreak()}
        <GameType>Sit or Set? Lie or Lay? Rise or Raise?</GameType>
        <p>
          These verb pairs can be the most confusing! One is used with a direct object and the other 
          isn't. So which do you need? First you'll need to figure out if there's a direct object and
          then you'll know. But then what if it happened in the past? I lay down to take a nap. Or I laid
          down to take a nap? Hmm... I may need to sleep on that one!<br/><br/>
        </p>
 
        {getBreak()}
        <GameType>Dissect It!</GameType>
        <p>
          Can you fully dissect all the grammar elements and parts of a sentence?
          You'll be able to find out with our Dissect It! game.<br/><br/>

          This is a variation on sentence diagramming. You'll start by identifying the 
          independent clause. Or maybe there is more than one! Then you'll drill down into
          each one identifying the subject, the predicate or possibly predicates, and then all 
          of the parts of each predicate, along with any adverbs that give more information.<br/><br/>

          The nice thing with this game is that we naturally guide you along the way. If you make an
          initial mistake in identifying a subject, for example, you can keep working at it until you 
          get it right and that can help you with identifying the other parts of the clause. <br/><br/>

          For each grammar element you encounter, you'll also dissect it as well. For example, 
          when you identify an adverb object, we'll have you determine what kind of adverb object 
          it is. Is it a simple adverb? An adverb prepositional phrase? Is it a adverb dependent clause? 
          Maybe an infinitive phrase? Once you figure that out, we'll help you to dissect that element 
          as well until you've worked through every part of the sentence.<br/><br/>

          <br/><br/>
        </p>
 
      </GamesContent>
    </React.Fragment>
  )
}

export default Home
