import React from 'react'
import { helpEntries } from './helpData'


const useGramElemCatTypeHelp = () => {

  const [gramElemCatTypeHelpItems, setGramElemCatTypeHelpItems] = React.useState(new Map())

  if (gramElemCatTypeHelpItems.size === 0) {
  	let gramElemMap = new Map()
  	helpEntries.forEach(helpEntry => {
  	  if (helpEntry.gramElemCatTypeId !== undefined) {
  	  	gramElemMap.set(helpEntry.gramElemCatTypeId, helpEntry)
  	  }
  	}) 
  	setGramElemCatTypeHelpItems(gramElemMap)
  }

  return gramElemCatTypeHelpItems
}

export default useGramElemCatTypeHelp
