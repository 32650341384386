import React from 'react'
import { getAnsInterjection } from 'components/GameElements/GameUtils'
import { action } from "mobx"
import { observer } from "mobx-react-lite"
import styled from 'styled-components'

const QuesAnsGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const QuesAnsText = styled.p`
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
`;

const Button = styled.button`
  background-color: ${props => props.backgroundColor};
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  border: 0px;
  border-radius: 100px 100px 100px 100px;
  padding: 5px 20px;
  margin: 10px 2px 20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);  
  outline: none;
  cursor: pointer;
`;

const HighlightQuestion = observer(({wordSet, gameQues, checkHandler, finishHandler}) => {

  // They selected the right answer, so we just display the correct message
  // briefly and move them on (i.e., they won't have to press Continue.)
  const automateTransition = action("Radio automateTransition", () => {
    setTimeout(() => finishHandler(), 1500)
    gameQues.autoTrans = true
  })

  const handleCheck = () => {
    const isCorrect = wordSet.isColorCorrect(gameQues.highlightColor)
    gameQues.setAnswer(isCorrect)
    if (isCorrect) {
      automateTransition()
    }

    // Give the calling component a chance to respond to the answer - i.e., the answer 
    // wasn't correct so the caller needs to highlight the correct words.
    checkHandler()
  }

  const getQuestionComponent = () => {
  	return (
  		<QuesAnsGroup>
	  	  <QuesAnsText>
	  		{gameQues.questionText}
	  	  </QuesAnsText>
        <Button 
          backgroundColor={gameQues.colorArray[0]}
          onClick={handleCheck}
          disabled={gameQues.isAnswered || !wordSet.anyWordHighlighted}
        >
          Check My Answer
        </Button>
  		</QuesAnsGroup>
  	)
  }

  const getAnswerComponent = () => {
    if (gameQues.autoTrans) {
      return (
        <QuesAnsGroup>
          <QuesAnsText>
            {getAnsInterjection(gameQues.isCorrect) + " " + gameQues.answerText}
          </QuesAnsText>
        </QuesAnsGroup>
      )
    } else {
      return (
        <QuesAnsGroup>
          <QuesAnsText>
            {getAnsInterjection(gameQues.isCorrect) + " " + gameQues.answerText}
          </QuesAnsText>
          <Button 
            backgroundColor={gameQues.colorArray[0]}
            onClick={() => finishHandler()}
          >
            Continue
          </Button>
        </QuesAnsGroup>
      )      
    }
  }  

  const getPhaseComponent = () => {
    if (gameQues.isAnswered !== undefined) {
      if (gameQues.isAnswered) {
        return getAnswerComponent()
      } else {
        return getQuestionComponent()
      }      
    } else {
      gameQues.isAnswered = false
      return getQuestionComponent()
    }
  }

  return (
  	<React.Fragment>
  	  {getPhaseComponent()}
  	</React.Fragment>
  )
})

export default HighlightQuestion
