import React from 'react';
import FAQ from "../Help/FAQ"
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import styled from 'styled-components';

const Title = styled.div`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin: 20px 0px;
`;

const SectionSelectStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;
`;

const RadioOption = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-end;
  margin: 3px 0px;
`;

const SelectionLabel = styled.label`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
`;

const Divider = styled.hr`
  width: 500px;
  border: 1px solid black;
  border-color: '#101c3d';
  background-color: '#101c3d';
  text-align: center;
`;


const HomeSelectContent = observer(({mainStore}) => {

  const handleSelectionChange = (changeEvent) => {
    if (changeEvent.target.checked) {
      mainStore.setSelectedSection(changeEvent.target.value)
    }
  }

  const sectionOptions = () => {

    const sectionOpts = mainStore.sectionSelectOpts

    const allOptions = sectionOpts.map((opt, ind) => {

      return <RadioOption key={opt.value}>
        <input 
          type="radio" 
          id={opt.value} 
          name="selectOption" 
          value={opt.value}
          checked={(opt.value === mainStore.selectedSection)}
          onChange={handleSelectionChange}
        />
        <SelectionLabel htmlFor={opt.value}>{opt.label}</SelectionLabel>
      </RadioOption>
    })

    return allOptions
  }

  const getWhatsTheGoalContent = () => {
    return (
      <React.Fragment>
        <Title>What's The Goal?</Title>

        <p>
          At Leading Step, our goal is to help you improve your understanding of how 
          the English language works. We do this using a variety of games that help you 
          learn to identify the various parts of a sentence.<br/><br/>

          It's one thing to know the definition of a grammar element, such as a direct object, 
          and to see a few examples, but can you identify them in a sentence and understand how they
          operate? Being able to practice is important in this journey of learning
          and that's where we can help!<br/><br/>

          The more you learn to identify the various elements we use to create sentences, 
          the more it can improve both creativity in your own writing and your comprehension 
          of other writing.<br/><br/>
        </p>
      </React.Fragment>
    )
  }

  const getDBWorkContent = () => {
    return (
      <React.Fragment>
        <Title>How Does The Database Work?</Title>

        <p>
          We've developed a database of millions of unique sentences using what we call
          a sentence pattern. As an example, all of the following sentences could come 
          from one sentence pattern: <br/>
        </p>

          <ul>
            <li>A dog was walking down the road.</li>
            <li>A brown dog was walking down the road.</li>
            <li>A brown furry dog was walking down the road.</li>
            <li>A brown furry dog with a bushy tail was walking down the road.</li>     
            <li>A brown furry dog with a bushy tail was walking down the road when it saw a squirrel
              dart across the road.</li>
            <li>A brown furry dog with a bushy tail was walking down the road when it saw a squirrel
              dart across the road and started to chase it.</li>
          </ul>

        <p>
          When we create a game, we pull sentences that have whatever specific elements we need 
          so you can have thousands of examples to work on. We create a variety of levels as well, so you 
          can get started with simple sentences, but then work your way up to using much more
          complex examples.<br/><br/>

        </p>
      </React.Fragment>
    )
  }

  const getGamesWorkContent = () => {
    return (
      <React.Fragment>
        <Title>How Do The Games Work?</Title>

        <p>
          All of our games take you through a series of questions about a sentence. We ask you to
          highlight specific parts of the sentence and we ask a variety of multiple choice questions.<br/><br/>

          It's no problem if you're not sure of an answer. Just try your best and we'll guide you
          with the right answer whenever you need it.<br/><br/>

          You can monitor your progress at each level with the progress circle at the top right of the
          screen. Our goal is to help you get comfortable with each different question type at each
          level and once you've done that, we'll move you on to the next level automatically.<br/><br/>

          We want you to have flexibility in your learning, so if you ever decide you want to try out a 
          different level, you can just click the current level that's displayed under the progress circle and
          you'll have the option to select whatever level you want.<br/><br/>
        </p>
      </React.Fragment>
    )
  }

  const getMoreGamesInfoContent = () => {
    return (
      <React.Fragment>
        <Title>More About The Games</Title>
        <p>

          Our first game is <b><i>Find the Pattern</i></b>. English sentences are created from a small number of 
          patterns and this game helps you learn to identify those patterns.<br/><br/>

          Practice identifying the structure and purpose of sentences along with various elements such
          as subjects, verb phrases, direct and indirect objects, predicate
          adjectives, predicate nominatives, and so on. At the higher levels, you'll also identify dependent 
          clauses, compound sentences, and all of their elements as well.<br/><br/>

          We'll walk you through the process, learning to identify action verbs vs. linking verbs as well 
          as transitive verbs vs. intransitive verbs. That helps you to narrow down the options and determine 
          the pattern of each predicate. You'll have lots of sentences to practice on, so you can keep trying until the
          process becomes easier for you!<br/><br/>

          See full details in the <Link to='/games/ftp-intro'>Find the Pattern intro</Link>.<br/><br/>

          We have quite a few other ideas of new games we'd like to develop as well, as time and resources allow. 
          See our full <Link to='/games'>Games page</Link> for details.<br/><br/>
        </p>
      </React.Fragment>
    )
  }

  const getFAQContent = () => {
    return (
      <React.Fragment>
        <Title>Frequently Asked Game Questions</Title>
        <FAQ/>
      </React.Fragment>
    )
  }

  const getSelectedContent = () => {

    switch (mainStore.selectedSection) {
      case "1":
        return getWhatsTheGoalContent()

      case "2":
        return getDBWorkContent()

      case "3":
        return getGamesWorkContent()

      case "4":
        return getMoreGamesInfoContent()

      case "5":
        return getFAQContent()

      default:
        return getWhatsTheGoalContent()
    }
  }

  return (
    <React.Fragment>
      <SectionSelectStyle>
        {sectionOptions()}
      </SectionSelectStyle>
      <Divider/>
      {getSelectedContent()}
    </React.Fragment>
  )
})

export default HomeSelectContent;
