
// This is a general routine used to select the specified number of values (numValues) from the
// given array of possibilities (valuesArr). The caller might need to ensure that one or more
// values are definitely selected out of the possibilities and if so, they are designated in 
// forcedValues. This is used, for example, if we have values for a multiple choice question,
// but only want to display a some number of the answers - e.g., maybe there are 10 possible 
// answers, but we only want to show 5 possibilities. In this case, we have to ensure the correct
// value is selected, but the other values can be any of the remaining possibilities. 
export const getUniqueValues = (numValues, valuesArr, forcedValues) => {

  let valuesMap = new Map()
  let valueInd

  // If there are any forced values, go ahead and add them to the map first.
  // This allows us to choose whichever values we specifically want (e.g., a 
  // correct answer), and then fill in with other answers beyond that.
  if (forcedValues !== undefined) {
    forcedValues.forEach(value => {
      valuesMap.set(value, true)
    })
  }

  while (valuesMap.size < numValues) {
    valueInd = Math.floor(Math.random() * valuesArr.length)
    valuesMap.set(valuesArr[valueInd], true)
  }

  let selectedValues = Array.from(valuesMap.keys())

  // If we have forcedValues, we want to randomize the array so the forcedValues aren't always the first ones
  if (forcedValues !== undefined) {
    valuesMap = new Map()
    while (valuesMap.size < numValues) {
      valueInd = Math.floor(Math.random() * selectedValues.length)
      valuesMap.set(selectedValues[valueInd], true)
    }

    selectedValues = Array.from(valuesMap.keys())
  }

  return selectedValues
}


export const getUniqueColors = (numColors, availColorsMap) => {
  return getUniqueValues(numColors, Array.from(availColorsMap.values()))
}


export const arrayContains = (arr, value) => {
  return arr.reduce((contains, arrVal) => {
    return contains || (arrVal === value)
  }, false)
}


export const getAnsInterjection = (isCorrect) => {
  const correctInterjs = ["Excellent!", "That's right!", "Fantastic!", "Terrific!"]
  const inCorrectInterjs = ["Oops!", "Whoops!"]

  let interjs = inCorrectInterjs
  if (isCorrect) {
	interjs = correctInterjs
  }

  return interjs[Math.floor(Math.random() * interjs.length)]
}
