import useGramElemHelpId from './useGramElemHelpId'
import useSentPartHelpId from './useSentPartHelpId'
import useGramElemCatHelpId from './useGramElemCatHelpId'
import useGamesHelpId from './useGamesHelpId'
import { gramElemTypeHelp, sentPartTypeHelp, gramElemCatTypeHelp, gamesTypeHelp } from 'components/Help/Help'


const useCatHelpIds = (helpType, helpTypeRef) => {

  const gramElemHelpId = useGramElemHelpId(helpTypeRef)
  const sentPartHelpId = useSentPartHelpId(helpTypeRef)
  const gramElemCatHelpId = useGramElemCatHelpId(helpTypeRef)
  const gamesHelpId = useGamesHelpId(helpTypeRef)

  switch (helpType) {
  	case gramElemTypeHelp:
      if (gramElemHelpId !== 0) {
        return [gramElemHelpId]
      }
      break
    case sentPartTypeHelp:
      if (sentPartHelpId !== 0) {
        return [sentPartHelpId]
      }
      break
    case gramElemCatTypeHelp:
      if (gramElemCatHelpId !== 0) {
        return [gramElemCatHelpId]
      }
      break
    case gamesTypeHelp:
      if (gamesHelpId !== 0) {
        return [gamesHelpId]
      }
      break
    default:
      return []
  }

  return []
}

export default useCatHelpIds
