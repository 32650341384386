import React from 'react'
import useCatHelpIds from './HelpHooks/useCatHelpIds'
import HelpItem from './HelpItem';
import { useHistory } from "react-router-dom"
import { gramElemTypeHelp, sentPartTypeHelp, gramElemCatTypeHelp, gamesTypeHelp } from 'components/Help/Help'
import styled from 'styled-components';

const HelpItemGroupStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
`;


const HelpItemGroup = ({helpType, helpTypeRef}) => {

  let history = useHistory()
  const catHelpIds = useCatHelpIds(helpType, helpTypeRef)

  const [indexType, setIndexType] = React.useState("")

  React.useEffect(() => {
    if (indexType.length > 0) {
      // Probably should grab the existing path here instead of assuming 'help'. tbd
      history.replace('/help/' + indexType + '/index')
      setIndexType("") 
    }
  }, [indexType, history])


  const getHelp = () => {
    // If we couldn't find any valid help items, then we'll display the index instead.
    // We're choosing not to display an error here, but just move to a default. 
    if (catHelpIds.length === 0) { 
      if (indexType.length === 0) {
        let curHelpType = helpType
        switch (helpType) {
          case gramElemTypeHelp:
          case sentPartTypeHelp:
          case gramElemCatTypeHelp:
          case gamesTypeHelp:
            break

          default:
            // For now, we'll just choose Grammar Element to be the default. We might want
            // to create an overall help index instead. tbd.
            curHelpType = gamesTypeHelp
            break
        }

        setIndexType(curHelpType)
      }
      return null
    } else {
      const helpItems = catHelpIds.map((catHelpId) => 
        <HelpItem key={catHelpId} helpType={helpType} helpId={catHelpId} />
      )
      return (
        <HelpItemGroupStyle>
          {helpItems}
        </HelpItemGroupStyle>
      )
    }
  }

  return (
    <div>
      {getHelp()}
    </div>
  )
}

export default HelpItemGroup
