import React from 'react'
import FAQ from "./FAQ"
import TitleHeader from 'components/Main/TitleHeader';
import HelpController from './HelpController';
import DissectItHelp from './DissectItHelp'
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { FaRegQuestionCircle } from "react-icons/fa";
import styled from 'styled-components'

export const gramElemTypeHelp = "gram-elem"
export const sentPartTypeHelp = "sent-part"
export const gramElemCatTypeHelp = "gram-elem-cat"
export const genElemTypeHelp = "gen-elem"
export const gamesTypeHelp = "games"

const HelpStyle = styled.div`
  width: 100%;
  font-family: 'Roboto', sans-serif;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OverviewStyle = styled.div`
  width: 80%;
  text-align: left;
  padding: 20px 10px;
`;

const CenteredText = styled.p`
  text-align: center;
  font-size: 20px;
  font-weight: 500;
`;


const Help = () => {

 const helpOverview = () => (
 	<OverviewStyle>
 	  <CenteredText>
 	    Types of Game Questions
 	  </CenteredText>
 	  <p>
 	    In each of our games, we display a sentence and then ask you a series of questions about that sentence,
 	    depending on the type of game you selected.<br/><br/>

 	    We may ask you a multiple choice question such as what type of pattern a particular predicate has. Or we
 	    may ask you to highlight a particular part of the sentence such as the subject of a clause or a direct
 	    object. In some cases, we may also ask you to fill in a blank in a sentence.<br/><br/>

 	  </p>

 	  <CenteredText>
 	    Game Levels and Complexity
 	  </CenteredText>

 	  <p>
 	    All of our games have multiple levels. We start you out with very simple sentences and questions so 
 	    you can start to understand the basics. Then, once you've mastered that level, we move you to the next
 	    level where the sentences will be a bit more complex and the questions may be more challenging
 	    as well. We'll keep doing that until you've completed all the levels for a game.<br/><br/>

 	    You can always see your progress and the current level you're on at the top right of the screen. We move
 	    you through the levels automatically based on how you're doing, but if you ever decide you want to select
 	    your own level, you can do that too. Just click on the current level and you'll have the option to set
 	    whatever level you prefer.<br/><br/>

 	    We have more detailed help for each game and, as part of that, we describe the types of questions and sentences
 	    you'll encounter at each level, so you can use that to select the level you want to play. Use the 
 	    'View Game Level Info' button to see that info.<br/><br/>

	  </p>

 	  <CenteredText>
 	    Getting Help Along the Way
 	  </CenteredText>

 	  <p>
	  	Whenever you see a question mark icon that looks like <FaRegQuestionCircle />, you can click it to get
	  	more help about a particular term we're using. We want to help you understand what we're asking for!<br/><br/>

	  	Keep in mind, though... We're not trying to teach you all of the grammar concepts we're covering in detail. 
	  	We'll just give you a reminder in case you forget. We assume you're learning grammar 
	  	from a teacher or maybe online or from a book you're using. Our goal is to help
	  	you practice what you're learning so you can really understand how the English language works!<br/><br/>

	  </p>

 	  <CenteredText>
 	    Frequently Asked Game Questions
 	  </CenteredText>

 	  <FAQ/>

  	<CenteredText>
	  	<a href="/help/games/index">See All Game Content Help</a> 
	  	<br/><br/>
  	</CenteredText>

  	<CenteredText>
	  	Have fun learning!<br/><br/>
	  </CenteredText>

 	</OverviewStyle>
 )


 let { path } = useRouteMatch();

  return (
    <HelpStyle>
      <TitleHeader title="Grammar Help" />

	  <Switch>
	    <Route exact path={path}>
	      {helpOverview()}
	    </Route>
	    <Route path={`${path}/dissect-it-help`}>
	      <DissectItHelp />
	    </Route>
	    <Route path={`${path}/:helpType/:itemName`}>
	      <HelpController />
	    </Route>
	  </Switch>
    </HelpStyle>

  )
}

export default Help
