import React from 'react'
import ButtonQuestion from 'components/GameElements/ButtonQuestion'
import DisplaySentWords from 'components/GameElements/DisplaySentWords'
import HighlightQuestion from 'components/GameElements/HighlightQuestion'
import InputQuestion from 'components/GameElements/InputQuestion'
import RadioQuestion from 'components/GameElements/RadioQuestion'
import { QuestionType } from 'proto_files/grammar_games_enums_pb'
import { observer } from "mobx-react-lite"
import styled from 'styled-components'


const QuesArea = styled.div`
  width: 100%;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
`;


const QuesLineMgr = observer(({quesLine, completeHandler}) => {

  const finishQuesHandler = () => {
    quesLine.selectNextQuestion()
    if (quesLine.isComplete) {
      completeHandler()
    }
  }

  // We need an extra handler for the highlight questions because, if the words the 
  // user selected aren't right, then we set the correct highlights.
  const highlightCheckHandler = () => {
    quesLine.updateHighlights()
  }

  const getHighlightQuestion = () => {
    return (
      <HighlightQuestion 
        wordSet={quesLine.wordSet}
        gameQues={quesLine.curQuestion}
        checkHandler={highlightCheckHandler}
  		  finishHandler={finishQuesHandler}
      />
    )
  }

  const getButtonQuestion = () => {
    return (
      <ButtonQuestion
        gameQues={quesLine.curQuestion}
        finishHandler={finishQuesHandler}
      />
    )
  }

  const getRadioQuestion = () => {
    return (
      <RadioQuestion 
        gameQues={quesLine.curQuestion}
        finishHandler={finishQuesHandler}
      />
    )
  }

  const getInputQuestion = () => {
    return (
      <InputQuestion 
        gameQues={quesLine.curQuestion}
        finishHandler={finishQuesHandler}
      />
    )
  }

  const getQuestionComponent = () => {
    if (quesLine.curQuestion !== undefined) {
      switch (quesLine.curQuestion.questionType) {
        case QuestionType.BOOLEAN:
        case QuestionType.MULTICHOICE_BUTTON:
          return getButtonQuestion()
        case QuestionType.MULTICHOICE_RADIO:
          return getRadioQuestion()
        case QuestionType.HIGHLIGHT:
          return getHighlightQuestion()
        case QuestionType.INPUT:
          return getInputQuestion()
        default:
          return null
      }
    }
  }

  return (
  	<QuesArea>
  	  <DisplaySentWords wordSet={quesLine.wordSet} />
  	  {getQuestionComponent()}
  	</QuesArea>
  )
})

export default QuesLineMgr;
