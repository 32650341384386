import { WordSet } from 'components/DataStores/GameStore/WordSet'
import { GameType, GramGamesErrorCode } from "proto_files/grammar_games_enums_pb"
import { makeAutoObservable, action, computed } from "mobx";


const ProgressThreshold = 90

export const GameStatus = {

	PLAYER_INACTIVE: "playerInactive",
	SETTING_GAME_LEVEL: "settingGameLevel",
	READY_TO_LOAD: "readyToLoad",
	LOADING: "loading",
	READY: "ready",
}

export class QuesLineGame {

	player = undefined
	gameType = GameType.UNKNOWN_GAME_TYPE
	status = GameStatus.PLAYER_INACTIVE
	hasCompleted = false
	gameLevel = 1 
	// When we're transitioning between levels, the actual gameLevel will transition first,
	// but we don't to display that until the user confirms the transition.
	displayGameLevel = 1
	// The newGameLevel is updated when the player manually selects a gameLevel. Once they
	// confirm their choice, it becomes the actual gameLevel.
	newGameLevel = 1
	progressPercent = 0
	activeQuesLine = undefined
	nextQuesLine = undefined
	serverErr = false
	loadingNext = false
	showLevelInfo = false
	transitionInProgress = false

	wordSet = undefined
	rootStore = undefined
	commsStore = undefined

	constructor(rootStore, player, gameType) {

		// We always need to have a player
		if (player === undefined) {
			player = rootStore.playersStore.defaultPlayer
		}

		this.player = player
		this.gameType = gameType
		this.gameLevel = player.getGameLevel(gameType)
		this.displayGameLevel = this.gameLevel
		// We want newGameLevel to be in sync with the active gameLevel
		this.newGameLevel = this.gameLevel
		this.rootStore = rootStore
		this.commsStore = rootStore.commsStore
		this.wordSet = new WordSet()


		makeAutoObservable(this, {
			loadingNext: false,
			wordSet: false,
			rootStore: false,
			commsStore: false,
			quesLineReady: computed,
			activateQuesLineGame: action,
			playerRefreshed: action,
			setPlayerGameLevel: action,
			setProgressPercent: action,
			maxGameLevel: computed,
			isComplete: computed,
			setShowLevelInfo: action,
			setNewGameLevel: action,
			confirmNewGameLevel: action,
			confirmGameLevelTransition: action,
			gameLevelSet: action,
			requestQuesLines: action,
			receivedQuesLine: action,
			reportedAnswer: action,
			handleRefresh: action,
			useNextQuesLine: action,
		})

		this.activateQuesLineGame()
	}

	activateQuesLineGame() {

		// The player may already be active if they've been playing another game.
		// Otherwise, we need to establish the GameLevel for this game with the server.
		if (!this.player.isActive) {
			this.status = GameStatus.PLAYER_INACTIVE
			this.player.requestPlayerRefresh(this, false)
		} else {
			this.status = GameStatus.SETTING_GAME_LEVEL
			this.commsStore.setGameLevel(this, false)
		}
	}

	get quesLineReady() {
		return this.status === GameStatus.READY
	}

	playerRefreshed() {
		// This may be a regular refresh or we may have been setting the player from an
		// inactive state. If we are, then set the GameLevel next
		if (this.status === GameStatus.PLAYER_INACTIVE) {
			// If we previously encountered an issue with communicating with the server, 
			// then serverErr might be true, so ensure we set it back to false.
			this.serverErr = false
			this.status = GameStatus.SETTING_GAME_LEVEL
			this.commsStore.setGameLevel(this, false)
		}
	}

	// Called if the pleyer requests a new GameLevel. Clear everything out and request new 
	// QuesLines. Since the player specifically requested the level, we'll restart if the
	// level is the same as the current level.
	setPlayerGameLevel(gameLevel) {

		if (this.gameType !== GameType.UNKNOWN_GAME_TYPE) {
			this.gameLevel = gameLevel
			this.displayGameLevel = gameLevel
			this.setProgressPercent(0)
			this.activeQuesLine = undefined
			this.nextQuesLine = undefined
			this.status = GameStatus.SETTING_GAME_LEVEL

			this.commsStore.setGameLevel(this, true)
		}
	}

	get maxGameLevel() {
		return this.rootStore.numGameLevels(this.gameType)
	}

	get isComplete() {
		return this.hasCompleted
	}

	setProgressPercent(progressPercent) {
		if (progressPercent >= ProgressThreshold) {
			this.progressPercent = 100
		} else {
			this.progressPercent = progressPercent
		}
	}

	// When showLevelInfo is true, we give the player the opportunity to change the level.
	setShowLevelInfo(show) {
		this.showLevelInfo = show
	}

	// This is the value used to select a new gameLevel. It isn't actually 
	// used until the player selects the 'Set New Level' button.
	setNewGameLevel(level) {
		this.newGameLevel = level
	}

	// Called when the player selects the 'Set New Level' button. 
	confirmNewGameLevel() {
		this.setPlayerGameLevel(this.newGameLevel)
		this.setShowLevelInfo(false)
	}

	// Called when the player confirms a GameLevel transition. Note that we specifically do not
	// call setPlayerGameLevel because we've already transitioned the gameLevel in the background.
	// We just need to update when the player actually sees displayed.
	confirmGameLevelTransition() {
		this.transitionInProgress = false
		if (!this.isComplete) {
			this.displayGameLevel = this.gameLevel
			this.setProgressPercent(0)
		}
	}

	// Indication that the GameLevel has been correctly set with the server.
	gameLevelSet(refreshPlayer, errCode) {
		this.handleRefresh(refreshPlayer, errCode)

		if (this.status === GameStatus.SETTING_GAME_LEVEL) {
			this.player.storePlayerConfig()
			this.status = GameStatus.READY_TO_LOAD
			this.requestQuesLines()
		}
	}

	requestQuesLines() {
		// If we're already loading, then we don't need to do so again.
		if (this.status !== GameStatus.LOADING) {
			if (this.activeQuesLine === undefined) {
				this.commsStore.requestQuesLine(this)
				if (this.status === GameStatus.READY_TO_LOAD) {
					this.status = GameStatus.LOADING
				}
			}
		}
	}

	receivedQuesLine(quesLine, progressPercent, refreshPlayer, errCode) {
		this.handleRefresh(refreshPlayer, errCode)

		if (errCode === GramGamesErrorCode.ERR_SERVER_ERR || quesLine === undefined)  {
			// Something has gone wrong on the server side or things are just out of sync.
			// We'll start over with trying to re-establish communication.
			this.serverErr = true
			this.status = GameStatus.PLAYER_INACTIVE
			this.player.requestPlayerRefresh(this, true)
		} else if (errCode === GramGamesErrorCode.ERR_SET_GAME_LEVEL_REQUIRED) {
			// The GameLevel on the server is out of sync with the frontend, so we need to 
			// send a new SetLevel message.
			this.status = GameStatus.SETTING_GAME_LEVEL
			this.commsStore.setGameLevel(this, false)
		} else {
			// If a transition is in progress, we don't want to update the progressPercent that is
			// displayed to the player until they confirm the transition. We know it will go to 0.
			if (!this.transitionInProgress) {
				this.setProgressPercent(progressPercent)
			}
			if (this.activeQuesLine === undefined) {
				quesLine.initWordSet(this.wordSet)
				this.activeQuesLine = quesLine
				this.status = GameStatus.READY
			} else if (this.nextQuesLine === undefined) {
				this.nextQuesLine = quesLine
				this.loadingNext = false
			}
		}
	}

	reportedAnswer(progressPercent, refreshPlayer, errCode) {
		this.handleRefresh(refreshPlayer, errCode)

		if (errCode === GramGamesErrorCode.ERR_SERVER_ERR)  {
			// Something has gone wrong on the server side or things are just out of sync.
			// We'll start over with trying to re-establish communication.
			this.serverErr = true
			this.status = GameStatus.PLAYER_INACTIVE
			this.player.requestPlayerRefresh(this, true)
		} else {
			this.setProgressPercent(progressPercent)
		}
	}

	handleRefresh(refreshPlayer, errCode) {
		if (refreshPlayer) {
			// This is just a regular refresh. The server still recognizes this player, but it
			// letting us know we need to refresh to stay current.
			this.player.requestPlayerRefresh(this, false)
		} else if (errCode === GramGamesErrorCode.ERR_REFRESH_REQUIRED) {
			// The server no longer recognizes this player so the player cannot proceed until a refresh is done.
			this.status = GameStatus.PLAYER_INACTIVE
			this.player.requestPlayerRefresh(this, true)
		}		
	}

	useNextQuesLine() {
		if (this.progressPercent >= ProgressThreshold) {
			// If they hit the threshold level, we've already automatically bumped them to 100 percent.
			// Transition to the next level if/as appropriate or let them just keep playing at the top level.
			if (this.gameLevel < this.rootStore.numGameLevels(this.gameType)) {
				this.transitionInProgress = true
				// We've temporarily bumped the level to 100. We'll move it to 0 once they confirm the transition.
				// We start the transition to the next level here. We set the gameLevel value and request a new
				// QuesLine, but we don't yet update the displayGameLevel until the user confirms the transition.
				this.gameLevel += 1
				// We keep the newGameLevel in sync with the actual gameLevel so that if the player wants
				// to adjust the level, they're starting from the current point.
				this.newGameLevel = this.gameLevel
				this.status = GameStatus.SETTING_GAME_LEVEL
				this.activeQuesLine = undefined
				this.commsStore.setGameLevel(this, false)

			} else if (!this.hasCompleted) {
				this.transitionInProgress = true
				this.hasCompleted = true
				// We don't actually use a COMPLETED status, because we want to allow the playing to continue 
				// playing and thus, we need to keep getting QuesLines.
				this.activeQuesLine = undefined
				this.status = GameStatus.READY_TO_LOAD
				this.requestQuesLines()
			} else {
				// The player has completed all levels, but they are still playing at the top level.
				this.activeQuesLine = undefined
				this.status = GameStatus.READY_TO_LOAD
				this.requestQuesLines()				
			}
		} else {
			if (this.nextQuesLine !== undefined) {
				this.nextQuesLine.initWordSet(this.wordSet)
				this.activeQuesLine = this.nextQuesLine
				this.nextQuesLine = undefined
			} else {
				this.activeQuesLine = undefined
				this.status = GameStatus.READY_TO_LOAD
			}
			this.requestQuesLines()
		}
	}
}
