import React from 'react'
import HelpItemGroup from './HelpItemGroup'
import HelpIndex from './HelpIndex'
import { useParams } from "react-router-dom"

const HelpController = () => {

  let { helpType, itemName } = useParams()

  const getHelp = () => {

    if (itemName === "index") {
      return <HelpIndex helpType={helpType} />
    } else {
      return <HelpItemGroup helpType={helpType} helpTypeRef={itemName} />
    }
  }

  return (
    <div>
      { getHelp() }
    </div>
  )
}

export default HelpController
