import { getUniqueColors } from 'components/GameElements/GameUtils'
import { QuestionType } from 'proto_files/grammar_games_enums_pb'
import { makeAutoObservable, action, computed } from "mobx";


export class GameQuestion {

	questionLine = undefined
	gameQuestionType = 0
	questionType = 0
	questionText = ""
	correctAnswerText = ""
	incorrectAnswerText = ""
	boolAnswer = false

	// Multiple choice answers are objects that specify at least 'label' and 'value'
	multiAnswers = []
	multiAnswerInd = 0
	multiRadioStyle = true
	highlightColor = 0
	highlightWords = []
	// inputWords is an array of arrays where each word array is a [label, wordPos]
	inputWords = []
	inputAnswer = ""
	colorArray = []
	isAnswered = undefined
	isCorrect = undefined

	// These properties are specifically to manage the flow of a Radio question. I'd rather manage them in
	// the RadeoQuestion component, but something goes wrong with picking up the updated values with
	// useState at times and I haven't been able to figure out why, so just handling it here for now instead.
	autoTrans = false
	curSelection = undefined
	firstSelect = true

	constructor(gameQuestionType, questionType, questionText, correctAnswerText, incorrectAnswerText, boolAnswer,
		multiAnswers, multiAnswerInd, highlightWords, inputWords, colorsMap) {

		this.gameQuestionType = gameQuestionType
		this.questionType = questionType
		this.questionText = questionText
		this.correctAnswerText = correctAnswerText
		// For some questions, the correct and incorrect answer text is the same
		this.incorrectAnswerText = incorrectAnswerText === "" ? correctAnswerText : incorrectAnswerText
		this.boolAnswer = boolAnswer
		this.multiAnswers = multiAnswers
		this.multiAnswerInd = multiAnswerInd
		this.highlightWords = highlightWords
		this.inputWords = inputWords
        this.colorsMap = colorsMap

        // If it's a Boolean question, we create the multianswers, so we can treat it like multiple choice.
        if (questionType === QuestionType.BOOLEAN) {
        	this.multiAnswers = []

        	this.multiAnswers.push({
        		label: "Yes",
				value: 1,
        	})
        	this.multiAnswers.push({
        		label: "No",
				value: 2,
        	})

        	// This is an index, but since the index values coming from the server are 1-based, we make this 1-based as well.
        	this.multiAnswerInd = boolAnswer ? 1 : 2        	
        }

		makeAutoObservable(this, {
			colorsMap: false,
			// Since these two fields have an initial value of undefined, I think makeAutoObservable
			// won't pick them up, so we specifically mark them as observable.
			isAnswered: true,
			isCorrect: true,
			curSelection: true,
			correctInputStr: computed,
			initQuestionLine: action,
			initColors: action,
			setAnswer: action,
            resetQuestion: action,
        })
	}

	get answerText() {
		// If we don't have a correct/incorrect indication yet, then we have nothing to return
		if (this.isCorrect === undefined) {
			return undefined
		}

		// Some QuestionTypes only have one possibility for the answer which is stored in correctAnswerText
		if (this.incorrectAnswerText === undefined) {
			return this.correctAnswerText
		}

		// Return whichever answer text is appropriate
		return this.isCorrect ? this.correctAnswerText : this.incorrectAnswerText
	}

	// For now, we only have one word input strings. This routine would also handle multiple word 
	// inputs as long as they were consecutive words.
	get correctInputStr() {
        return this.inputWords.reduce((fullWordsStr, wordArr) => {
        	// The first element of the wordArr is the string/label
        	if (fullWordsStr.length > 0) {
        		return fullWordsStr + " " + wordArr[0]
        	} else {
        		return wordArr[0]
        	}        	
        }, "")
	}

	initQuestionLine(quesLine) {
		this.questionLine = quesLine
	}

	initColors() {

		// We need one color for the control button (e.g., 'Check My Answer', 'Continue', etc.).
		let numColors = 1

		switch (this.questionType) {
		case QuestionType.BOOLEAN:
			numColors += 2
			break

		case QuestionType.MULTICHOICE_BUTTON:
			numColors += this.multiAnswers.length
			break

		default:
			break
		}

        // Now get an array of unique colors. The different question components will use them as needed.
        this.colorArray = getUniqueColors(numColors, this.colorsMap)
	}

	setAnswer(isCorrect) {
		this.isAnswered = true
		this.isCorrect = isCorrect

		if (this.questionLine) {
			this.questionLine.reportAnswer(this.gameQuestionType, isCorrect)
		}

		if (this.questionType === QuestionType.INPUT) {
			const wordPosArr = this.inputWords.reduce((wordPosArr, wordArr) => {
	        	// The second element of the wordArr is the word position
	        	wordPosArr.push(wordArr[1])
	        	return wordPosArr
        	}, [])

			this.questionLine.displayCorrectWords(wordPosArr)
		}
	}

	resetQuestion() {
		this.isAnswered = undefined
		this.isCorrect = undefined
		this.autoTrans = false
	}
}
