import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';


const NavCenter = styled.div`  
  height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const NavBlock = styled.div`  
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const LinkStyle = styled(Link)`
  font-family: 'Roboto', sans-serif;  
  padding: 0px 10px;
`;

const HeaderNav = () => {

  return (
    <NavCenter>
      <NavBlock>
        <LinkStyle to="/">Home</LinkStyle> 
        <LinkStyle to="/games">Games</LinkStyle>
        <LinkStyle to="/help">Help</LinkStyle>
      </NavBlock>
    </NavCenter>
  )
}

export default HeaderNav
