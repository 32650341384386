import React, { useEffect } from 'react'
import { FaRegQuestionCircle } from "react-icons/fa";
import DissectItQuestion from './images/DissectItQuestion.png'
import DissectIt_Step1 from './images/DissectIt_Step1.png'
import DissectIt_Step2 from './images/DissectIt_Step2.png'
import DissectIt_Step3 from './images/DissectIt_Step3.png'
import DissectIt_Step4 from './images/DissectIt_Step4.png'
import DissectIt_Step5 from './images/DissectIt_Step5.png'
import DissectIt_Step6 from './images/DissectIt_Step6.png'
import styled from 'styled-components'

const BuildStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainStyle = styled.div`
  width: 600px;
  font-family: 'Roboto', sans-serif;  
  font-size: 16px;
  font-weight: 400;
  padding: 0px 10px;
  text-align: justify;
  padding: 20px 10px;
`;

const Divider = styled.hr`
  width: 500px;
  border: 1px solid black;
  border-color: '#101c3d';
  background-color: '#101c3d';
  text-align: center;
`;

const CenteredText = styled.p`
  text-align: center;
  font-size: 20px;
`;

const ImageBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageStyle = styled.img`
  width:  500px;
  height: 500px;
`;


const DissectItHelp = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BuildStyle>
      { /* <TitleHeader title="How Do I Dissect It?" /> */ }
      <MainStyle>
        <CenteredText>
          How Do I Dissect A Sentence? 
        </CenteredText>
        <p>
          Your goal in the Dissect It! game is to completely dissect the sentence into all of its
          Grammar Elements and Sentence Parts. Here's how!<br/><br/>
        </p>

        <Divider/>

        <p>
          Sometimes you'll need to answer a question. It will look something like this...
        </p>
        <ImageBox><ImageStyle src={DissectItQuestion} alt="DissectItQuestion" /></ImageBox>
        <p>
          Your goal is to select the right option. If you're not sure what some of the answers are,
          you can use the <FaRegQuestionCircle /> icon to get a description of that Grammar Element 
          or Sentence Part.<br/><br/>

          Use the radio buttons to make your selection and you'll find out if you got it right.
          If not, you'll see what the right answer is.<br/><br/>
        </p>
 
        <Divider/>

        <p>
          <br/>
          Most of the time, you'll be working to dissect a Grammar Element. Here's a simple example
          just as we're starting to dissect this sentence.
        </p>
        <ImageBox><ImageStyle src={DissectIt_Step1} alt="DissectIt_Step1" /></ImageBox>
        <p>
          In the box at the top, you see the current Sentence Part and Grammar Element you're dissecting 
          in bold. The Grammar Element type is in parentheses. In this case, we're dissecting an
          Independent Clause Object and the Grammar Element type is a Simple Clause.<br/><br/>

          Below the sentence, you see the current Sentence Part you need to identify highlighted with 
          a particular color. Your goal is to highlight all the words in the sentence that belong
          to that part. You can click words individually, or click and drag to highlight multiple words
          at once.<br/><br/>

          Once you've highlighted all the words correctly, you'll move on to the next part that hasn't 
          yet been identified. You can also click through the Sentence Parts yourself using the left 
          and right arrows. <br/><br/>

        </p>

        <Divider/>

        <p>
          <br/>
          If you've identified a part correctly, you'll know because you'll see a smiley face! Once you've  
          identified all the parts for a Grammar Element, you'll see that it has a check mark next to it in the 
          Dissection List at the top. Here we've correctly identified the full Independent Clause 
          Object.<br/><br/>

        </p>
        <ImageBox><ImageStyle src={DissectIt_Step2} alt="DissectIt_Step2" /></ImageBox>
        <p>
          <br/>
          If the current part can be dissected further, you'll also see a down arrow below the Sentence Part. 
          Here that's the Subject. Click that to start working on that new part. <br/><br/>
          <br/>          
        </p>

        <Divider/>

        <p>
          <br/>        
          Whenever you get ready to dissect a new Sentence Part, you'll need to identify the Grammar Element 
          type for that part. That's so we know which parts belong to that new Grammar Element.<br/><br/>

          In this case, we'll be dissecting the Subject. You now see only those words highlighted in the 
          sentence. Just like before, you can get help on the different possible choices and you select 
          your answer with the radio buttons. <br/><br/>
        </p>
        <ImageBox><ImageStyle src={DissectIt_Step3} alt="DissectIt_Step3" /></ImageBox>
        <p>
          <br/>
        </p> 

        <Divider/>

        <p>
          <br/>
          Now we're ready to start dissecting the Subject. We identified it as a Noun Phrase, so we now see 
          that in the Dissection List at the top. We're dissecting the Subject Sentence Part and the Grammar
          Element type of the Subject is Noun Phrase.
          Remember, we know it's the current one because it's highlighted in bold.<br/><br/>

          Above that we see that the Subject was part of an Independent Clause Object. That's what we just 
          dissected. And now, we want to identify the Determiner Object of the Noun Phrase.<br/><br/>
        </p> 
        <ImageBox><ImageStyle src={DissectIt_Step4} alt="DissectIt_Step4" /></ImageBox>
        <p>
          <br/>
          Now we use the same process to dissect this Noun Phrase, stepping through each Sentence Part one
          at a time, just as we did before.<br/><br/>

          When we finish dissecting this Grammar Element, we can dig down further to dissect some of the 
          Sentence Parts that are in this Grammar Element or we can move back up to the Independent Clause
          again using the up arrow.<br/><br/>
        </p> 

        <Divider/>

        <p>
          <br/>
          Now we've skipped ahead a bit in dissecting this sentence. We've fully dissected the Subject
          Noun Phrase and all of its contained Sentence Parts.<br/><br/>

        </p>
        <ImageBox><ImageStyle src={DissectIt_Step5} alt="DissectIt_Step5" /></ImageBox>
        <p>
          We can tell this Grammar Element has been fully dissected because of two things. First, all of the words
          of the Grammar Element have been assigned to Sentence Parts. This doesn't include all of the words
          in the sentence, but just the words in this Grammar Element. The rest of the words are still there,
          but they are shown in a lighter font so you know they aren't active right now.<br/><br/>

          Second, and more importantly, in the 
          Dissection List at the top you see two checkmarks next to the current Grammar Element. This means 
          that not only has the Grammar Element itself been dissected, but all of its contained Sentence
          Parts have been as well.<br/><br/>

          Take a look at the Independent Clause in the Dissection List. There, we only see one checkmark.
          That means we've dissected that Grammar Element, but it still has contained Sentence Parts that 
          we haven't yet dissected. That's what we should do next. We can use the up arrow to move back up
          to that Grammar Element and keep working on the rest of the parts for that Grammar Element.<br/><br/>
        </p>

        <Divider/>

        <p>
          <br/>
          We've skipped ahead again and we've now finished dissecting this sentence. Yay, us! We know this
          because we see two checkmarks next to the Sentence at the top of the Dissection List. We also see 
          a message under the smiley face telling us the sentence is complete. <br/><br/>
        </p>
        <ImageBox><ImageStyle src={DissectIt_Step6} alt="DissectIt_Step6" /></ImageBox>
        <p>
          At this point, we can move on to try another sentence using the left and right arrows that are above 
          the Dissection List. We can actually use those at any time, not just after we've finished a sentence. 
          This lets us take a break on one sentence and try another. We won't lose any work since we 
          can come back to it later whenever we want.<br/><br/>
          One last thing to note is that you can start over on a sentence whenever you want using the 
          'Let Me Start Over' option at the bottom. Maybe you just want to practice is again or maybe you have 
          a friend who wants to take a turn!<br/><br/>
          That's all there is to it. We hope you have fun learning!<br/><br/>
        </p>
 
      </MainStyle>
    </BuildStyle>
  )

}

export default DissectItHelp
