import useGramElemTypeHelp from './useGramElemTypeHelp'
import useSentPartTypeHelp from './useSentPartTypeHelp'
import useGramElemCatTypeHelp from './useGramElemCatTypeHelp'
import useGamesTypeHelp from './useGamesTypeHelp'
import { gramElemTypeHelp, sentPartTypeHelp, gramElemCatTypeHelp, gamesTypeHelp } from 'components/Help/Help'

export const undefinedHelpId = 9999

const useHelpItem = (helpType, helpId) => {

  const emptyHelpObject = {
    helpId: undefinedHelpId, 
    gramElemTypeId: helpId,
    title: 'Undefined Help',
    description: "",
    examples: [],
  }

  const gramElemTypeHelpItems = useGramElemTypeHelp()
  const sentPartTypeHelpItems = useSentPartTypeHelp()
  const gramElemCatTypeHelpItems = useGramElemCatTypeHelp()
  const gamesTypeHelpItems = useGamesTypeHelp()

  const getHelpItem = () => {

    let newItemData = emptyHelpObject

    switch (helpType) {
      case gramElemTypeHelp:
        if (gramElemTypeHelpItems.has(helpId)) {
          newItemData = gramElemTypeHelpItems.get(helpId)
        } else {
          newItemData.description = "Help for this Grammar Element type is not yet defined."
        }
        break
      case sentPartTypeHelp:
        if (sentPartTypeHelpItems.has(helpId)) {
          newItemData = sentPartTypeHelpItems.get(helpId)
        } else {
          newItemData.description = "Help for this Sentence Part type is not yet defined."
        }
        break
      case gramElemCatTypeHelp:
        if (gramElemCatTypeHelpItems.has(helpId)) {
          newItemData = gramElemCatTypeHelpItems.get(helpId)
        } else {
          newItemData.description = "Help for this Grammar Element Category type is not yet defined."
        }
        break
      case gamesTypeHelp:
        if (gamesTypeHelpItems.has(helpId)) {
          newItemData = gamesTypeHelpItems.get(helpId)
        } else {
          newItemData.description = "Help for this game type is not yet defined."
        }
        break
      default:
        newItemData.description = "Support for " + helpType + " is not available."
    }

    return newItemData
  }

  return getHelpItem()
}

export default useHelpItem
