import React from 'react'
import QuesLineMgr from 'components/QuesLineGames/QuesLineMgr'
import { GameType } from "proto_files/grammar_games_enums_pb"
import { observer } from "mobx-react-lite"
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useHistory } from "react-router-dom"
import styled from 'styled-components'


const GameArea = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
`;

const SideArea = styled.div`
  width: 85px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MainArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const LevelDisp = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
`;

const LevelStr = styled.button`
  background-color: transparent;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;  
  padding-top: 4px;
  border: none;
  outline: none;  
  cursor: pointer;
`;

const Button = styled.button`
  background-color: ${props => props.backgroundColor};
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  border: 0px;
  border-radius: 100px 100px 100px 100px;
  padding: 5px 20px;
  margin: 10px 4px 20px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);  
  outline: none;
  cursor: pointer;
`;

const LevelInputGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const LevelLabel = styled.label`
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 14px;
  padding: 10px 7px;
  outline: 2px solid black;
`;

const LevelButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2px 0px;
`;

const ArrowButton = styled.button`
  background: transparent;
  border: none;
  color: black;
  padding: 3px 3px;
  margin: 0px 10px;
  font-size: 16px;
  cursor: pointer;
  outline: none;
`;

const Spinner = () => (
  <StyledSpinner viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="4"
    />
  </StyledSpinner>
);

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: 150px 0 0 0px;
  width: 50px;
  height: 50px;
  
  & .path {
    stroke: #91679f;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;


const QuesLineGameMgr = observer(({quesLineGame}) => {

  let history = useHistory()

  const completeHandler = () => {
    quesLineGame.useNextQuesLine()
  }

  const handleLevelClick = event => {   
    quesLineGame.setShowLevelInfo(true)
  }

  const upLevelHandler = event => {
    if (quesLineGame.newGameLevel < quesLineGame.maxGameLevel) {
      quesLineGame.setNewGameLevel(quesLineGame.newGameLevel + 1)
    }
  }

  const downLevelHandler = event => {   
    if (quesLineGame.newGameLevel > 1) {
      quesLineGame.setNewGameLevel(quesLineGame.newGameLevel - 1)
    }
  }

  const handleSetLevelClick = event => {   
    quesLineGame.confirmNewGameLevel()
  }

  const handleDoneClick = event => {   
    quesLineGame.setShowLevelInfo(false)
  }

  const handleViewLevelClick = event => {

    switch (quesLineGame.gameType) {
      case GameType.FIND_THE_PATTERN:
        history.push('/games/ftp-intro')
        break

      default:
        break
    }    
  }

  const handleTransitionClick = event => {   
    quesLineGame.confirmGameLevelTransition()
  }

  const getComponent = () => {

    if (quesLineGame.serverErr || !quesLineGame.quesLineReady) {
      return <div>
        <br/>Getting your next questions. One moment, please...<br/><br/>
        <Spinner/>
      </div>
    } else if (quesLineGame.showLevelInfo) {
      return <div>
        <b>Game Levels</b><br/><br/>

        You are {quesLineGame.progressPercent}% through level {quesLineGame.displayGameLevel}.<br/><br/>
        To set a new level, choose a level and select 'Set New Level'.<br/>
        You can also select the current level to start over.<br/>
        <b>Careful! You'll lose your current progress and you can't undo it!</b><br/><br/>

        <LevelInputGroup>
          <LevelLabel>
            {quesLineGame.newGameLevel}
          </LevelLabel>
          <LevelButtonGroup>
            <ArrowButton onClick={upLevelHandler} ><FaChevronUp /></ArrowButton>
            <ArrowButton onClick={downLevelHandler} ><FaChevronDown /></ArrowButton>
          </LevelButtonGroup>
        </LevelInputGroup>

        <Button
          backgroundColor={'#4fc3f7c0'}
          onClick={handleSetLevelClick}
        >
          Set New Level
        </Button>

        <Button
          backgroundColor={'#00c852c0'}
          onClick={handleDoneClick}
        >
          Back to the Game!
        </Button>

        <br/>

        <Button
          backgroundColor={'#91679fc0'}
          onClick={handleViewLevelClick}
        >
          View Game Level Info
        </Button>

      </div>
    } else if (quesLineGame.transitionInProgress) {
      if (quesLineGame.isComplete) {
        return <div>
          <br/><br/>
          <b>Fantastic! You have completed the last level!</b><br/><br/>
          You can continue to play as much as you like.<br/>
          Continue at this level or move back to another level.<br/><br/>
          <Button
            backgroundColor={'#00c852c0'}
            onClick={handleTransitionClick}
          >
            Keep Playing!
          </Button>
        </div>
      } else {
        return <div>
          <br/><br/>
          <b>Excellent! You are ready for the next level!</b><br/><br/>
          You've done really well with level {quesLineGame.displayGameLevel},<br/>
          so we'll move you to level {quesLineGame.gameLevel} now.<br/><br/>
          <Button
            backgroundColor={'#00c852c0'}
            onClick={handleTransitionClick}
          >
            I'm Ready!
          </Button>
        </div>
      }
    } else {
      return <QuesLineMgr
        quesLine={quesLineGame.activeQuesLine}
        completeHandler={completeHandler}
      />
    }
  }

  const getProgressCircle = () => {
    const levelStr = "Level " + quesLineGame.displayGameLevel
    return <LevelDisp>
      <CircularProgressbar 
          value={quesLineGame.progressPercent}
          strokeWidth={12}
          styles={buildStyles({
            textColor: "black",
            pathColor: "#1876d3",
            trailColor: "#f0f0f0",
            textSize: "24px",
          })}
        />
      <LevelStr
          onClick={handleLevelClick}
      >
        {levelStr}
      </LevelStr>
    </LevelDisp>
  }

  return (
    <GameArea>
      <SideArea/>
      <MainArea>
        {getComponent()}
      </MainArea>
      <SideArea>
        {getProgressCircle()}
      </SideArea>
    </GameArea>
  )
})

export default QuesLineGameMgr;
