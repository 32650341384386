import React from 'react'
import { helpItemName } from './helpData'
import { gameHelpEntries } from './gamesHelpData'

const getIdHelpMap = () => {
	let helpMap = new Map()
	gameHelpEntries.forEach(helpEntry => {
		helpMap.set(helpEntry.gramElemTypeId, helpEntry.helpId)
	})
return helpMap  	
}

const getNameHelpMap = () => {
	let helpMap = new Map()
	gameHelpEntries.forEach(helpEntry => {
		helpMap.set(helpItemName(helpEntry), helpEntry.helpId)
	}) 
	return helpMap
}

const useGamesHelpId = (typeRef) => {

  // A map of only the GrammarElement help items indexed by the GramElemTypeId
  const [gamesTypeIdHelpMap] = React.useState(getIdHelpMap())

  // A map of only the GrammarElement help items indexed by the name
  const [gamesTypeNameHelpMap] = React.useState(getNameHelpMap())

  console.log("gamesNameHelpMap: " + gamesTypeNameHelpMap)

  if (typeRef !== null) {
    if (gamesTypeIdHelpMap.has(typeRef)) {
	  	return typeRef
    }
    if (gamesTypeNameHelpMap.has(typeRef)) {
	  	return gamesTypeNameHelpMap.get(typeRef)
    }
  }

  return 0
}

export default useGamesHelpId
