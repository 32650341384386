import { GameQuestion } from 'components/DataStores/GameStore/GameQuestion'
import { QuestionLine } from 'components/DataStores/GameStore/QuestionLine'
import { gamesTypeHelp } from 'components/Help/Help';
import { getGameHelpId } from 'components/Help/HelpHooks/gamesHelpData'
import { GrammarGamesClient } from 'proto_files/grammar_games_grpc_web_pb'
import { SetPlayerReq, SetGameLevelReq, GetQuesLineReq, ReportAnswerReq } from 'proto_files/grammar_games_pb'
import { GameQuestionType } from 'proto_files/grammar_games_enums_pb'

const DeadlineSecs = 3

export class GrpcComms {

	constructor(rootStore) {
		this.rootStore = rootStore
		//this.client = new GrammarGamesClient("http://localhost:8080", null, null)
		this.client = new GrammarGamesClient("https://grammar-games.leadingstep.com:443", null, null)

		const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {});
		enableDevTools([
		  this.client,
		]);
	}

	refreshPlayer(player, quesLineGame) {

		const setPlayerReq = new SetPlayerReq()
		setPlayerReq.setPlayerId(player.playerID)
		setPlayerReq.setAccessKey(player.accessKey)

		var deadline = new Date();
		deadline.setSeconds(deadline.getSeconds() + DeadlineSecs);

		this.client.setPlayer(setPlayerReq, {deadline: deadline.getTime()}, (err, resp) => {
			if (err) {
	   			console.error(`Unexpected error for refreshPlayer: code = ${err.code}` +
	                `, message = "${err.message}"`);
 				player.refreshFailed(quesLineGame)
			} else {
				if (resp.getPlayerId() === "00000000-0000-0000-0000-000000000000") {
					// We shouldn't see this, but just in case...
		   			console.error('Server Error. PlayerID not assigned for refreshPlayer.');
					player.refreshFailed(quesLineGame)
				} else {
					player.playerRefreshed(resp.getPlayerId(), resp.getAccessKey(), quesLineGame)
				}
			}
		})
	}

	setGameLevel(quesLineGame, restart) {

		const setGameLevelReq = new SetGameLevelReq()
		setGameLevelReq.setPlayerId(quesLineGame.player.playerID)
		setGameLevelReq.setAccessKey(quesLineGame.player.accessKey)
		setGameLevelReq.setGameType(quesLineGame.gameType)
		setGameLevelReq.setGameLevel(quesLineGame.gameLevel)
		setGameLevelReq.setRestart(restart)

		var deadline = new Date();
		deadline.setSeconds(deadline.getSeconds() + DeadlineSecs);

		this.client.setGameLevel(setGameLevelReq, {deadline: deadline.getTime()}, (err, resp) => {
			if (err) {
    			console.error(`Unexpected error for setGameLevel: code = ${err.code}` +
                `, message = "${err.message}"`);
    			quesLineGame.player.refreshFailed(quesLineGame)
  			} else {
  				const refreshPlayer = resp.getRefreshPlayer()
  				const errCode = resp.getErrCode()
  				quesLineGame.gameLevelSet(refreshPlayer, errCode)
  			}
		})
	}

	requestQuesLine(quesLineGame) {

		const getQuesLineReq = new GetQuesLineReq()
		getQuesLineReq.setPlayerId(quesLineGame.player.playerID)
		getQuesLineReq.setAccessKey(quesLineGame.player.accessKey)
		getQuesLineReq.setGameType(quesLineGame.gameType)
		getQuesLineReq.setGameLevel(quesLineGame.gameLevel)

		var deadline = new Date();
		deadline.setSeconds(deadline.getSeconds() + DeadlineSecs);

		this.client.getQuesLine(getQuesLineReq, {deadline: deadline.getTime()}, (err, resp) => {
			if (err) {
    			console.error(`Unexpected error for getQuesLine: code = ${err.code}` +
                `, message = "${err.message}"`);
    			quesLineGame.player.refreshFailed(quesLineGame)
  			} else {
  				const progressPercent = resp.getProgressPercent()
  				const refreshPlayer = resp.getRefreshPlayer()
  				const errCode = resp.getErrCode()
  				let quesLine = undefined
  				if (resp.hasQuestionLine()) {
	  				quesLine = this.toStoreQuesLine(resp.getQuestionLine(), quesLineGame)
 				}
	  			quesLineGame.receivedQuesLine(quesLine, progressPercent, refreshPlayer, errCode)
   			}
		})
	}

	reportAnswer(quesLineGame, quesLine, gameQuesType, isCorrect) {

		const reportAnswerReq = new ReportAnswerReq()
		reportAnswerReq.setPlayerId(quesLineGame.player.playerID)
		reportAnswerReq.setAccessKey(quesLineGame.player.accessKey)
		reportAnswerReq.setGameType(quesLineGame.gameType)
		reportAnswerReq.setGameLevel(quesLineGame.gameLevel)
		reportAnswerReq.setQuestionLineId(quesLine.quesLineID)
		reportAnswerReq.setGameQuesType(gameQuesType)
		reportAnswerReq.setIsCorrect(isCorrect)

		var deadline = new Date();
		deadline.setSeconds(deadline.getSeconds() + DeadlineSecs);

		this.client.reportAnswer(reportAnswerReq, {deadline: deadline.getTime()}, (err, resp) => {
			if (err) {
				console.error(`Unexpected error from reportAnswer: code = ${err.code}` +
					`, message = "${err.message}"`);
    			quesLineGame.player.refreshFailed(quesLineGame)
			} else {
				const progressPercent = resp.getProgressPercent()
				const refreshPlayer = resp.getRefreshPlayer()
				const errCode = resp.getErrCode()
				quesLineGame.reportedAnswer(progressPercent, refreshPlayer, errCode)
			}
		})
	}

	// Supporting routines to convert gRPC messages to store objects

	// Convert gRPC QuestionLine to QuestionLine store instance
	toStoreQuesLine(pbQuesLine, quesLineGame) {
		const quesLineId = pbQuesLine.getQuestionLineId()
		const gameType = pbQuesLine.getGameType()
		const gameLevel = pbQuesLine.getGameLevel()
		const words = this.toStoreWords(pbQuesLine.getSentWordsList()) 
		const questions = this.toStoreGameQuestions(pbQuesLine.getGameQuestionsList(), words)

		return new QuestionLine(quesLineId, gameType, gameLevel, words, questions, this.rootStore.elemColors, quesLineGame, this)
	}

	// Convert gRPC sentWords list to object format needed for the QuestionLine store
	toStoreWords(pbSentWords) {
		return pbSentWords.map((sentWord, ind) => {

			return {
				position:    	ind,
			  	label:  		sentWord.getWord(),
			  	modLabel:  		sentWord.getModWord(),
			  	color:  		this.rootStore.noHighlightColor, 
			  	isActive:  		true,
			  	isDisabled:  	false,
			  	isPunctuation:  sentWord.getIsPunctuation(),
			}
		})
	}

	toStoreGameQuestions(pbGameQuestions, words) {
		return pbGameQuestions.map(gameQues => {
			return this.toStoreGameQuestion(gameQues, words)
		})
	}

	toStoreGameQuestion(pbGameQues, wordsObjs) {
		const gameQuesType = pbGameQues.getGameQuestionType()
		const quesType = pbGameQues.getQuestionType()
		const boolAns = pbGameQues.getBooleanAnswer()
		const multiAnswers = this.toStoreMultiAnswers(pbGameQues.getMultiAnswersList())
		const multiAnsInd = pbGameQues.getMultiAnswerInd()
		const highlightWords = pbGameQues.getHighlightWordsList()

		// For input questions, the answer is specified as the positions of the words that should be input.
		// Then we get the actual value from the label of the word object (the initially displayed value 
		// will be the value that is in modLabel). The GameQuestion needs the actual word values, so we
		// go ahead and pull the answer out here and pass that into the GameQuestion.
		const wordPositions = pbGameQues.getInputWordsList()
		let inputWords = []
		if (wordPositions.length > 0) {
			// Currently all of our input questions are one word, but that may not always be the case.
	        inputWords = wordPositions.reduce((words, wordPos) => {
	        	// Get the label value which is the answer we need.
	        	words.push([wordsObjs[wordPos].label, wordPos])
	        	return words
	        }, [])
		}

		const quesText = this.getQuestionText(gameQuesType)
		const corrAnsText = this.getCorrectAnsText(gameQuesType)
		const incorrAnsText = this.getIncorrectAnsText(gameQuesType)

		return new GameQuestion(gameQuesType, quesType, quesText, corrAnsText, incorrAnsText, boolAns, 
			multiAnswers, multiAnsInd, highlightWords, inputWords, this.rootStore.elemColors)
	}

	toStoreMultiAnswers(pbMultiAnswers) {
		return pbMultiAnswers.map(answer => {
			return {
				label: answer.getLabel(),
				value: answer.getIntValue(),
				helpType: gamesTypeHelp,
				helpId: getGameHelpId(answer.getLabel())
			}
		})
	}

	getQuestionText(gameQuesType) {

		switch (gameQuesType) {

			case GameQuestionType.MTA_HIGHLIGHT_SUBJECT:
				return "Highlight the subject of this clause."

			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_PRONOUN:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_PHRASE:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_COMPOUND_NOUN:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_APPOSITIVE:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_GERUND:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_INFINITIVE:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_GERUND_PHRASE:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_INFINITIVE_PHRASE:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_DEPENDENT_CLAUSE:
				return "What type of subject is this?"

			case GameQuestionType.MTA_IS_SUBJECT_SING_OR_PLURAL_SING:
			case GameQuestionType.MTA_IS_SUBJECT_SING_OR_PLURAL_PLURAL:
				return "Is this subject singular or plural?"

			case GameQuestionType.MTA_IS_SUBJECT_1ST_2ND_3RD_1ST_SING:
			case GameQuestionType.MTA_IS_SUBJECT_1ST_2ND_3RD_2ND_SING:
			case GameQuestionType.MTA_IS_SUBJECT_1ST_2ND_3RD_3RD_SING:
				return "Is this pronoun 1st, 2nd, or 3rd person?"

			case GameQuestionType.MTA_IS_PREDICATE_COMPOUND_YES:
			case GameQuestionType.MTA_IS_PREDICATE_COMPOUND_NO:
				return "Is this a compound predicate?"

			case GameQuestionType.MTA_HIGHLIGHT_VERB_PHRASE:
				return "Highlight the complete verb phrase."

			case GameQuestionType.MTA_HIGHLIGHT_ALL_VERB_PHRASES:
				return "Highlight all of the verb phrases in this clause."

			case GameQuestionType.MTA_IS_VERB_PHRASE_ADJUSTABLE_YES:
			case GameQuestionType.MTA_IS_VERB_PHRASE_ADJUSTABLE_NO:
				return "Does this verb phrase depend on the subject?"

			case GameQuestionType.MTA_IS_ANY_VERB_PHRASE_ADJUSTABLE_1_YES:
			case GameQuestionType.MTA_IS_ANY_VERB_PHRASE_ADJUSTABLE_2_YES:
			case GameQuestionType.MTA_IS_ANY_VERB_PHRASE_ADJUSTABLE_BOTH_YES:
			case GameQuestionType.MTA_IS_ANY_VERB_PHRASE_ADJUSTABLE_NO:
				return "Do any of the verb phrases in this clause depend on the subject?"

			case GameQuestionType.MTA_DO_SUBJECT_VERB_PHRASE_AGREE_YES:
			case GameQuestionType.MTA_DO_SUBJECT_VERB_PHRASE_AGREE_NO:
				return "Do the subject and verb phrase agree?"

			case GameQuestionType.MTA_DO_ALL_SUBJECT_VERB_PHRASES_AGREE_YES:
			case GameQuestionType.MTA_DO_ALL_SUBJECT_VERB_PHRASES_AGREE_1_NO:
			case GameQuestionType.MTA_DO_ALL_SUBJECT_VERB_PHRASES_AGREE_2_NO:
			case GameQuestionType.MTA_DO_ALL_SUBJECT_VERB_PHRASES_AGREE_BOTH_NO:
				return "Do all of the verb phrases in this clause agree with the subject?"

			case GameQuestionType.MTA_HIGHLIGHT_INCORRECT_VERB:
				return "Highlight the incorrect verb in this clause."

			case GameQuestionType.MTA_INPUT_CORRECT_VERB:
				return "Input the correct verb for this clause."

			case GameQuestionType.MTA_HIGHLIGHT_ALL_INCORRECT_VERB:
				return "Highlight all of the incorrect verbs in this clause."

			case GameQuestionType.MTA_INPUT_CORRECT_VERB_COMPOUND_1:
				return "Input the correct verb for the first predicate of this clause."
			case GameQuestionType.MTA_INPUT_CORRECT_VERB_COMPOUND_2:
				return "Input the correct verb for the second predicate of this clause."

			case GameQuestionType.MTA_WHAT_TYPE_CLAUSE_INDEP:
			case GameQuestionType.MTA_WHAT_TYPE_CLAUSE_NOUN:
			case GameQuestionType.MTA_WHAT_TYPE_CLAUSE_ADJ:
			case GameQuestionType.MTA_WHAT_TYPE_CLAUSE_ADV:
				return "What type of clause is highlighted in this sentence?"

			case GameQuestionType.MTA_HIGHLIGHT_DEP_CLAUSE_NOUN:
				return "Highlight the noun dependent clause in this sentence."
			case GameQuestionType.MTA_HIGHLIGHT_DEP_CLAUSE_ADJ:
				return "Highlight the adjective dependent clause in this sentence."
			case GameQuestionType.MTA_HIGHLIGHT_DEP_CLAUSE_ADV:
				return "Highlight the adverb dependent clause in this sentence."

			case GameQuestionType.MTA_HIGHLIGHT_INDEP_CLAUSE:
				return "Highlight the independendent clause in this sentence."
			case GameQuestionType.MTA_HIGHLIGHT_INDEP_CLAUSE_1:
				return "Highlight the first independendent clause in this sentence."
			case GameQuestionType.MTA_HIGHLIGHT_INDEP_CLAUSE_2:
				return "Highlight the second independendent clause in this sentence."

			case GameQuestionType.MTA_HAS_NOUN_DEP_CLAUSE_YES:
			case GameQuestionType.MTA_HAS_NOUN_DEP_CLAUSE_NO:
				return "Does this sentence have a noun dependent clause?"
			case GameQuestionType.MTA_HAS_ADJ_DEP_CLAUSE_YES:
			case GameQuestionType.MTA_HAS_ADJ_DEP_CLAUSE_NO:
				return "Does this sentence have an adjective dependent clause?"
			case GameQuestionType.MTA_HAS_ADV_DEP_CLAUSE_YES:
			case GameQuestionType.MTA_HAS_ADV_DEP_CLAUSE_NO:
				return "Does this sentence have an adverb dependent clause?"

			case GameQuestionType.MTA_IS_COMPOUND_SENTENCE_YES:
			case GameQuestionType.MTA_IS_COMPOUND_SENTENCE_NO:
				return "Is this a compound sentence?"

			case GameQuestionType.MTA_ADJ_DEP_CLAUSE_HAS_SUBJECT_YES:
			case GameQuestionType.MTA_ADJ_DEP_CLAUSE_HAS_SUBJECT_NO:
				return "Does this adjective dependent clause have its own subject?"

			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_INDEP_1:
			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_INDEP_2:
			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_NOUN:
			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_ADJ:
			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_ADV:
				return "Highlight the clause in this sentence that has a subject/verb agreement error."

			case GameQuestionType.MTA_IS_THERE_AGREEMENT_ERR_YES:
			case GameQuestionType.MTA_IS_THERE_AGREEMENT_ERR_NO:
				return "Does this sentence have a subject/verb agreement error?"

			case GameQuestionType.MTA_SYSTEM_HIGHLIGHT_CLAUSE:
			default:
				return ""

			case GameQuestionType.FTP_WHAT_SENT_STRUCTURE_SIMPLE:
			case GameQuestionType.FTP_WHAT_SENT_STRUCTURE_COMPOUND:
			case GameQuestionType.FTP_WHAT_SENT_STRUCTURE_COMPLEX:
			case GameQuestionType.FTP_WHAT_SENT_STRUCTURE_COMPOUND_COMPLEX:
				return "What is the structure of this sentence?"

			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_DECLARATIVE:				
			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_EXCLAMATORY:				
			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_INTERROGATIVE:				
			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_IMPERATIVE:				
			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_IMPERATIVE_EXCLAMATORY:
				return "What is the purpose of this sentence?"

			case GameQuestionType.FTP_HIGHLIGHT_FIRST_INDEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_SECOND_INDEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_DEP_CLAUSE__NOUN:
			case GameQuestionType.FTP_HIGHLIGHT_DEP_CLAUSE__ADJ:
			case GameQuestionType.FTP_HIGHLIGHT_DEP_CLAUSE__ADV:
			case GameQuestionType.FTP_HIGHLIGHT_NOUN_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_ADJ_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_ADV_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_FIRST_NOUN_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_SECOND_NOUN_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_FIRST_ADJ_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_SECOND_ADJ_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_FIRST_ADV_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_SECOND_ADV_DEP_CLAUSE:
				return "Highlight the " + this.getSpecificTypeStr(gameQuesType) + " in this sentence."

			case GameQuestionType.FTP_HIGHLIGHT_SENT_SUBJECT:
				return "Highlight the " + this.getSpecificTypeStr(gameQuesType) + " of this sentence."

			case GameQuestionType.FTP_HIGHLIGHT_CLAUSE_SUBJECT:
				return "Highlight the " + this.getSpecificTypeStr(gameQuesType) + " of this clause."

			case GameQuestionType.FTP_IS_COMPOUND_PRED__YES:
			case GameQuestionType.FTP_IS_COMPOUND_PRED__NO:
				return "Is this predicate a compound predicate?"

			case GameQuestionType.FTP_HIGHLIGHT_SENT_PREDICATE:
				return "Highlight the complete predicate of this sentence."

			case GameQuestionType.FTP_HIGHLIGHT_CLAUSE_PREDICATE:
				return "Highlight the complete predicate of this clause."

			case GameQuestionType.FTP_HIGHLIGHT_COMPOUND_1ST_PREDICATE:
				return "Highlight the first complete predicate of the compound predicate."

			case GameQuestionType.FTP_HIGHLIGHT_COMPOUND_2ND_PREDICATE:
				return "Highlight the second complete predicate of the compound predicate."

			case GameQuestionType.FTP_VERB_ACTION_OR_LINKING__ACTION:
			case GameQuestionType.FTP_VERB_ACTION_OR_LINKING__LINKING:
				return "Is the verb in this predicate an action or linking verb?"

			case GameQuestionType.FTP_VERB_TRANS_OR_INTRANS__INTRANS:
			case GameQuestionType.FTP_VERB_TRANS_OR_INTRANS__TRANS:
				return "Is the verb in this predicate a transitive or intransitive verb?"

			case GameQuestionType.FTP_LINKING_PRED_BE_HAS__ADVERB_ONLY:
			case GameQuestionType.FTP_LINKING_PRED_BE_HAS__PRED_ADJ:
			case GameQuestionType.FTP_LINKING_PRED_NONBE_HAS__PRED_ADJ:
			case GameQuestionType.FTP_LINKING_PRED_BE_HAS__PRED_NOM:
			case GameQuestionType.FTP_LINKING_PRED_NONBE_HAS__PRED_NOM:
			case GameQuestionType.FTP_ACTIVE_PRED_HAS__DO:
			case GameQuestionType.FTP_ACTIVE_PRED_HAS__IO:
			case GameQuestionType.FTP_ACTIVE_PRED_HAS__ADJ_COMP:
			case GameQuestionType.FTP_ACTIVE_PRED_HAS__NOUN_COMP:
			case GameQuestionType.FTP_ANY_PRED_BE_HAS__ADVERB_ONLY:
			case GameQuestionType.FTP_ANY_PRED_BE_HAS__PRED_ADJ:
			case GameQuestionType.FTP_ANY_PRED_NONBE_HAS__PRED_ADJ:
			case GameQuestionType.FTP_ANY_PRED_BE_HAS__PRED_NOM:
			case GameQuestionType.FTP_ANY_PRED_NONBE_HAS__PRED_NOM:
			case GameQuestionType.FTP_ANY_PRED_HAS__INTRANS:
			case GameQuestionType.FTP_ANY_PRED_HAS__DO:
			case GameQuestionType.FTP_ANY_PRED_HAS__IO:
			case GameQuestionType.FTP_ANY_PRED_HAS__ADJ_COMP:
			case GameQuestionType.FTP_ANY_PRED_HAS__NOUN_COMP:
				return "What is the pattern of this predicate?"

			case GameQuestionType.FTP_SINGLE_NOUN_PRED_HAS__PRED_NOM:
			case GameQuestionType.FTP_SINGLE_NOUN_PRED_HAS__DO:
				return "Which of these elements does this predicate have?"				

			case GameQuestionType.FTP_HIGHLIGHT_VERB_PHRASE:
			case GameQuestionType.FTP_HIGHLIGHT_PRED_ADJ:
			case GameQuestionType.FTP_HIGHLIGHT_PRED_NOM:
			case GameQuestionType.FTP_HIGHLIGHT_INDIR_OBJ:
			case GameQuestionType.FTP_HIGHLIGHT_DIR_OBJ_ONLY:
			case GameQuestionType.FTP_HIGHLIGHT_DIR_OBJ:
			case GameQuestionType.FTP_HIGHLIGHT_ADJ_COMPLEMENT:
			case GameQuestionType.FTP_HIGHLIGHT_NOUN_COMPLEMENT:
				return "Highlight the " + this.getSpecificTypeStr(gameQuesType) + " in this predicate."


		}
	}

	getSpecificTypeStr(gameQuesType) {
		switch (gameQuesType) {
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_PRONOUN:
				return "noun"

			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_PHRASE:
				return "noun phrase"
							
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_COMPOUND_NOUN:
				return "compound noun"
							
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_APPOSITIVE:
				return "noun appositive"
							
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_GERUND:
				return "gerund"
							
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_INFINITIVE:
				return "noun infinitive"
							
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_GERUND_PHRASE:
				return "gerund phrase"
							
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_INFINITIVE_PHRASE:
				return "noun infinitive phrase"
							
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_DEPENDENT_CLAUSE:
				return "noun dependent clause"

			case GameQuestionType.MTA_WHAT_TYPE_CLAUSE_INDEP:
				return "independent"

			case GameQuestionType.MTA_WHAT_TYPE_CLAUSE_ADJ:
				return "adjective"

			case GameQuestionType.MTA_WHAT_TYPE_CLAUSE_ADV:
				return "adverb"

			case GameQuestionType.MTA_WHAT_TYPE_CLAUSE_NOUN:
				return "noun"

			case GameQuestionType.MTA_HAS_NOUN_DEP_CLAUSE_YES:
			case GameQuestionType.MTA_HAS_NOUN_DEP_CLAUSE_NO:
				return "noun dependent"

			case GameQuestionType.MTA_HAS_ADJ_DEP_CLAUSE_YES:
			case GameQuestionType.MTA_HAS_ADJ_DEP_CLAUSE_NO:
				return "adjective dependent"

			case GameQuestionType.MTA_HAS_ADV_DEP_CLAUSE_YES:
			case GameQuestionType.MTA_HAS_ADV_DEP_CLAUSE_NO:
				return "adverb dependent"


			case GameQuestionType.FTP_WHAT_SENT_STRUCTURE_SIMPLE:
				return "simple"

			case GameQuestionType.FTP_WHAT_SENT_STRUCTURE_COMPOUND:
				return "compound"

			case GameQuestionType.FTP_WHAT_SENT_STRUCTURE_COMPLEX:
				return "complex"

			case GameQuestionType.FTP_WHAT_SENT_STRUCTURE_COMPOUND_COMPLEX:
				return "compound/complex"

			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_DECLARATIVE:
				return "declarative"

			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_EXCLAMATORY:
				return "exclamatory"

			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_INTERROGATIVE:
				return "interrogative"

			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_IMPERATIVE:
				return "imperative"

			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_IMPERATIVE_EXCLAMATORY:
				return "imperative and exclamatory"

			case GameQuestionType.FTP_HIGHLIGHT_FIRST_INDEP_CLAUSE:
				return "first independendent clause"

			case GameQuestionType.FTP_HIGHLIGHT_SECOND_INDEP_CLAUSE:
				return "second independendent clause"

			case GameQuestionType.FTP_HIGHLIGHT_DEP_CLAUSE__NOUN:
			case GameQuestionType.FTP_HIGHLIGHT_DEP_CLAUSE__ADJ:
			case GameQuestionType.FTP_HIGHLIGHT_DEP_CLAUSE__ADV:
				return "dependent clause"

			case GameQuestionType.FTP_HIGHLIGHT_NOUN_DEP_CLAUSE:
				return "noun dependent clause"

			case GameQuestionType.FTP_HIGHLIGHT_ADJ_DEP_CLAUSE:
				return "adjective dependent clause"

			case GameQuestionType.FTP_HIGHLIGHT_ADV_DEP_CLAUSE:
				return "adverb dependent clause"

			case GameQuestionType.FTP_HIGHLIGHT_FIRST_NOUN_DEP_CLAUSE:
				return "first noun dependent clause"
			case GameQuestionType.FTP_HIGHLIGHT_SECOND_NOUN_DEP_CLAUSE:
				return "second noun dependent clause"
			case GameQuestionType.FTP_HIGHLIGHT_FIRST_ADJ_DEP_CLAUSE:
				return "first adjective dependent clause"
			case GameQuestionType.FTP_HIGHLIGHT_SECOND_ADJ_DEP_CLAUSE:
				return "second adjective dependent clause"
			case GameQuestionType.FTP_HIGHLIGHT_FIRST_ADV_DEP_CLAUSE:
				return "first adverb dependent clause"
			case GameQuestionType.FTP_HIGHLIGHT_SECOND_ADV_DEP_CLAUSE:
				return "second adverb dependent clause"

			case GameQuestionType.FTP_HIGHLIGHT_SENT_SUBJECT:
			case GameQuestionType.FTP_HIGHLIGHT_CLAUSE_SUBJECT:
				return "complete subject"

			case GameQuestionType.FTP_HIGHLIGHT_SENT_PREDICATE:
			case GameQuestionType.FTP_HIGHLIGHT_CLAUSE_PREDICATE:
				return "complete predicate"

			case GameQuestionType.FTP_HIGHLIGHT_COMPOUND_1ST_PREDICATE:
				return "first predicate"

			case GameQuestionType.FTP_HIGHLIGHT_COMPOUND_2ND_PREDICATE:
				return "second predicate"

			case GameQuestionType.FTP_HIGHLIGHT_VERB_PHRASE:
				return "complete verb phrase"

			case GameQuestionType.FTP_VERB_ACTION_OR_LINKING__ACTION:
				return "action verb"

			case GameQuestionType.FTP_VERB_ACTION_OR_LINKING__LINKING:
				return "linking verb"

			case GameQuestionType.FTP_VERB_TRANS_OR_INTRANS__INTRANS:
				return "intransitive verb"

			case GameQuestionType.FTP_VERB_TRANS_OR_INTRANS__TRANS:
				return "transitive verb"

			case GameQuestionType.FTP_LINKING_PRED_BE_HAS__PRED_ADJ:
			case GameQuestionType.FTP_LINKING_PRED_NONBE_HAS__PRED_ADJ:
			case GameQuestionType.FTP_ANY_PRED_BE_HAS__PRED_ADJ:
			case GameQuestionType.FTP_ANY_PRED_NONBE_HAS__PRED_ADJ:
				return "predicate adjective"

			case GameQuestionType.FTP_HIGHLIGHT_PRED_ADJ:
				return "complete predicate adjective"

			case GameQuestionType.FTP_LINKING_PRED_BE_HAS__PRED_NOM:
			case GameQuestionType.FTP_LINKING_PRED_NONBE_HAS__PRED_NOM:
			case GameQuestionType.FTP_SINGLE_NOUN_PRED_HAS__PRED_NOM:
			case GameQuestionType.FTP_ANY_PRED_BE_HAS__PRED_NOM:
			case GameQuestionType.FTP_ANY_PRED_NONBE_HAS__PRED_NOM:
				return "predicate nominative"				

			case GameQuestionType.FTP_HIGHLIGHT_PRED_NOM:
				return "complete predicate nominative"				

			case GameQuestionType.FTP_HIGHLIGHT_INDIR_OBJ:
				return "complete indirect object"

			case GameQuestionType.FTP_SINGLE_NOUN_PRED_HAS__DO:
			case GameQuestionType.FTP_ACTIVE_PRED_HAS__DO:
			case GameQuestionType.FTP_ANY_PRED_HAS__DO:
				return "direct object"

			case GameQuestionType.FTP_HIGHLIGHT_DIR_OBJ_ONLY:
			case GameQuestionType.FTP_HIGHLIGHT_DIR_OBJ:
				return "complete direct object"

			case GameQuestionType.FTP_ACTIVE_PRED_HAS__IO:
			case GameQuestionType.FTP_ANY_PRED_HAS__IO:
				return "indirect object and direct object"

			case GameQuestionType.FTP_HIGHLIGHT_ADJ_COMPLEMENT:
				return "complete adjective object complement"

			case GameQuestionType.FTP_ACTIVE_PRED_HAS__ADJ_COMP:
			case GameQuestionType.FTP_ANY_PRED_HAS__ADJ_COMP:
				return "direct object and adjective object complement"

			case GameQuestionType.FTP_HIGHLIGHT_NOUN_COMPLEMENT:
				return "complete noun object complement"

			case GameQuestionType.FTP_ACTIVE_PRED_HAS__NOUN_COMP:
			case GameQuestionType.FTP_ANY_PRED_HAS__NOUN_COMP:
				return "direct object and noun object complement"

			case GameQuestionType.FTP_ANY_PRED_HAS__INTRANS:
				return "intransitive verb"

			default:
				return ""	
		}
	}

	getCorrectAnsText(gameQuesType) {
		switch (gameQuesType) {

			case GameQuestionType.MTA_HIGHLIGHT_SUBJECT:
				return "You highlighted the subject correctly!"

			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_PRONOUN:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_PHRASE:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_COMPOUND_NOUN:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_APPOSITIVE:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_GERUND:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_INFINITIVE:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_GERUND_PHRASE:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_INFINITIVE_PHRASE:
			case GameQuestionType.MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_DEPENDENT_CLAUSE:
				return "This subject is a " + this.getSpecificTypeStr(gameQuesType) + "."

			case GameQuestionType.MTA_IS_SUBJECT_SING_OR_PLURAL_SING:
				return "This subject is singular."
			case GameQuestionType.MTA_IS_SUBJECT_SING_OR_PLURAL_PLURAL:
				return "This subject is plural"

			case GameQuestionType.MTA_IS_SUBJECT_1ST_2ND_3RD_1ST_SING:
				return "This pronoun is 1st person."
			case GameQuestionType.MTA_IS_SUBJECT_1ST_2ND_3RD_2ND_SING:
				return "This pronoun is 2nd person."
			case GameQuestionType.MTA_IS_SUBJECT_1ST_2ND_3RD_3RD_SING:
				return "This pronoun is 3rd person."

			case GameQuestionType.MTA_IS_PREDICATE_COMPOUND_YES:
				return "This is a compound predicate"
			case GameQuestionType.MTA_IS_PREDICATE_COMPOUND_NO:
				return "This is not a compound predicate."

			case GameQuestionType.MTA_HIGHLIGHT_VERB_PHRASE:
				return "You highlighted the complete verb phrase correctly."

			case GameQuestionType.MTA_HIGHLIGHT_ALL_VERB_PHRASES:
				return "You highlighted all of the verb phrases correctly."

			case GameQuestionType.MTA_IS_VERB_PHRASE_ADJUSTABLE_YES:
				return "This verb phrase does depend on the subject."
			case GameQuestionType.MTA_IS_VERB_PHRASE_ADJUSTABLE_NO:
				return "This verb phrase does not depend on the subject."

			case GameQuestionType.MTA_IS_ANY_VERB_PHRASE_ADJUSTABLE_1_YES:
			case GameQuestionType.MTA_IS_ANY_VERB_PHRASE_ADJUSTABLE_2_YES:
			case GameQuestionType.MTA_IS_ANY_VERB_PHRASE_ADJUSTABLE_BOTH_YES:
				return "At least one verb phrase in this clause depends on the subject."
			case GameQuestionType.MTA_IS_ANY_VERB_PHRASE_ADJUSTABLE_NO:
				return "No verb phrases in this clause depend on the subject."

			case GameQuestionType.MTA_DO_SUBJECT_VERB_PHRASE_AGREE_YES:
				return "The subject and verb phrase do agree."
			case GameQuestionType.MTA_DO_SUBJECT_VERB_PHRASE_AGREE_NO:
				return "The subject and verb phrase do not agree."

			case GameQuestionType.MTA_DO_ALL_SUBJECT_VERB_PHRASES_AGREE_YES:
				return "All of the verb phrases in this clause agree with the subject."
			case GameQuestionType.MTA_DO_ALL_SUBJECT_VERB_PHRASES_AGREE_1_NO:
			case GameQuestionType.MTA_DO_ALL_SUBJECT_VERB_PHRASES_AGREE_2_NO:
			case GameQuestionType.MTA_DO_ALL_SUBJECT_VERB_PHRASES_AGREE_BOTH_NO:
				return "At least one verb phrase in this clause does not agree with the subject."

			case GameQuestionType.MTA_HIGHLIGHT_INCORRECT_VERB:
				return "You highlighted the incorrect verb correctly."

			case GameQuestionType.MTA_INPUT_CORRECT_VERB:
				return "That is the correct verb for this clause."

			case GameQuestionType.MTA_HIGHLIGHT_ALL_INCORRECT_VERB:
				return "You highlighted all of the incorrect verbs in this clause."

			case GameQuestionType.MTA_INPUT_CORRECT_VERB_COMPOUND_1:
			case GameQuestionType.MTA_INPUT_CORRECT_VERB_COMPOUND_2:
				return "That is the correct verb for this predicate."

			case GameQuestionType.MTA_WHAT_TYPE_CLAUSE_INDEP:
			case GameQuestionType.MTA_WHAT_TYPE_CLAUSE_ADJ:
			case GameQuestionType.MTA_WHAT_TYPE_CLAUSE_ADV:
				return "This is an " + this.getSpecificTypeStr(gameQuesType) + " clause."
			case GameQuestionType.MTA_WHAT_TYPE_CLAUSE_NOUN:
				return "This is a " + this.getSpecificTypeStr(gameQuesType) + " clause."

			case GameQuestionType.MTA_HIGHLIGHT_DEP_CLAUSE_NOUN:
			case GameQuestionType.MTA_HIGHLIGHT_DEP_CLAUSE_ADJ:
			case GameQuestionType.MTA_HIGHLIGHT_DEP_CLAUSE_ADV:
			case GameQuestionType.MTA_HIGHLIGHT_INDEP_CLAUSE:
			case GameQuestionType.MTA_HIGHLIGHT_INDEP_CLAUSE_1:
			case GameQuestionType.MTA_HIGHLIGHT_INDEP_CLAUSE_2:
				return "You highlighted the clause correctly."

			case GameQuestionType.MTA_HAS_NOUN_DEP_CLAUSE_YES:
				return "This sentence does have a " + this.getSpecificTypeStr(gameQuesType) + " clause."
			case GameQuestionType.MTA_HAS_NOUN_DEP_CLAUSE_NO:
				return "This sentence does not have a " + this.getSpecificTypeStr(gameQuesType) + " clause."

			case GameQuestionType.MTA_HAS_ADJ_DEP_CLAUSE_YES:
			case GameQuestionType.MTA_HAS_ADV_DEP_CLAUSE_YES:
				return "This sentence does have an " + this.getSpecificTypeStr(gameQuesType) + " clause."
			case GameQuestionType.MTA_HAS_ADJ_DEP_CLAUSE_NO:
			case GameQuestionType.MTA_HAS_ADV_DEP_CLAUSE_NO:
				return "This sentence does not have an " + this.getSpecificTypeStr(gameQuesType) + " clause."

			case GameQuestionType.MTA_IS_COMPOUND_SENTENCE_YES:
				return "This is a compound sentence."
			case GameQuestionType.MTA_IS_COMPOUND_SENTENCE_NO:
				return "This is not a compound sentence."

			case GameQuestionType.MTA_ADJ_DEP_CLAUSE_HAS_SUBJECT_YES:
				return "This adjective dependent clause does have its own subject."
			case GameQuestionType.MTA_ADJ_DEP_CLAUSE_HAS_SUBJECT_NO:
				return "This adjective dependent clause does not have its own subject."

			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_INDEP_1:
			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_INDEP_2:
			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_NOUN:
			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_ADJ:
			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_ADV:
				return "You highlighted the clause that has a subject/verb agreement error correctly."

			case GameQuestionType.MTA_IS_THERE_AGREEMENT_ERR_YES:
				return "This sentence does have a subject/verb agreement error."
			case GameQuestionType.MTA_IS_THERE_AGREEMENT_ERR_NO:
				return "This sentence does not have a subject/verb agreement error."

			case GameQuestionType.MTA_SYSTEM_HIGHLIGHT_CLAUSE:
			default:
				return ""

			// Answers that begin with a consonant
			case GameQuestionType.FTP_WHAT_SENT_STRUCTURE_SIMPLE:
			case GameQuestionType.FTP_WHAT_SENT_STRUCTURE_COMPOUND:
			case GameQuestionType.FTP_WHAT_SENT_STRUCTURE_COMPLEX:
			case GameQuestionType.FTP_WHAT_SENT_STRUCTURE_COMPOUND_COMPLEX:
			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_DECLARATIVE:
				return "This is a " + this.getSpecificTypeStr(gameQuesType) + " sentence."

			// Answers that begin with a vowel
			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_EXCLAMATORY:
			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_INTERROGATIVE:
			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_IMPERATIVE:
			case GameQuestionType.FTP_WHAT_SENT_PURPOSE_IMPERATIVE_EXCLAMATORY:
				return "This is an " + this.getSpecificTypeStr(gameQuesType) + " sentence."

			case GameQuestionType.FTP_HIGHLIGHT_FIRST_INDEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_SECOND_INDEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_DEP_CLAUSE__NOUN:
			case GameQuestionType.FTP_HIGHLIGHT_DEP_CLAUSE__ADJ:
			case GameQuestionType.FTP_HIGHLIGHT_DEP_CLAUSE__ADV:
			case GameQuestionType.FTP_HIGHLIGHT_NOUN_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_ADJ_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_ADV_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_FIRST_ADJ_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_SECOND_ADJ_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_FIRST_ADV_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_SECOND_ADV_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_SENT_SUBJECT:
			case GameQuestionType.FTP_HIGHLIGHT_CLAUSE_SUBJECT:
			case GameQuestionType.FTP_HIGHLIGHT_SENT_PREDICATE:
			case GameQuestionType.FTP_HIGHLIGHT_CLAUSE_PREDICATE:
			case GameQuestionType.FTP_HIGHLIGHT_COMPOUND_1ST_PREDICATE:
			case GameQuestionType.FTP_HIGHLIGHT_COMPOUND_2ND_PREDICATE:
			case GameQuestionType.FTP_HIGHLIGHT_VERB_PHRASE:
			case GameQuestionType.FTP_HIGHLIGHT_PRED_ADJ:
			case GameQuestionType.FTP_HIGHLIGHT_PRED_NOM:
			case GameQuestionType.FTP_HIGHLIGHT_INDIR_OBJ:
			case GameQuestionType.FTP_HIGHLIGHT_DIR_OBJ_ONLY:
			case GameQuestionType.FTP_HIGHLIGHT_DIR_OBJ:
			case GameQuestionType.FTP_HIGHLIGHT_ADJ_COMPLEMENT:
			case GameQuestionType.FTP_HIGHLIGHT_NOUN_COMPLEMENT:
				return "You highlighted the " + this.getSpecificTypeStr(gameQuesType) + " correctly."

			case GameQuestionType.FTP_VERB_ACTION_OR_LINKING__ACTION:
			case GameQuestionType.FTP_VERB_TRANS_OR_INTRANS__INTRANS:
				return "The verb is an " + this.getSpecificTypeStr(gameQuesType) + "."

			case GameQuestionType.FTP_VERB_TRANS_OR_INTRANS__TRANS:
			case GameQuestionType.FTP_VERB_ACTION_OR_LINKING__LINKING:
				return "The verb is a " + this.getSpecificTypeStr(gameQuesType) + "."

			case GameQuestionType.FTP_IS_COMPOUND_PRED__YES:
				return "This is a compound predicate."
			case GameQuestionType.FTP_IS_COMPOUND_PRED__NO:
				return "This is not a compound predicate."

			case GameQuestionType.FTP_LINKING_PRED_BE_HAS__PRED_ADJ:
			case GameQuestionType.FTP_LINKING_PRED_NONBE_HAS__PRED_ADJ:
			case GameQuestionType.FTP_LINKING_PRED_BE_HAS__PRED_NOM:
			case GameQuestionType.FTP_LINKING_PRED_NONBE_HAS__PRED_NOM:
			case GameQuestionType.FTP_SINGLE_NOUN_PRED_HAS__PRED_NOM:
			case GameQuestionType.FTP_SINGLE_NOUN_PRED_HAS__DO:
			case GameQuestionType.FTP_ACTIVE_PRED_HAS__DO:
			case GameQuestionType.FTP_ACTIVE_PRED_HAS__ADJ_COMP:
			case GameQuestionType.FTP_ACTIVE_PRED_HAS__NOUN_COMP:
			case GameQuestionType.FTP_ANY_PRED_BE_HAS__PRED_ADJ:
			case GameQuestionType.FTP_ANY_PRED_NONBE_HAS__PRED_ADJ:
			case GameQuestionType.FTP_ANY_PRED_BE_HAS__PRED_NOM:
			case GameQuestionType.FTP_ANY_PRED_NONBE_HAS__PRED_NOM:
			case GameQuestionType.FTP_ANY_PRED_HAS__DO:
			case GameQuestionType.FTP_ANY_PRED_HAS__ADJ_COMP:
			case GameQuestionType.FTP_ANY_PRED_HAS__NOUN_COMP:
				return "This predicate has a " + this.getSpecificTypeStr(gameQuesType) + "."

			case GameQuestionType.FTP_ACTIVE_PRED_HAS__IO:
			case GameQuestionType.FTP_ANY_PRED_HAS__INTRANS:
			case GameQuestionType.FTP_ANY_PRED_HAS__IO:
				return "This predicate has an " + this.getSpecificTypeStr(gameQuesType) + "."

			case GameQuestionType.FTP_LINKING_PRED_BE_HAS__ADVERB_ONLY:
			case GameQuestionType.FTP_ANY_PRED_BE_HAS__ADVERB_ONLY:
				return "This predicate has only an adverb."
		}
	}

	// For many of the question types, the incorrect answer text is the same as if they answer correctly.
	// We only need to specify it here if it is different.
	getIncorrectAnsText(gameQuesType) {
		switch (gameQuesType) {

			case GameQuestionType.MTA_HIGHLIGHT_SUBJECT:
				return "The correct subject is highlighted above."

			case GameQuestionType.MTA_HIGHLIGHT_VERB_PHRASE:
				return "The correct complete verb phrase is highlighted above."

			case GameQuestionType.MTA_HIGHLIGHT_ALL_VERB_PHRASES:
				return "The correct verb phrases are highlighted above."

			case GameQuestionType.MTA_HIGHLIGHT_INCORRECT_VERB:
				return "The incorrect verb is highlighted above."

			case GameQuestionType.MTA_INPUT_CORRECT_VERB:
				return "The correct verb for this clause is shown above."

			case GameQuestionType.MTA_HIGHLIGHT_ALL_INCORRECT_VERB:
				return "All incorrect verbs are highlighted above."

			case GameQuestionType.MTA_INPUT_CORRECT_VERB_COMPOUND_1:
			case GameQuestionType.MTA_INPUT_CORRECT_VERB_COMPOUND_2:
				return "The correct verb for this predicate is shown above."

			case GameQuestionType.MTA_HIGHLIGHT_DEP_CLAUSE_NOUN:
			case GameQuestionType.MTA_HIGHLIGHT_DEP_CLAUSE_ADJ:
			case GameQuestionType.MTA_HIGHLIGHT_DEP_CLAUSE_ADV:
			case GameQuestionType.MTA_HIGHLIGHT_INDEP_CLAUSE:
			case GameQuestionType.MTA_HIGHLIGHT_INDEP_CLAUSE_1:
			case GameQuestionType.MTA_HIGHLIGHT_INDEP_CLAUSE_2:
				return "The correct clause is highlighted above."

			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_INDEP_1:
			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_INDEP_2:
			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_NOUN:
			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_ADJ:
			case GameQuestionType.MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_ADV:
				return "The clause with a subject/verb agreement is highlighted above."

			case GameQuestionType.FTP_HIGHLIGHT_FIRST_INDEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_SECOND_INDEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_DEP_CLAUSE__NOUN:
			case GameQuestionType.FTP_HIGHLIGHT_DEP_CLAUSE__ADJ:
			case GameQuestionType.FTP_HIGHLIGHT_DEP_CLAUSE__ADV:
			case GameQuestionType.FTP_HIGHLIGHT_NOUN_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_ADJ_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_ADV_DEP_CLAUSE:
			case GameQuestionType.FTP_HIGHLIGHT_SENT_SUBJECT:
			case GameQuestionType.FTP_HIGHLIGHT_CLAUSE_SUBJECT:
			case GameQuestionType.FTP_HIGHLIGHT_SENT_PREDICATE:
			case GameQuestionType.FTP_HIGHLIGHT_CLAUSE_PREDICATE:
			case GameQuestionType.FTP_HIGHLIGHT_COMPOUND_1ST_PREDICATE:
			case GameQuestionType.FTP_HIGHLIGHT_COMPOUND_2ND_PREDICATE:
			case GameQuestionType.FTP_HIGHLIGHT_VERB_PHRASE:
			case GameQuestionType.FTP_HIGHLIGHT_PRED_ADJ:
			case GameQuestionType.FTP_HIGHLIGHT_PRED_NOM:
			case GameQuestionType.FTP_HIGHLIGHT_INDIR_OBJ:
			case GameQuestionType.FTP_HIGHLIGHT_DIR_OBJ_ONLY:
			case GameQuestionType.FTP_HIGHLIGHT_DIR_OBJ:
			case GameQuestionType.FTP_HIGHLIGHT_ADJ_COMPLEMENT:
			case GameQuestionType.FTP_HIGHLIGHT_NOUN_COMPLEMENT:
				return "The correct " + this.getSpecificTypeStr(gameQuesType) + " is highlighted above."

			default:
				return ""
		}		
	}

}