import { getUniqueColors } from 'components/GameElements/GameUtils'
import { QuestionType } from 'proto_files/grammar_games_enums_pb'
import { makeAutoObservable, action, computed } from "mobx";

export class QuestionLine {

	quesLineID = 0
	gameType = 0
	gameLevel = 0
	wordObjects = []
	gameQuestions = []
	curQuesNum = 0
	quesLineGame = undefined
	wordSet = undefined
	commsStore = undefined


	constructor(quesLineID, gameType, gameLevel, wordObjects, gameQuestions, colorsMap, quesLineGame, commsStore) {

        this.quesLineID = quesLineID
        this.gameType = gameType
        this.gameLevel = gameLevel
        this.wordObjects = wordObjects
        this.gameQuestions = gameQuestions
        this.colorsMap = colorsMap
        this.quesLineGame = quesLineGame
        this.commsStore = commsStore

        gameQuestions.forEach(ques => {
        	ques.initQuestionLine(this)
        })

        this.setQuestionColors()

		makeAutoObservable(this, {
			wordSet: true,
			colorsMap: false,
			quesLineGame: false,
			commsStore: false,
			initWordSet: action,
			clearWordSet: action,
            selectNextQuestion: action,
            resetQuestionLine: action,
            curQuestion: computed,
            isComplete: computed,
        })
	}

	// When a QuestionLine becomes active, the caller can provide the wordSet that will be used to manage
	// the display of the QuestionLine. If it is set, the QuestionLine will manage the colors for the
	// words directly - i.e., currently this pertains to all highlight questions.
	initWordSet(wordSet) {
		if (wordSet !== undefined) {
			this.wordSet = wordSet
			this.wordSet.initWordSet(this.wordObjects)
			this.resetQuestionLine()
		}
	}

	// The caller should also clear the wordSet when the QuestionLine is no longer active and the same
	// wordSet is assigned to a different QuestionLine. No conflicts will occur unless resetQuestionLine()
	// is called, but it is still best practice.
	clearWordSet() {
		this.wordSet = undefined
	}

	displayCorrectWords(wordPosArr) {
		this.wordSet.displayCorrectWords(wordPosArr)
	}

	// We may have multiple highlight questions in a question line and all of the them need to have
	// distinct colors, so we have to set them here at the question line level.
	setHighlightQuestionColors() {

		// First find the highlight questions.
        const highlightQuestions = this.gameQuestions.reduce((questions, ques) => {
        	if (ques.questionType === QuestionType.HIGHLIGHT ||
        		ques.questionType === QuestionType.HIGHLIGHT_SYSTEM) {
        		questions.push(ques)
        	}
        	return questions
        }, [])

        //let numColors = highlightQuestions.length < colorsMap.length ? highlightQuestions.length : colorsMap.length
        const numColors = Math.min(highlightQuestions.length, this.colorsMap.size)

        // Now get a unique set of colors for those questions
        const uniqueColors = getUniqueColors(numColors, this.colorsMap)

        // In some of the more complex sentences, we can have more highlight questions than we have unique colors.
        // In our highlighting code we can run into a problem if the same word is meant to be highlighted with the
        // same color for two different questions. Given that, we have to ensure that we don't assign a color to a
        // question if the answer words have already been assigned that color by another question. 
		let assignedColors = new Map()
		let colorInd = 0

        // Finally, set the highlight color for each highlight question
        highlightQuestions.forEach((ques, ind) => {
        	let curColor = uniqueColors[colorInd%numColors]
        	let colorOk = false
        	let numTries = 0

        	// We put a guard on this, limiting it to a max number of attempts. We should never hit
        	// that max, but just in case.
        	while (!colorOk && numTries < uniqueColors.length) {
        		numTries++
	        	if (assignedColors.get(curColor)) {
	        		// We've already assigned this color before, so we have to make sure we don't have a conflict.
	        		// First create a map with all the existing highlight words that use this color.
	        		let highlightedWords = new Map()
	        		for (let i=0; i < ind; i++) {
	        			if (highlightQuestions[i].highlightColor === curColor) {
	        				highlightQuestions[i].highlightWords.forEach(wordPos => {
	        					highlightedWords.set(wordPos, true)
	        				})
	        			}
	        		}

	        		// Now, get the highlight words for the current question and see if there are any conflicts.
	        		let conflictFound = false
	        		conflictFound = ques.highlightWords.reduce((conflictFound, wordPos) => {
	        			return conflictFound || (highlightedWords.get(wordPos) !== undefined)
	        		}, false)

	        		if (conflictFound) {
	        			// Can't use this color. Try the next.
	        			colorInd++
	        			curColor = uniqueColors[colorInd%numColors]
	        		} else {
	        			colorOk = true
	        		}
	        	} else {
	        		colorOk = true
	        	}
        	}

        	ques.highlightColor = curColor
        	assignedColors.set(curColor, true)
        	colorInd++
        })
	}

	// The various question types need different numbers of colors, but they all need at least one.
	// Set the colors for each individual question. Note: We originally wanted to just handle the colors
	// in the various question type components, but it seems like there are issues with setting and accessing
	// both local and MobX state in components and I don't understand how to deal with that correctly, so
	// I'm just defining the colors here, at least until we can figure out the details.
	setIndividualQuestionColors() {
		this.gameQuestions.forEach((ques) => {
			ques.initColors()
		})
	}

	setQuestionColors() {
		this.setHighlightQuestionColors()
		this.setIndividualQuestionColors()
	}

	// We manage the background colors for highlight questions if wordSet is defined.
	initCurQuestion() {

		this.wordSet.clearUnderlines()

		if (this.wordSet !== undefined && !this.isComplete) {
			const curQues = this.curQuestion
			curQues.resetQuestion()

		    if (curQues.questionType === QuestionType.HIGHLIGHT) {
		      // For a regular highlight question, we initialize the correct color for each highlighted word
		      this.wordSet.setWordCorrectColor(curQues.highlightColor, curQues.highlightWords)
		      this.wordSet.checkpoint2AllWords()
		      this.wordSet.setAllDisabled(false)
		      this.wordSet.curHighlightColor = curQues.highlightColor
		      this.wordSet.anyWordHighlighted = false
		    }

		    if (curQues.questionType === QuestionType.HIGHLIGHT_SYSTEM) {
		      // If it's a system highlight question, we set the current color directly and
		      // immediately move on to the next question.
		      this.wordSet.setWordCurrentColor(curQues.highlightColor, curQues.highlightWords)
		      this.selectNextQuestion()
		    }
		}
	}

	updateHighlights() {
		if (this.wordSet !== undefined) {
			const curQues = this.curQuestion

			if (curQues.questionType === QuestionType.HIGHLIGHT) {
			    if (!curQues.isCorrect) {
				    // First we set all words back to the checkpoint2 to clear any words they
				    // highlighted incorrectly and then we set all of the correct words.
			        this.wordSet.restoreCheckpoint2AllWords()
			        this.wordSet.clearUnderlines()
			        this.wordSet.setWordCurrentColor(curQues.highlightColor, curQues.highlightWords, true)
			    }
			    this.wordSet.setAllDisabled(true)
			}
		}
	}

	get isComplete() {
		return this.curQuesNum === this.gameQuestions.length
	}

	get curQuestion() {
		if (this.isComplete) {
			return undefined
		} else {
			return this.gameQuestions[this.curQuesNum]
		}
	}

	reportAnswer(gameQuesType, isCorrect) {
		// If we've already determined that there was an error communicating with the server, then we don't 
		// want to report any more answers until we've reestablished communication.
		if (!this.quesLineGame.serverErr) {
			this.commsStore.reportAnswer(this.quesLineGame, this, gameQuesType, isCorrect)
		}
	}

	selectNextQuestion() {
		if (this.curQuesNum < this.gameQuestions.length) {
			this.curQuesNum++
			this.initCurQuestion()
		}
	}

	// The caller can start the question line over again at any point.
	resetQuestionLine() {
		this.curQuesNum = 0
		this.initCurQuestion()
	}
}
