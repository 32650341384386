export const helpName = (helpTitle) => {
	let name = helpTitle.toLowerCase().replace(/ /g, "-")
	const parenPos = name.indexOf("(")
	if (parenPos > 0) {
		name = name.slice(0, parenPos-1)
	}

	return name
}

// Convert the title to a name that can be used in a url
export const helpItemName = (helpItem) =>  {
	return helpName(helpItem.title) 
}


export const helpCategories = new Map(
	[ 
	  [1,  {id: 1,  label: 'Grammar Elements'}],
	  [2,  {id: 2,  label: 'Sentence Parts'}],
	  [3,  {id: 3,  label: 'Grammar Element Categories'}],
	  [4,  {id: 4,  label: 'General'}],
	]
)

export const helpEntries = [

	  /* GrammarElement Help Items */

	  {
	  		helpId: 1, 
	  		helpCategory: 1, 
	  		grammarCategory: 1, 
	  		gramElemTypeId: 2,
	  		title: 'Noun',
	  		description: `A noun names a person, place, thing, or idea. This does not include 
	  					  pronouns. A noun may be singular or plural, common or proper, 
	  					  concrete or abstract.`,
	  		examples: ['<b>Singular nouns:</b> lemon, street, blanket<br>',
	  				   '<b>Plural nouns:</b> bags, peaches, babies, women<br>',
	  				   '<b>Common nouns:</b> box, street, teacher<br>',
	  				   '<b>Proper nouns:</b> Mr. Williams, Veronica, January, Europe<br>',
	  				   '<b>Concrete nouns:</b> tree, car, dog<br>',
	  				   '<b>Abstract nouns:</b> pride, thought, anger<br>',
	  				  ],
	  		relatedHelpIds: [200],
	  },

	  {
	  		helpId: 2, 
	  		helpCategory: 1, 
	  		grammarCategory: 1, 
	  		gramElemTypeId: 3,
	  		title: 'Pronoun',
	  		description: `We identify nine different types of pronouns in our system.  
	  					  <ol>
	  					    <li>Subjective Pronouns</li>
	  					    <li>Objective Pronouns</li>
	  					    <li>Possessive Pronouns</li>
	  					    <li>Reflexive Pronouns</li>
	  					    <li>Intensive Pronouns</li>
	  					    <li>Demonstrative Pronouns</li>
	  					    <li>Indefinite Pronouns</li>
	  					    <li>Relative Pronouns</li>
	  					    <li>Interrogative Pronouns</li>
	  					  </ol>
	  					  The first seven function as Noun Objects.They replace one or more nouns, along with any 
	  					  determiners and adjective objects that modify those nouns.<br><br>
	  					  Relative Pronouns introduce either Noun or Adjective Dependent Clauses. Those clauses
	  					  can also be called 'relative clauses.'<br><br>
	  					  Interrogative Pronouns are used when asking a question. These same pronouns can
	  					  also function as Relative Pronouns, but they are called Interrogative Pronouns
	  					  when they are used to ask a question.
	  					  `,
	  		examples: ['<b>Subjective pronouns: </b>he, she, they, I, we, you, it<br>',
	  				   '<b>Objective pronouns: </b>him, her, them, me, us, you, it<br>',
	  				   '<b>Possessive pronouns: </b>his, her, their, my, our, your, its<br>',
	  				   '<b>Reflexive pronouns: </b>himself, herself, themselves, myself, ourselves, yourself, yourselves, itself<br>',
	  				   '<b>Intensive pronouns: </b>himself, herself, themselves, myself, ourselves, yourself, yourselves, itself<br>',
	  				   '<b>Demonstrative pronouns: </b>this, that, these, those<br>',
	  				   '<b>Indefinite pronouns: </b>anyone, everybody, few, many, none, some, etc.<br><br>',
	  				   '<b>Relative pronouns: </b>who, which, that, whose, whom<br>',
	  				   '<b>Interrogative pronouns: </b>what, which, who, whom, whose<br>',
	  				  ],
	  		relatedHelpIds: [200, 27, 28, 153],
	  },

	  {
	  		helpId: 3, 
	  		helpCategory: 1, 
	  		grammarCategory: 2, 
	  		gramElemTypeId: 4,
	  		title: 'Auxillary Verb',
	  		description: `Auxillary verbs are used with either a form of the verb 'be' or another main verb.
	  					  They add extra meaning to
	  					  express tense, mood, or voice.<br><br>
	  					  In the example sentences, the auxillary verbs are highlighted in bold 
	  					  and the 'be' verb forms or main verbs are underlined.`,
	  		examples: ['We <b>will</b> <u>go</u> to the movie this weekend.<br>',
	  				   'They <b>might</b> <u>come</u> to the park with us.<br>',
	  				   'She <b>could have</b> <u>been</u> a lawyer, but chose to be a teacher instead.<br>',
	  				  ],
	  		relatedHelpIds: [4, 167, 168],
	  },

	  {
	  		helpId: 4, 
	  		helpCategory: 1, 
	  		grammarCategory: 3, 
	  		gramElemTypeId: 5,
	  		title: 'Verb',
	  		description: `A verb expresses action or a state of being or condition. Those that
	  					  express a state of being or condition are linking verbs and
	  					  those that express action are action verbs. Most verbs are one word,
	  					  but phrasal verbs have more than one word.
	  					 `,
	  		examples: ['<b>Action verbs:</b><br>',
	  				   'They <b>sat</b> on the bench and <b>talked</b> for a long time.<br>',
	  				   'She couldn\'t <b>decide</b> what to do.<br>',
	  				   'Let\'s all <b>dance</b> to this song.<br><br>',
	  				   '<b>Linking verbs:</b><br>',
	  				   'He <b>became</b> the leader of the team.<br>',
	  				   'Tanya <b>felt</b> hot sitting outside in the sun.<br>',
	  				   'They <b>seemed</b> really excited to be at the party.<br><br>',
	  				   '<b>Phrasal verbs:</b><br>',
	  				   'They <b>called off</b> the game because of the rain.<br>',
	  				   'I <b>looked up</b> the address for the party.<br>',
	  				   'You will never <b>get away with</b> that.<br>',
	  				  ],
	  		relatedHelpIds: [3, 166],
	  },

	  {
	  		helpId: 5, 
	  		helpCategory: 1, 
	  		grammarCategory: 8, 
	  		gramElemTypeId: 6,
	  		title: 'Adverb',
	  		description: `Adverbs are used to modify verbs, adjectives, or other adverbs.  Most 
	  					  adverbs modify verbs, but some are used to place emphasis on adjectives 
	  					  or other adverbs.<br><br>
	  					  The adverbs in the example sentences are highlighted in bold.`,
	  		examples: ['The car drove <b>quickly</b> past us. (\'quickly\' modifies the verb \'drove\')',
	  				   'The kids cheered <b>wildly</b> when their team won. (\'wildly\' modifies the verb \'cheered\')',
	  				   'She walked <b>very slowly</b>. (\'slowly\' modifies the verb \'walked\' and \'very\' modifies the adverb \'slowly\')',
	  				   'This is a <b>rather</b> embarrassing story. (\'rather\' modifies the adjective \'embarrassing\')',
	  				  ],
	  		relatedHelpIds: [203],
	  },

	  {
	  		helpId: 6, 
	  		helpCategory: 1, 
	  		grammarCategory: 6, 
	  		gramElemTypeId: 7,
	  		title: 'Adjective',
	  		description: `Adjectives are words that modify nouns.  Some systems of grammar will 
	  		 			  refer to determiners as adjectives as well, but we distinguish between 
	  		 			  determiners and descriptive adjectives.  Determiners always come before the 
	  		 			  descriptive adjectives.
	  		 			  <br><br>
	  		 			  In these example sentences, the adjectives are highlighted 
	  		 			  in bold and the determiners are underlined.`,
	  		examples: ['<u>the</u> <b>colorful</b> hat<br>',
	  				   '<u>a</u> <b>beautiful</b> rainbow<br>',
	  				   '<u>three</u> <b>soft</b> blankets<br>',
	  				  ],
	  		relatedHelpIds: [201],
	  },

	  {
	  		helpId: 7, 
	  		helpCategory: 1, 
	  		grammarCategory: 10, 
	  		gramElemTypeId: 8,
	  		title: 'Preposition',
	  		description: `Prepositions link Noun Objects to other words 
	  					  in a sentence.  They often show a relationship of time or space. Some common 
	  					  prepositions are:  about, above, across, before, behind, below, by, down, 
	  					  during, except, for, from, in, into, near, of, off, on, out, over, past, 
	  					  regarding, through, to, toward, under, until, up, with, within, and without.
	  					  <br><br>
	  					  Some prepositions are more than one word.  Some examples of those are:  
	  					  according to, ahead of, along with, as for, because of, due to, except for, 
	  					  in front of, in spite of, instead of, next to, and with regard to.
						  <br><br>
	  					  A Preposition is followed by a Noun Object and forms a Prepositional Phrase.  
	  					  In the examples below, the Prepositions are highlighted in bold and the objects 
	  					  of the Prepositions are underlined.`,
	  		examples: ['We slid <b>on</b> <u>the ice</u> and almost fell.<br>',
	  				   'We saw lots <b>of</b> <u>small animals</u> <b>near</b> <u>the river</u>.<br>',
	  				   'Let\'s put the food <b>for</b> <u>the party</u> <b>on</b> <u>the table</u>. <br>',
	  				  ],
	  		relatedHelpIds: [25, 26],
	  },

	  {
	  		helpId: 8, 
	  		helpCategory: 1, 
	  		grammarCategory: 9, 
	  		gramElemTypeId: 9,
	  		title: 'Conjunction',
	  		description: `Conjunctions are used to join words, phrases, or clauses in a sentence.
	  					  There are three types of conjunctions:  Coordinating, Correlative, and 
	  					  Subordinating. Both Coordinating Conjunctions and Correlative Conjunctions are used 
	  					  to join equal elements, while Subordinating Conjunctions are used to 
	  					  connect either Noun or Adverb Dependent Clauses to Independent Clauses.<br><br>
	  					  In the example sentences, the Conjunctions are highlighted in bold and the 
	  					  joined elements are underlined.`,
	  		examples: ['The <u>boys</u> <b>and</b> <u>girls</u> went out to the playground. (The Coordinating Conjunction \'and\' connects the Nouns \'boys\' and \'girls\')<br><br>',
	  				   'The kids were <b>not only</b> <u>cold</u> <b>but also</b> <u>hungry</u>. (The Correlative Conjunctions \'not only\' and \'but also\' connect the Adjectives \'cold\' and \'hungry\')<br><br>',
	  				   'We should eat <b>before</b> <u>the food gets cold</u>. (The Subordinating Conjunction \'before\' connects the Dependent Clause \'the food gets cold\' to the Independent Clause)<br><br>',
	  				  ],
	  		relatedHelpIds: [19, 20, 21, 22, 23, 24, 27, 29],
	  },

	  {
	  		helpId: 9, 
	  		helpCategory: 1, 
	  		grammarCategory: 11, 
	  		gramElemTypeId: 10,
	  		title: 'Interjection',
	  		description: `Interjections express emotion.  If the emotion is mild, it is set off by 
	  					  a comma, and if it is stronger, it is set off by an exclamation. 
	  					  Interjections may have one or more words.<br><br>
	  					  In the example sentences below, the interjections are highlighted in bold.`,
	  		examples: ['darn, hey, excellent, oh, oh my, oops, ouch, rats, terrific, ugh, wow, yikes, and yuck',
	  				   '<b>Hey</b>, is this your shoe?',
	  				   '<b>Darn</b>, I\'m going to be late again.',
	  				   '<b>Wow</b>! I can\'t believe we won the game!',
	  				   '<b>Yuck</b>! This tastes terrible!',
	  				  ],
	  		relatedHelpIds: [51, 52, 53, 54],
	  },

	  {
	  		helpId: 10, 
	  		helpCategory: 1, 
	  		grammarCategory: 12, 
	  		gramElemTypeId: 11,
	  		title: 'Determiner',
	  		description: `Determiners refer to a noun that follows them and give us 
	  		 			  more information about them.  For example, they may help us understand the  
	  		 			  particular nouns to which we are referring or understand the quantity of the  
	  		 			  noun. They always come before any adjectives that also describe the noun.
	  		 			  <br><br>
	  		 			  `,		
	  		examples: ['<u><b>the</b></u> tall boy (Which tall boy?)<br>',
	  				   '<u><b>his</b></u> blue shirt (Which or whose blue shirt?)<br>',
	  				   '<u><b>that</b></u> beautiful tree (Which beautiful tree?)<br>',
	  				   '<u><b>some</b></u> money (How much money?)<br>',
	  				   '<u><b>three</b></u> ripe peaches (How many ripe peaches?)<br>',
	  				  ],
	  		relatedHelpIds: [],
	  },

	  // Skipping for now. Not referenced by Be Verb Phrase or Aux Verb Phrase
	  {
	  		helpId: 11, 
	  		helpCategory: 1, 
	  		grammarCategory: 4, 
	  		gramElemTypeId: 12,
	  		title: 'Verb Phrase',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 12, 
	  		helpCategory: 1, 
	  		grammarCategory: 12, 
	  		gramElemTypeId: 13,
	  		title: 'Possessive Determiner',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 13, 
	  		helpCategory: 1, 
	  		grammarCategory: 12, 
	  		gramElemTypeId: 14,
	  		title: 'Complex Determiner',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 14, 
	  		helpCategory: 1, 
	  		grammarCategory: 1, 
	  		gramElemTypeId: 16,
	  		title: 'Noun Phrase',
	  		description: `A noun phrase is a Noun Object that includes a noun or a pronoun along with any 
	  					  modifiers that give more information about it. The noun or pronoun is required,
	  					  but all modifiers are optional. However, in order to be a noun phrase,
	  					  at least one modifier must be included. Otherwise, it's just a simple
	  					  noun or pronoun.<br><br>
	  					  A Noun Phrase has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Beginning Participial Phrase (Optional)</li>
	  					  <li>Determiner Object (Optional)</li>
	  					  <li>PreAdjective Object (Optional)</li>
	  					  <li>Noun Object (Required)</li>
	  					  <li>PostAdjective Object (Optional)</li>
	  					  <li>Ending Participial Phrase (Optional)</li>
	  					  </ol>
	  					  Only the Noun Object is required, but at least one other optional Sentence Part must
	  					  also be specified.<br><br>
	  					  In the examples below, the Noun Phrases are underlined and the Noun Object is 
	  					  highlighted in bold.`,
	  		examples: ['<u>The tall <b>clown</b> with the funny hat</u> made everyone laugh.<br>',
	  				   'He was <u>the quiet <b>one</b> who said nothing at all</u>.<br>',
	  				   '<u>The <b>bride</b></u> looked beautiful <u>walking down the aisle</u>.<br>',
	  				  ],
	  		relatedHelpIds: [161, 138, 147, 144, 145],
	  },

	  {
	  		helpId: 15, 
	  		helpCategory: 1, 
	  		grammarCategory: 1, 
	  		gramElemTypeId: 17,
	  		title: 'Noun Appositive',
	  		description: `An Appositive is a Noun or Noun Phrase that renames another Noun Object that
	  					  is either right before or right after it. We refer to both the 
	  					  Noun Object and the Appositive as a Noun Appositive Object or Noun Appositive
	  					  for short. It is one of the 10 types of Noun Objects in our system.<br><br>
	  					  An appositive that provides additional information is set off from the
	  					  rest of the sentence by commas, but an appositive that is required
	  					  in order to give complete meaning uses no commas.<br><br>
	  					  A Noun Appositive has just two Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Noun Object (Required)</li>
	  					  <li>Noun Appositive (Required)</li>
	  					  </ol>
	  					  Note that even though the Noun Appositive can sometimes come before the Noun Object,
	  					  our system currently only supports it coming after.<br><br>
	  					  In the examples below, the entire Noun Appositive is underlined and
	  					  the appositive is highlighted in bold.`,
	  		examples: ['<u>My friend <b>Julia</b></u> will be coming with us today.<br>',
	  				   '<u>That tall tree, <b>the one with the broken branches,</b></u> almost fell during the storm.<br>',
	  				   '<u>Mr. Thompson, <b>a well-known man of character,</b></u> was admired by all who knew him.<br>',
	  				  ],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 16, 
	  		helpCategory: 1, 
	  		grammarCategory: 8, 
	  		gramElemTypeId: 19,
	  		title: 'Modified Adverb',
	  		description: `A Modified Adverb has a main Adverb Object that is modified by another Adverb Object,
	  					  possibly two. The modifying Adverb Objects can come either before or after the main 
	  					  Adverb Object.<br><br>
	  					  Often, when one Adverb modifies another Adverb, both of them are simple Adverbs as 
	  					  in, "very quickly" or "almost daily." However, that doesn't always have to be the 
	  					  case as some of the examples below demonstrate.<br><br>
	  					  Care must be taken to distinguish between a Modified Adverb and a Compound Adverb.
	  					  The Adverb Objects in a Compound Adverb can stand on their own if you remove any of the 
	  					  other objects, but a modifying Adverb Object makes no sense if you remove the main
	  					  Adverb Object.<br><br>
	  					  A Modified Adverb has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Beginning Adverb Object (Optional)</li>
	  					  <li>Adverb Object (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  While both the Beginning and Ending Adverb Objects are optional, at least one of them
	  					  must be specified.<br><br>
	  					  In the examples below, the main Adverb Objects are highlighted in bold and
	  					  the modifying Adverb Objects are underlined.`,
	  		examples: ['He finished the job <u>quite</u> <b>easily</b>.<br>',
	  				   'Let\'s look up the information <u>really</u> <b>quickly</b> before they come.<br>',
	  				   'She called me <u>just</u> <b>after they arrived</b>.<br>',
	  				   'She laughed <u>so</u> <b>hard</b> <u>that she almost fell on the floor</u>.<br>',
	  				  ],
	  		relatedHelpIds: [130, 125, 126, 203],
	  },

	  {
	  		helpId: 17, 
	  		helpCategory: 1, 
	  		grammarCategory: 8, 
	  		gramElemTypeId: 20,
	  		title: 'Adverb Noun Phrase',
	  		description: `An Adverb Noun Phrase is very similar in structure to a Noun Phrase, but it functions 
	  					  as an Adverb Object. They are usually very simple phrases as in "this morning."<br><br>
	  					  An Adverb Noun Phrase has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Determiner Object (Optional)</li>
	  					  <li>PreAdjective Object (Optional)</li>
	  					  <li>Noun Object (Required)</li>
	  					  <li>PostAdjective Object (Optional)</li>
	  					  </ol>
	  					  The only required part is the Noun Object, but at least one of the optional Sentence
	  					  Parts must also be specified.<br><br>
	  					  In the examples below, the Adverb Noun Phrases are highlighted in bold.`,
	  		examples: ['I\'m sure we saw them <b>that very night</b>.<br>',
	  				   'The birds migrate <b>every year</b> around this time.<br>',
	  				   'We will be gone <b>most of the day</b>.<br>',
	  				  ],
	  		relatedHelpIds: [161, 138, 120, 147, 203],
	  },

	  {
	  		helpId: 18, 
	  		helpCategory: 1, 
	  		grammarCategory: 6, 
	  		gramElemTypeId: 22,
	  		title: 'Modified Adjective',
	  		description: `A Modified Adjective is a PreAdjective Object that is modified by an Adverb Object.
	  					  A modifying Adverb Object can come before or after the PreAdjective Object.<br><br>
	  					  A Modified Adjective has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Beginning Adverb Object (Optional)</li>
	  					  <li>PreAdjective Object (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  The only required part is the PreAdjective Object, but at least one of the Adverb 
	  					  Object Sentence Parts must also be specified.<br><br>
	  					  In the examples below, the Modified Adjectives are underlined and the PreAdjective
	  					  Objects are highlighted in bold.`,
	  		examples: ['This exercise is <u>quite <b>challenging</b></u>.<br>',
	  				   'We received <u>mostly <b>positive and encouraging</b></u> news.<br>',
	  				   'It is <u>very <b>difficult</b> to give a cat a bath</u>!<br>',
	  				   'I\'m <u><b>tired</b> of hearing you complain about this</u>.<br>',
	  				  ],
	  		relatedHelpIds: [138, 125, 126, 201],
	  },

	  // We might want to make a general compound help item that describes why we have them select
	  // the coordinating conjunction first
	  {
	  		helpId: 19, 
	  		helpCategory: 1, 
	  		grammarCategory: 1, 
	  		gramElemTypeId: 25,
	  		title: 'Compound Noun Object',
	  		description: `A Compound Noun Object is a Noun Object made up of two or more Noun Objects.
	  					  A simple example is 'boys and girls'.<br><br>
	  					  The type of Noun Objects don't always need to be the same, but some combinations
	  					  aren't valid. For example, you should not combine a Gerund or Gerund Phrase with 
	  					  a Noun Infinitive or Noun Infinitive Phrase. Some other combinations just wouldn't make 
	  					  sense such as combining a Noun Dependent Clause with any other type.<br><br>
	  					  In certain cases, it can make sense to use one Compound Noun Object within another.
	  					  'We have a variety of sandwiches made with tuna, chicken, or peanut butter 
	  					  and jelly.' In this case, 'peanut butter and jelly' is a Compound Noun Object and that
	  					  is one of the Noun Objects in the larger Compound Noun Object 'tuna, chicken, or peanut 
	  					  butter and jelly.'<br><br>
	  					  Also note that we specifically call this type a Compound Noun Object rather than simply
	  					  a Compound Noun as we do for the other compound types. This is to distinguish it from 
	  					  a Compound Noun which refers to a Noun that is made up of two or more words such as 
	  					  'peanut butter.' 
	  					  A Compound Noun Object has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Coordinating Conjunction (Required)</li>
	  					  <li>Noun Object 1 (Required)</li>
	  					  <li>Noun Object 2 (Required)</li>
	  					  <li>Noun Object 3 (Optional)</li>
	  					  <li>Noun Object 4 (Optional)</li>
	  					  <li>Correlative Conjunction (Optional)</li>
	  					  </ol>
	  					  See the general Compound Grammar Element help item for an explanation on how we handle
	  					  the Conjunction Sentence Parts.<br><br>
	  					  In the examples below, the Compound Noun Objects are underlined and the types of Noun 
	  					  Objects are noted in parentheses.
	  					 `,
	  		examples: ['<u>He and Aliyah</u> are going to a movie tonight. (A Pronoun and Noun)<br>',
	  				   '<u>Jogging and playing soccer</u> are two ways I like to exercise. (A Gerund and a Gerund Phrase)<br>',
	  				   'Please bring <u>a water bottle, a jacket, and sunglasses </u> with you. (Two Noun Phrases and a Noun)<br>'
	  				  ],
	  		relatedHelpIds: [62, 8, 120, 200],
	  },

	  {
	  		helpId: 20, 
	  		helpCategory: 1, 
	  		grammarCategory: 8, 
	  		gramElemTypeId: 26,
	  		title: 'Compound Adverb',
	  		description: `A Compound Adverb is an Adverb Object made up of two or more Adverb Objects. There are no
	  					  limitations on the types of Adverb Object Grammar Elements that can be combined in a compound other 
	  					  than what makes logical sense for the sentence.<br><br>
	  					  In certain cases, it can make sense to use one Compound Adverb within another.
	  					  'The balloon rose higher and higher until it was out of sight.' In this case, 
	  					  'higher and higher' is a Compound Adverb and that
	  					  is the first of the two Adverb Objects in the larger compound 'higher and higher
	  					  until it was out of sight.'<br><br>
	  					  A Compound Adverb has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Coordinating Conjunction (Optional)</li>
	  					  <li>Adverb Object 1 (Required)</li>
	  					  <li>Adverb Object 2 (Required)</li>
	  					  <li>Adverb Object 3 (Optional)</li>
	  					  <li>Adverb Object 4 (Optional)</li>
	  					  <li>Correlative Conjunction (Optional)</li>
	  					  </ol>
	  					  See the general Compound Grammar Element help item for an explanation on how we handle
	  					  the Conjunction Sentence Parts. A conjunction is not required, and is often not used, within a 
	  					  Compound Adverb.<br><br>
	  					  In the examples below, the Compound Adverbs are underlined. Where we don't use a conjunction
	  					  or comma to separate the parts, we highlight the individual Adverb Objects, alternating in bold 
	  					  and italics to distinguish them.
	  					 `,
	  		examples: ['We went <u><b>inside</b> <i>to take a closer look</i></u>.<br>',
	  				   'They floated <u><b>for several miles</b> <i>down the river</i> <b>on the raft</b></u>.<br>',
	  				   'I need to leave <u><b>early</b> <i>for school</i> <b>tomorrow</b></u>.<br>',
	  				  ],
	  		relatedHelpIds: [62, 8, 130, 203],
	  },

	  {
	  		helpId: 21, 
	  		helpCategory: 1, 
	  		grammarCategory: 6, 
	  		gramElemTypeId: 27,
	  		title: 'Compound PreAdjective',
	  		description: `A Compound PreAdjective is a PreAdjective Object made up of two or more PreAdjective Objects.<br><br>
	  					  A Compound PreAdjective has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Coordinating Conjunction (Optional)</li>
	  					  <li>PreAdjective Object 1 (Required)</li>
	  					  <li>PreAdjective Object 2 (Required)</li>
	  					  <li>PreAdjective Object 3 (Optional)</li>
	  					  <li>PreAdjective Object 4 (Optional)</li>
	  					  <li>Correlative Conjunction (Optional)</li>
	  					  </ol>
	  					  See the general Compound Grammar Element help item for an explanation on how we handle
	  					  the Conjunction Sentence Parts. A conjunction is not required within a 
	  					  Compound PreAdjective. At times, the conjunction 'and' can be replaced by a comma.<br><br>
	  					  In the examples below, the Compound PreAdjectives are underlined.
	  					 `,
	  		examples: ['I\'m feeling <u>tired and sore</u> after my long run yesterday.<br>',
	  				   'The <u>angry, roaring</u> crowd made their voices heard.<br>',
	  				   'Your comments are <u>neither helpful nor nice</u>.<br>',
	  				  ],
	  		relatedHelpIds: [62, 8, 138, 201],
	  },

	  {
	  		helpId: 22, 
	  		helpCategory: 1, 
	  		grammarCategory: 7, 
	  		gramElemTypeId: 28,
	  		title: 'Compound PostAdjective',
	  		description: `A Compound PostAdjective is a PostAdjective Object made up of two or more PostAdjective Objects.<br><br>
	  					  A Compound PostAdjective has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Coordinating Conjunction (Optional)</li>
	  					  <li>PostAdjective Object 1 (Required)</li>
	  					  <li>PostAdjective Object 2 (Required)</li>
	  					  <li>PostAdjective Object 3 (Optional)</li>
	  					  <li>PostAdjective Object 4 (Optional)</li>
	  					  </ol>
	  					  See the general Compound Grammar Element help item for an explanation on how we handle
	  					  the Conjunction Sentence Parts. A conjunction is not required within a 
	  					  Compound PostAdjective and a Correlative Conjunction is not allowed.<br><br>
	  					  In the examples below, the Compound PostAdjectives are underlined. The individual 
	  					  PostAdjective Objects are highlighted, alternating in bold and italics.
	  					 `,
	  		examples: ['He was concerned about the report <u><b>he received</b> <i>regarding the approaching storm</i></u>.<br>',
	  				   'We saw the herd <u><b>of deer</b> <i>running across the field</i></u>.<br>',
	  				   'They need to replace all the bricks <u><b>around the gate</b> <i>that are broken and crumbling</i></u>.<br>',
	  				  ],
	  		relatedHelpIds: [62, 8, 147, 202],
	  },

	  {
	  		helpId: 23, 
	  		helpCategory: 1, 
	  		grammarCategory: 13, 
	  		gramElemTypeId: 29,
	  		title: 'Compound Predicate',
	  		description: `A Compound Predicate is a Predicate Object made up of two or more Predicates.
	  					  In our system, a Predicate is always a complete Predicate meaning that it includes
	  					  any associated objects, complements or modfiers. It may not have any of those,
	  					  so it may just be a simple Verb, but you should use care to identify the entire Predicate.<br><br>
	  					  Any of the ten Predicate patterns can be combined in a Compound Predicate. The compound
	  					  includes all of the Predicates that share the same Subject in a Clause.<br><br>

	  					  A Compound Predicate has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Coordinating Conjunction (Required)</li>
	  					  <li>Predicate 1 (Required)</li>
	  					  <li>Predicate 2 (Required)</li>
	  					  <li>Predicate 3 (Optional)</li>
	  					  <li>Predicate 4 (Optional)</li>
	  					  </ol>
	  					  See the general Compound Grammar Element help item for an explanation on how we handle
	  					  the Conjunction Sentence Parts. A conjunction is required within a 
	  					  Compound Predicate and a Correlative Conjunction is not allowed.<br><br>

	  					  One thing to be careful of with Compound Predicates is determining whether an Adverb 
	  					  Object belongs to the last Predicate or the parent Clause Grammar Element. If the Adverb 
	  					  Object applies to both Predicates, then it 
	  					  belongs to the Clause. Consider this example:<br><br>

	  					  'The kids ran and skipped happily down the street.'<br><br>
	  					  In this example, the Compound Adverb 
	  					  Object 'happily down the street' tells us both how they ran and how they skipped so it 
	  					  applies to both Predicates. - i.e., they ran happily down the street and they 
	  					  skipped happily down the street. Because it applies to both Predicates, we know the 
	  					  Adverb Object is part of the Clause, not just the second Predicate.<br><br>

	  					  In the examples below, the Compound Predicates are underlined. The individual 
	  					  Predicates are highlighted, alternating in bold and italics.
	  					 `,
	  		examples: ['The crowd <u><b>jumped to their feet</b> and <i>cheered wildly</i></u>!<br>',
	  				   'The boat <u><b>ran into the rocks</b> and <i>began to sink</i></u>.<br>',
	  				   'She is <u><b>very creative</b> and <i>draws beautifully</i></u>.<br>',
	  				  ],
	  		relatedHelpIds: [62, 105, 60],
	  },

	  {
	  		helpId: 24, 
	  		helpCategory: 1, 
	  		grammarCategory: 14, 
	  		gramElemTypeId: 30,
	  		title: 'Compound Clause',
	  		description: `A Compound Clause is short for a Compound Independent Clause. It is a Grammar Element 
	  					  that has at least two Independent Clauses and a connecting Coordinating or Correlative 
	  					  Conjunction.<br><br>
	  					  In the examples below, the first Independent Clause is underlined and the second is 
	  					  highlighted in italics.
	  					 `,
	  		examples: ['<u>His father is very wise</u>, so <i>I welcomed his advice</i>.<br>',
	  				   '<u>Did you solve that problem yourself</u> or <i>did someone help you</i>?<br>',
	  				   '<u>Their dog continually chased the cat</u>, but <i>he never caught her</i>.<br>',
	  				  ],
	  		relatedHelpIds: [62, 50, 100],
	  },

	  {
	  		helpId: 25, 
	  		helpCategory: 1, 
	  		grammarCategory: 8, 
	  		gramElemTypeId: 31,
	  		title: 'Adverb Prep Phrase',
	  		description: `An Adverb Prepositional Phrase is a Prepositional Phrase that is used as an Adverb 
	  					  Object. A Prepositional Phrase consists of a Preposition and a Noun Object. <br><br>
	  					  The Adverb Prepositional Phrases are underlined in the examples below. For contrast,
	  					  we also highlight any Adjectives in italics.
	  					 `,
	  		examples: ['We slid <u>on the ice</u> and almost fell.<br>',
	  				   'She set the jar <i>of flour</i> <u>on the kitchen counter</u>.<br>',
	  				   'We saw lots <i>of small animals</i> <u>on the bank <i>of the river</i></u>.<br>',
	  				  ],
	  		relatedHelpIds: [152, 120, 130, 203],
	  },

	  {
	  		helpId: 26, 
	  		helpCategory: 1, 
	  		grammarCategory: 7, 
	  		gramElemTypeId: 32,
	  		title: 'Adjective Prep Phrase',
	  		description: `An Adjective Prepositional Phrase is a Prepositional Phrase that is used as a PostAdjective 
	  					  Object. A Prepositional Phrase consists of a Preposition and a Noun Object. <br><br>
	  					  The Adjective Prepositional Phrases are underlined in the examples below. For contrast,
	  					  we also highlight any Adverb Prepositional Phrases in italics.
	  					 `,
	  		examples: ['Our friends showed us the location <u>of their new house</u> <i>on the map</i>.<br>',
	  				   'They built the foundation <u>of the house</u> <i>on solid rock</i>.<br>',
	  				   'Let\'s put the food <u>for the party</u> <i>on the table</i>. <br>',
	  				  ],
	  		relatedHelpIds: [152, 120, 147],
	  },

	  {
	  		helpId: 27, 
	  		helpCategory: 1, 
	  		grammarCategory: 1, 
	  		gramElemTypeId: 33,
	  		title: 'Noun Dependent Clause',
	  		description: `A Noun Dependent Clause, sometimes also just called a Noun Clause, is a Dependent 
	  					  Clause that functions as a Noun Object.
	  					  It may be used as a Subject, a Direct Object, a Predicate Nominative, or it can 
	  					  be the Noun Object in a Prepositional Phrase.<br><br>
	  					  A Noun Dependent Clause has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Relative Pronoun (Optional)</li>
	  					  <li>Subordinating Conjunction (Optional)</li>
	  					  <li>Beginning Adverb Object (Optional)</li>
	  					  <li>Subject (Required)</li>
	  					  <li>Predicate Object (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  The clause may begin with either a Relative Pronoun or a Subordinating Conjunction, but
	  					  neither is required. The Adverb Objects are also optional, but the Subject and  
	  					  Predicate Object are both required.<br><br>
	  					  The Noun Dependent Clauses are underlined in the examples below and their function in
	  					  the sentence is in parentheses.
	  					 `,
	  		examples: ['I don\'t know <u>why they would say that</u>. (Direct Object)<br>',
	  				   '<u>Whoever answers first</u> will win the prize! (Subject)<br>',
	  				   'I am not responsible for <u>whatever you decide to do</u>. (Prepositional Phrase Object)<br>',
	  				   'The reason I am late is <u>that we had a flat tire</u>. (Predicate Nominative)<br>',
	  				  ],
	  		relatedHelpIds: [59, 110, 111, 113, 120, 153, 156, 125, 110, 105, 126],
	  },

	  {
	  		helpId: 28, 
	  		helpCategory: 1, 
	  		grammarCategory: 7, 
	  		gramElemTypeId: 34,
	  		title: 'Adjective Dependent Clause',
	  		description: `An Adjective Dependent Clause, sometimes also called a relative clause, is a 
	  					  Dependent Clause that functions as a PostAdjective Object. It usually directly 
	  					  follows the Noun Object that it modifies or it may be part of a Compound 
	  					  PostAdjective.<br><br>
	  					  An Adjective Dependent Clause has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Relative Pronoun (Optional)</li>
	  					  <li>Beginning Adverb Object (Optional)</li>
	  					  <li>Subject (Required)</li>
	  					  <li>Predicate Object (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  The clause may begin with a Relative Pronoun, but it is not required. In particular, the 
	  					  relative pronoun 'that' is often not used.
	  					  The Adverb Objects in the clause are also optional, but the Subject and  
	  					  Predicate Object are both required.<br><br>
	  					  In the examples below, each Adjective Dependent Clause is underlined and the Noun 
	  					  Object it modifies is highlighted in bold. In addition, the Relative Pronoun 'that'
	  					  is shown in parentheses to indicate that it is optional.
	  					 `,
	  		examples: ['This is the <b>plan</b> <u>(that) we will follow</u>.<br>',
	  				   'The <b>one</b> <u>who told me what happened</u> was my brother.<br>',
	  				   'The <b>children</b> in the class <u>who are finished with their assignment</u> may go to lunch.<br>'
	  				  ],
	  		relatedHelpIds: [59, 153, 125, 110, 105, 126],
	  },

	  {
	  		helpId: 29, 
	  		helpCategory: 1, 
	  		grammarCategory: 8, 
	  		gramElemTypeId: 35,
	  		title: 'Adverb Dependent Clause',
	  		description: `An Adverb Dependent Clause, sometimes also just called an 
	  					  adverb clause, is a 
	  					  Dependent Clause that functions as an Adverb Object.<br><br>
	  					  An Adverb Dependent Clause has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Subordinating Conjunction (Required)</li>
	  					  <li>Beginning Adverb Object (Optional)</li>
	  					  <li>Subject (Required)</li>
	  					  <li>Predicate Object (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  The clause always begins with a Subordinating Conjunction. 
	  					  The Adverb Objects in the clause are optional, as they are with all clauses, but the 
	  					  Subject and Predicate Object are both required.<br><br>
	  					  An Adverb Dependent Clause usually modifies a verb, but it can also modify an adjective.
	  					  Although some grammar experts will say that an Adverb Dependent Clause doesn't modify anything, 
	  					  (they say it simply shows relationship to other elements in the clause it belongs to), 
	  					  we choose to treat it as we do any other Adverb Object in our system with one exception. It 
	  					  will never modify another Adverb Object.
	  					  <br><br>
	  					  Each Adverb Dependent Clause is underlined in the examples below and the word it modifies
	  					  is highlighted in bold. The type of the word modified is also noted in parentheses.
	  					 `,
	  		examples: ['They <b>waited</b> for him <u>because he had the keys they needed</u>. (Verb)<br>',
	  				   '<u>Even though it might rain</u>, we will still <b>go</b> to the park. (Verb)<br>',
	  				   'I am <b>afraid</b> <u>they won\'t come</u>. (Adjective)<br>',
	  				  ],
	  		relatedHelpIds: [59, 156, 125, 110, 105, 126, 203],
	  },

	  {
	  		helpId: 30, 
	  		helpCategory: 1, 
	  		grammarCategory: 1, 
	  		gramElemTypeId: 36,
	  		title: 'Gerund',
	  		description: `A Gerund is a present participle verb (i.e., one that ends in 'ing') 
	  					  that functions as a Noun Object. It may or may not be part of a Gerund Phrase.<br><br>
	  					  The example Gerunds below are highlighted in bold and, where appropriate,
	  					  the full Gerund Phrase is underlined.`,
	  		examples: ['<b>Dancing</b> is one of my favorite forms of exercise.<br>',
	  				   'She seems to like <u><b>arguing</b> with her neighbor</u>.<br>',
	  				   '<b>Reading</b> and <b>writing</b> are such important skills to have.<br>',
	  				   'They will be <u><b>lighting</b> the Christmas tree</u> any minute now.<br>'
	  				  ],
	  		relatedHelpIds: [56, 35],
	  },

	  {
	  		helpId: 31, 
	  		helpCategory: 1, 
	  		grammarCategory: 6, 
	  		gramElemTypeId: 37,
	  		title: 'Participle',
	  		description: `A participle is a verb that functions as an adjective. There are two types.
	  					  One is sometimes called a 'present participle' and uses the <i>-ing</i> form of a verb
	  					  as in 'the sound of <i>breaking</i> glass.' The other is sometimes called a 'past 
	  					  participle' and uses the <i>-en</i> or <i>-ed</i> form of a verb as in 'the <i>broken</i>' 
	  					  glass.'<br><br>
	  					  In our system, a Participle is a PreAdjective Object. That means it normally comes before 
	  					  the Noun Object
	  					  that it modifies. The only time it doesn't is if it is used as a Predicate Adjective or
	  					  an Adjective Object Complement. See those Sentence Parts for more details and examples.<br><br>
	  					  A Participle may also be part of a Participial Phrase. In that case, the 
	  					  Participial Phrase is a PostAdjective Object rather than a PreAdjective Object. See that
	  					  help item for more details and examples.<br><br>
	  					  In the examples below, the participles are highlighted in bold. Also, where the participle
	  					  is used as a Predicate Adjective or an Adjective Object Complement, that Sentence Part is 
	  					  noted in parentheses.
	  					 `,
	  		examples: ['They could hear the sound of <b>breaking</b> glass coming from the kitchen.<br>',
	  				   'The <b>roasted</b> broccoli is almost ready.<br>',
	  				   'I find this problem <b>confusing</b> and <b>frustrating</b>. (Adjective Object Complement)<br>',
	  				   'Her argument seems very <b>convincing</b> to me. (Predicate Adjective)<br>'
	  				  ],
	  		relatedHelpIds: [56, 36, 114, 136],
	  },

	  {
	  		helpId: 32, 
	  		helpCategory: 1, 
	  		grammarCategory: 1, 
	  		gramElemTypeId: 38,
	  		title: 'Noun Infinitive',
	  		description: `A Noun Infinitive is an Infinitive that functions as a Noun Object. It may or may not 
	  					  be part of a Noun Infinitive Phrase.<br><br>
	  					  In the examples below, the Noun Infinitives are highlighted in bold and, where appropriate,
	  					  the full Noun Infinitive Phrase is underlined.
	  					 `,
	  		examples: ['She convinced me <b>to play</b>.<br>',
	  				   'I would like <u><b>to go</b> for a walk</u> this evening.<br>',
	  				   'She advised him <u><b>to study</b> diligently for the exam</u>.<br>',
	  				  ],
	  		relatedHelpIds: [56, 58, 37],
	  },

	  {
	  		helpId: 33, 
	  		helpCategory: 1, 
	  		grammarCategory: 7, 
	  		gramElemTypeId: 39,
	  		title: 'Adjective Infinitive',
	  		description: `An Adjective Infinitive is an Infinitive that functions as a PostAdjective Object. It may or may not 
	  					  be part of an Adjective Infinitive Phrase.<br><br>
	  					  In the examples below, the Adjective Infinitives are highlighted in bold and, where appropriate,
	  					  the full Adjective Infinitive Phrase is underlined.
	  					 `,
	  		examples: ['This is the best plan <b>to follow</b>.<br>',
	  				   'The only way <u><b>to find</b> the answer you need</u> is to keep searching.<br>',
	  				   'I have some more work <b>to finish</b> before I can leave.<br>',
	  				  ],
	  		relatedHelpIds: [56, 58, 38],
	  },

	  {
	  		helpId: 34, 
	  		helpCategory: 1, 
	  		grammarCategory: 8, 
	  		gramElemTypeId: 40,
	  		title: 'Adverb Infinitive',
	  		description: `An Adverb Infinitive is an Infinitive that functions as an Adverb Object. It may or may not 
	  					  be part of an Adverb Infinitive Phrase.<br><br>
	  					  In the examples below, the Adverb Infinitives are highlighted in bold and, where appropriate,
	  					  the full Adverb Infinitive Phrase is underlined.
	  					 `,
	  		examples: ['Because of the loud crash, we stopped <b>to investigate</b>.<br>',
	  				   'They hung the clothes and towels on the line <b>to dry</b>.<br>',
	  				   'She stood up <u><b>to speak</b> to the crowd</u>.<br>',
	  				  ],
	  		relatedHelpIds: [56, 58, 39, 203],
	  },

	  {
	  		helpId: 35, 
	  		helpCategory: 1, 
	  		grammarCategory: 1, 
	  		gramElemTypeId: 41,
	  		title: 'Gerund Phrase',
	  		description: `A Gerund Phrase is made up of a beginning Gerund and at least one other object
	  					  such as a Direct Object or an Adverb Object. See the Verbal Phrase help item 
	  					  for all objects that may be included.<br><br>
	  					  The Gerund Phrases are underlined in the examples below and the Gerunds are highlighted
	  					  in bold. The type of object (or objects) included in the phrase is noted in parentheses.
	  					 `,
	  		examples: ['<u>Skiing in Vermont</u> is their favorite winter vacation. (Adverb Object)<br>',
	  				   'The kids enjoy <u>stacking the colorful blocks</u> to build tall towers. (Direct Object)<br>',
	  				   'She likes <u>writing her friend unexpected secret notes</u>. (Indirect and Direct Objects)<br>',
	  				   'They are considering <u>painting the room a light shade of blue</u>. (Direct Object and Adjective Object Complement)<br>'
	  				  ],
	  		relatedHelpIds: [57, 30],
	  },

	  {
	  		helpId: 36, 
	  		helpCategory: 1, 
	  		grammarCategory: 7, 
	  		gramElemTypeId: 42,
	  		title: 'Participial Phrase',
	  		description: `A Participial Phrase is made up of a beginning Participle and at least one other object
	  					  such as a Direct Object or an Adverb Object. See the Verbal Phrase help item 
	  					  for all objects that may be included.<br><br>
	  					  Note that a Participle on its own functions as a PreAdjective Object, but a Participial
	  					  Phrase functions as a PostAdjective Object.<br><br>
	  					  The Participial Phrases are underlined in the examples below and the Participles are
	  					  highlighted in bold. The type of object (or objects)
	  					  included in the phrase is noted in parentheses.
	  					 `,
	  		examples: ['The children <u><b>playing</b> outside</u> were having so much fun!<br>',
	  				   'There was a small, wobbly table <u><b>sitting</b> on the porch</u>.<br>',
	  				   'We watched the line of ants <u><b>crawling</b> up the cabinet</u>.<br>',
	  				  ],
	  		relatedHelpIds: [57, 31],
	  },

	  {
	  		helpId: 37, 
	  		helpCategory: 1, 
	  		grammarCategory: 1, 
	  		gramElemTypeId: 43,
	  		title: 'Noun Infinitive Phrase',
	  		description: `An Noun Infinitive Phrase is made up of a beginning Noun Infinitive and at least one 
	  					  other object
	  					  such as a Direct Object or an Adverb Object. See the Verbal Phrase help item 
	  					  for all objects that may be included.<br><br>
	  					  The Noun Infinitive Phrases are underlined in the examples below and the Noun Infinitives are
	  					  highlighted in bold.
	  					 `,
	  		examples: ['We need <u><b>to clean</b> the house</u> today.<br>',
	  				   'He tried <u><b>to sell</b> his bike</u> to make some extra money.<br>',
	  				   'She wants <u><b>to become</b> a musician</u>.<br>',
	  				   'They taught us <u><b>to play</b> the game</u> last night.<br>',
	  				  ],
	  		relatedHelpIds: [57, 32],
	  },

	  {
	  		helpId: 38, 
	  		helpCategory: 1, 
	  		grammarCategory: 7, 
	  		gramElemTypeId: 44,
	  		title: 'Adjective Infinitive Phrase',
	  		description: `An Adjective Infinitive Phrase is made up of a beginning Adjective Infinitive and at least one 
	  					  other object
	  					  such as a Direct Object or an Adverb Object. See the Verbal Phrase help item 
	  					  for all objects that may be included.<br><br>
	  					  The Adjective Infinitive Phrases are underlined in the examples below and the Adjective Infinitives are
	  					  highlighted in bold.
	  					 `,
	  		examples: ['This seems like a good strategy <u><b>to handle</b> the situation</u>.<br>',
	  				   'The judge\'s decision <u><b>to release</b> the prisoner</u> was questioned by many.<br>',
	  				   'We bought some treats <u><b>to give</b> to everyone at the party</b>.<br>',
	  				  ],
	  		relatedHelpIds: [57, 33],
	  },

	  {
	  		helpId: 39, 
	  		helpCategory: 1, 
	  		grammarCategory: 8, 
	  		gramElemTypeId: 45,
	  		title: 'Adverb Infinitive Phrase',
	  		description: `An Adverb Infinitive Phrase is made up of a beginning Adverb Infinitive and at least one 
	  					  other object
	  					  such as a Direct Object or an Adverb Object. See the Verbal Phrase help item 
	  					  for all objects that may be included.<br><br>
	  					  The Adverb Infinitive Phrases are underlined in the examples below and the Adverb Infinitives are
	  					  highlighted in bold.
	  					 `,
	  		examples: ['The exhausted runner paused <u><b>to catch</b> his breath</u>.<br>',
	  				   'The kids enjoy stacking the colorful blocks <u><b>to build</b> tall towers</u>.<br>',
	  				   'He tried to sell his bike <u><b>to make</b> some extra money</u>.<br>',
	  				   'They dug a large, deep hole <u><b>to bury</b> the box<u>.<br>',
	  				  ],
	  		relatedHelpIds: [57, 34, 203],
	  },

	  {
	  		helpId: 40, 
	  		helpCategory: 1, 
	  		grammarCategory: 13, 
	  		gramElemTypeId: 46,
	  		title: 'Be Verb with Adverb (I)',
	  		description: `A 'Be Verb with Adverb' Predicate uses a Be Verb Phrase. It has no Predicate Adjective
	  					  or Predicate Nominative, but requires an Adverb Object for the Clause to be complete.
						  It has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Be Verb Phrase (Required)</li>
	  					  <li>Embedded Verb Adverb Object (Optional)</li>
	  					  <li>Ending Adverb Object (Required)</li>
	  					  </ol>
	  					  In the examples below, the entire Predicate is underlined and the Adverb Object is 
	  					  highlighted in bold.
	  					 `,
	  		examples: ['The kids <u>are <b>upstairs</b></u>.<br>',
	  				   '<u>Is</u> she <u><b>at home by herself</b></u>?<br>',
	  				   'The last show <u>will likely be <b>on Sunday</b></u>.<br>',
	  				  ],
	  		relatedHelpIds: [167, 127, 126],
	  },

	  {
	  		helpId: 41, 
	  		helpCategory: 1, 
	  		grammarCategory: 13, 
	  		gramElemTypeId: 47,
	  		title: 'Be Verb with Predicate Adjective (II)',
	  		description: `A 'Be Verb with Predicate Adjective' Predicate has required Be Verb Phrase and Predicate 
	  					  Adjective Sentence Parts.<br><br>
	  					  A Predicate Adjective is also sometimes called a subject complement. We call it a
	  					  Predicate Adjective to distinguish it from a subject complement that is a Noun 
	  					  Object (i.e., a Predicate Nominative).<br><br>
						  This type of Predicate has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Be Verb Phrase (Required)</li>
	  					  <li>Embedded Verb Adverb Object (Optional)</li>
	  					  <li>Predicate Adjective (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  In the examples below, the entire Predicate is underlined and the Predicate Adjective is 
	  					  highlighted in bold.
	  					 `,
	  		examples: ['The sunsets on the water <u>are usually <b>gorgeous</b></u>.<br>',
	  				   'The talk she gave <u>was <b>so inspiring</b></u>!<br>',
	  				   'The reason she gave for being late <u>was <b>so unexpected</b></u>.<br>',
	  				  ],
	  		relatedHelpIds: [167, 127, 114, 126],
	  		// Not using PrePredicate Adjective and PostPredicate Adjective yet
	  		//relatedHelpIds: [167, 127, 114, 135, 143, 126],
	  },

	  {
	  		helpId: 42, 
	  		helpCategory: 1, 
	  		grammarCategory: 13, 
	  		gramElemTypeId: 48,
	  		title: 'Be Verb with Predicate Nominative (III)',
	  		description: `A 'Be Verb with Predicate Nominative' Predicate has required Be Verb Phrase and Predicate 
	  					  Nominative Sentence Parts. <br><br>
	  					  A Predicate Nominative is also sometimes called a subject complement. We call it a
	  					  Predicate Nominative to distinguish it from a subject complement that is an Adjective 
	  					  Object (i.e., a Predicate Adjective).<br><br>
						  This type of Predicate has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Be Verb Phrase (Required)</li>
	  					  <li>Embedded Verb Adverb Object (Optional)</li>
	  					  <li>Predicate Nominative (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  In the examples below, the entire Predicate is underlined and the Predicate Nominative is 
	  					  highlighted in bold.
	  					 `,
	  		examples: ['Mr. Mendez <u>was <b>their teacher</b> last year</u>.<br>',
	  				   'The car he drove <u>will be <b>an antique</b> in a few years</u>.<br>',
	  				   'The buiding <u>is <b>an old rundown shack that needs to be demolished</b></u>.<br>',
	  				  ],
	  		relatedHelpIds: [167, 127, 113, 126],
	  },

	  {
	  		helpId: 43, 
	  		helpCategory: 1, 
	  		grammarCategory: 13, 
	  		gramElemTypeId: 49,
	  		title: 'Linking Verb with Predicate Adjective (IV)',
	  		description: `A 'Linking Verb with Predicate Adjective' Predicate uses a non-'be' linking verb. It  
	  					  requires only two Sentence Parts: a Verb and a 
	  					  Predicate Adjective. All other parts are optional.<br><br>
	  					  A Predicate Adjective is also sometimes called a subject complement. We call it a
	  					  Predicate Adjective to distinguish it from a subject complement that is a Noun 
	  					  Object (i.e., a Predicate Nominative).<br><br>
						  This type of Predicate has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Aux Verb Phrase (Optional)</li>
	  					  <li>Embedded Verb Adverb Object (Optional)</li>
	  					  <li>Verb (Required)</li>
	  					  <li>Predicate Adjective (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  In the examples below, the entire Predicate is underlined and the Predicate Adjective is 
	  					  highlighted in bold.
	  					 `,
	  		examples: ['The students <u>had become <b>restless and bored</b> during the afternoon</u>.<br>',
	  				   'The soup <u>had tasted <b>very bitter</b> because of the herbs</u>.<br>',
	  				   'The price of the house <u>seems <b>ridiculous</b></u>.<br>',
	  				  ],
	  		relatedHelpIds: [168, 127, 166, 114, 126],
	  },

	  {
	  		helpId: 44, 
	  		helpCategory: 1, 
	  		grammarCategory: 13, 
	  		gramElemTypeId: 50,
	  		title: 'Linking Verb with Predicate Nominative (V)',
	  		description: `A 'Linking Verb with Predicate Nominative' Predicate uses a non-'be' linking verb. It 
	  					  requires only two Sentence Parts: a Verb and a and Predicate Nominative. All other parts
	  					  are optional. <br><br>
	  					  A Predicate Nominative is also sometimes called a subject complement. We call it a
	  					  Predicate Nominative to distinguish it from a subject complement that is an Adjective 
	  					  Object (i.e., a Predicate Adjective).<br><br>
						  This type of Predicate has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Aux Verb Phrase (Optional)</li>
	  					  <li>Embedded Verb Adverb Object (Optional)</li>
	  					  <li>Verb (Required)</li>
	  					  <li>Predicate Nominative (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  In the examples below, the entire Predicate is underlined and the Predicate Nominative is 
	  					  highlighted in bold.
	  					 `,
	  		examples: ['The students <u>will become <b>graduates</b> once they pass their final exams</u>.<br>',
	  				   'In time, the caterpillar <u>became <b>a beautiful butterfly</b></u>.<br>',
	  				   'Maria <u>remained <b>a faithful friend to her</b> all their lives</u>.<br>',
	  				  ],
	  		relatedHelpIds: [168, 127, 166, 113, 126],
	  },

	  {
	  		helpId: 45, 
	  		helpCategory: 1, 
	  		grammarCategory: 13, 
	  		gramElemTypeId: 51,
	  		title: 'Intransitive Action Verb (VI)',
	  		description: `An 'Intransitive Action Verb' Predicate uses an action verb. The Verb is the only
	  					  required Sentence Part. <br><br>
						  This type of Predicate has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Aux Verb Phrase (Optional)</li>
	  					  <li>Embedded Verb Adverb Object (Optional)</li>
	  					  <li>Verb (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  In the examples below, the entire Predicate is underlined.
	  					 `,
	  		examples: ['<u>Stop</u>!<br>',
	  				   'They <u>dance really well together</u>.<br>',
	  				   'The door <u>slammed because of a strong gust of wind</u>.<br>',
	  				  ],
	  		relatedHelpIds: [168, 127, 166, 126],
	  },

	  // give example of direct object being a gerund phrase
	  {
	  		helpId: 46, 
	  		helpCategory: 1, 
	  		grammarCategory: 13, 
	  		gramElemTypeId: 52,
	  		title: 'Direct Object (VII)',
	  		description: `A 'Direct Object' Predicate uses a transitive verb and has two required Sentence Parts: 
	  					  a Verb and a Direct Object.<br><br>
						  This type of Predicate has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Aux Verb Phrase (Optional)</li>
	  					  <li>Embedded Verb Adverb Object (Optional)</li>
	  					  <li>Verb (Required)</li>
	  					  <li>Direct Object (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  In the examples below, the entire Predicate is underlined and the Direct Object is 
	  					  highlighted in bold.
	  					 `,
	  		examples: ['He <u>accidentally broke <b>the glass</b></u>.<br>',
	  				   'The kids <u>climbed <b>the large tree in the backyard</b></u>.<br>',
	  				   'They <u>like <b>skiing in the mountains</b> every winter.</u><br>',
	  				  ],
	  		relatedHelpIds: [168, 127, 166, 111, 126],
	  },

	  {
	  		helpId: 47, 
	  		helpCategory: 1, 
	  		grammarCategory: 13, 
	  		gramElemTypeId: 53,
	  		title: 'Direct and Indirect Object (VIII)',
	  		description: `A 'Direct and Indirect Object' Predicate uses a transitive verb and has three required 
	  					  Sentence Parts: a Verb, an Indirect Object, and a Direct Object.<br><br>
						  This type of Predicate has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Aux Verb Phrase (Optional)</li>
	  					  <li>Embedded Verb Adverb Object (Optional)</li>
	  					  <li>Verb (Required)</li>
	  					  <li>Indirect Object (Required)</li>
	  					  <li>Direct Object (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  In the examples below, the entire Predicate is underlined, the Indirect Object is 
	  					  highlighted with italics, and the Direct Object is 
	  					  highlighted in bold.
	  					 `,
	  		examples: ['She <u>baked <i>her father</i> <b>a cake</b> for his birthday</u>.<br>',
	  				   'She <u>gave <i>him</i> <b>a haircut<b> last night<u>.<br>',
	  				   'She <u>handed <i>me</i> <b>the wrong package</b> by mistake</u>.<br>',
	  				  ],
	  		relatedHelpIds: [168, 127, 166, 112, 111, 126],
	  },

	  {
	  		helpId: 48, 
	  		helpCategory: 1, 
	  		grammarCategory: 13, 
	  		gramElemTypeId: 54,
	  		title: 'Noun Object Complement (IX)',
	  		description: `A 'Noun Object Complement' Predicate uses a transitive verb and has three required 
	  					  Sentence Parts: a Verb, a Direct Object, and a Noun Object Complement.<br><br>
						  This type of Predicate has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Aux Verb Phrase (Optional)</li>
	  					  <li>Embedded Verb Adverb Object (Optional)</li>
	  					  <li>Verb (Required)</li>
	  					  <li>Direct Object (Required)</li>
	  					  <li>Noun Object Complement (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  In the examples below, the entire Predicate is underlined, the Direct Object is 
	  					  highlighted in bold, and the Noun Object Complement is 
	  					  highlighted with italics.
	  					 `,
	  		examples: ['The team <u>elected <b>Tyrone</b> <i>captain</i></u>.<br>',
	  				   'They <u>named <b>this movie</b> <i>the top picture of the year</i></u>.<br>',
	  				   'He <u>called <b>them</b> <i>a lot of harsh names</i> because he was angry</u>.<br>',
	  				  ],
	  		relatedHelpIds: [168, 127, 166, 111, 115, 126],
	  },

	  {
	  		helpId: 49, 
	  		helpCategory: 1, 
	  		grammarCategory: 13, 
	  		gramElemTypeId: 55,
	  		title: 'Adjective Object Complement (X)',
	  		description: `An 'Adjective Object Complement' Predicate uses a transitive verb and has three required 
	  					  Sentence Parts: a Verb, a Direct Object, and an Adjective Object Complement.<br><br>
						  This type of Predicate has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Aux Verb Phrase (Optional)</li>
	  					  <li>Embedded Verb Adverb Object (Optional)</li>
	  					  <li>Verb (Required)</li>
	  					  <li>Direct Object (Required)</li>
	  					  <li>Adjective Object Complement (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  In the examples below, the entire Predicate is underlined, the Direct Object is 
	  					  highlighted in bold, and the Adjective Object Complement is 
	  					  highlighted with italics.
	  					 `,
	  		examples: ['They <u>usually keep <b>their home</b> </i>spotless</i></u>.<br>',
	  				   'I <u>find <b>this problem</b> <i>the most challenging of all</i></u>.<br>',
	  				   'He <u>makes <b>me</b> <i>so mad</i> sometimes</u>!<br>',
	  				  ],
	  		relatedHelpIds: [168, 127, 166, 111, 136, 126],
	  },

	  // Give examples of imperative clauses (i.e., that don't have a subject). They can still have
	  // a beginning adverb object
	  {
	  		helpId: 50, 
	  		helpCategory: 1, 
	  		grammarCategory: 14, 
	  		gramElemTypeId: 56,
	  		title: 'Simple Clause',
	  		description: `A Simple Clause is a single Independent Clause.
	  					  We call it a Simple Clause, rather than a Simple Independent Clause, just for the
	  					  sake of shortness. We then contrast it with a Compound Clause (also rather than a 
	  					  Compound Independent Clause). We do this when we ask a user to identify 
	  					  whether a sentence is a Simple Clause or a Compound Clause. <br><br>
	  					  A Simple Clause always refers to an Independent Clause, never a Dependent Clause.
	  					  We also don't ask users to identify Complex or Compound-Complex sentences in our system. 
	  					  Instead, we identify Dependent Clauses separately according to their function as Noun, 
	  					  Adverb, or Adjective Objects.<br><br>
	  					  See the Independent Clause help item for more details and additional examples.
	  					 `,
	  		examples: ['Everyone heard her in spite of the noise and confusion.<br>',
	  				   'Who is invited to the party?<br>',
	  				   'The bear found lots of berries and other things to eat in the forest.<br>',
	  				  ],
	  		relatedHelpIds: [58, 24, 100],
	  },

	  {
	  		helpId: 51, 
	  		helpCategory: 1, 
	  		grammarCategory: 15, 
	  		gramElemTypeId: 57,
	  		title: 'Declarative Sentence',
	  		description: `A Declarative Sentence is one that simply makes a statement or provides
	  					  information. It always ends with a period.<br><br>
	  					  A Declarative Sentence has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Interjection (Optional)</li>
	  					  <li>Sentence Adverb Object (Optional)</li>
	  					  <li>Independent Clause Object (Required)</li>
	  					  </ol>
	  					  See the help items for these Sentence Parts for more details.
	  					 `,
	  		examples: ['They like skiing in Vermont.<br>',
	  				   'There was a beautiful rainbow in the sky after the storm.<br>',
	  				   'The students in the class finished their assignment early.<br>',
	  				  ],
	  		relatedHelpIds: [52, 53, 54, 55, 162, 128, 100],
	  },

	  {
	  		helpId: 52, 
	  		helpCategory: 1, 
	  		grammarCategory: 15, 
	  		gramElemTypeId: 58,
	  		title: 'Imperative Sentence',
	  		description: `An Imperative Sentence tells someone what to do. It may be a
	  					  request, a command, advice, or something similar.
	  					  It has no explicit subject because the subject is implied and is 
	  					  understood to be 'you' - i.e., the person or people the speaker 
	  					  is talking to.<br><br>
	  					  An Imperative Sentence has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Interjection (Optional)</li>
	  					  <li>Noun of Address (Optional)</li>
	  					  <li>Independent Clause Object (Required)</li>
	  					  </ol>
	  					  See the help items for these Sentence Parts for more details.
	  					 `,
	  		examples: ['Please close the door when you leave.<br>',
	  				   'Bring the questions to me so I can try to help.<br>',
	  				   'Be careful not to fall as you walk across the rocks.<br>',
	  				  ],
	  		relatedHelpIds: [51, 53, 54, 55, 162, 119, 100],
	  },

	  {
	  		helpId: 53, 
	  		helpCategory: 1, 
	  		grammarCategory: 15, 
	  		gramElemTypeId: 59,
	  		title: 'Exclamatory Sentence',
	  		description: `An Exclamatory Sentence expresses strong emotion and ends in an exclamation point.
	  					  Many sentences can be either a Declarative or an Exclamatory Sentence so the 
	  					  presence of an ending exclamation or period helps the reader understand the meaning
	  					  more clearly.<br><br>
	  					  An Exclamatory Sentence has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Interjection (Optional)</li>
	  					  <li>Sentence Adverb Object (Optional)</li>
	  					  <li>Independent Clause Object (Required)</li>
	  					  </ol>
	  					  See the help items for these Sentence Parts for more details.
	  					 `,
	  		examples: ['I can\'t believe we won the game!<br>',
	  				   'There is a snake under the porch!<br>',
	  				   'We can\'t stay here any longer!<br>',
	  				  ],
	  		relatedHelpIds: [51, 52, 54, 55, 162, 128, 100],
	  },

	  {
	  		helpId: 54, 
	  		helpCategory: 1, 
	  		grammarCategory: 15, 
	  		gramElemTypeId: 60,
	  		title: 'Interrogative Sentence',
	  		description: `An Interrogative Sentence asks a question and ends in a question mark. 
	  					  Usually the first auxillary verb of a verb phrase will come before the subject
	  					  in an Interrogative Sentence. It may also use an Interrogative Pronoun.<br><br>
	  					  An Interrogative Sentence has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Interjection (Optional)</li>
	  					  <li>Independent Clause Object (Required)</li>
	  					  </ol>
	  					  See the help items for these Sentence Parts for more details.
	  					 `,
	  		examples: ['Why are you so happy?<br>',
	  				   'Will the kids be coming to visit soon?<br>',
	  				   'How are we supposed to fit all these pieces together?<br>',
	  				  ],
	  		relatedHelpIds: [51, 52, 53, 55, 2, 162, 100],
	  },

	  {
	  		helpId: 55, 
	  		helpCategory: 1, 
	  		grammarCategory: 15, 
	  		gramElemTypeId: 61,
	  		title: 'Imperative Exclamatory Sentence',
	  		description: `An Imperative Exclamatory Sentence is one that is both an Imperative Sentence 
	  					  and an Exclamatory Sentence combined. It has no subject and ends in an exclamation
	  					  point. Because it expresses strong emotion, it typically expresses a command.<br><br>
	  					  Given the nature of it being a strong command, it isn't unusual for an imperative
	  					  exclamatory sentence to be very short, even just one word as in: "Stop!"<br><br>
	  					  An Imperative Exclamatory Sentence has the following Sentence Parts in the order specified:
	  					  <ol>
	  					  <li>Interjection (Optional)</li>
	  					  <li>Noun of Address (Optional)</li>
	  					  <li>Independent Clause Object (Required)</li>
	  					  </ol>
	  					  See the help items for these Sentence Parts for more details.
	  					 `,
	  		examples: ['Be quiet this instant!<br>',
	  				   'Watch out!<br>',
	  				   'Help!<br>',
	  				  ],
	  		relatedHelpIds: [51, 52, 53, 54, 162, 119, 100],
	  },

	  /* 
	     The following are additional Grammar Elements that we don't use in Sentences, but we want to define
	     from a help point of view. They are more general elements.
	   */
	  {
	  		helpId: 56, 
	  		helpCategory: 4, 
	  		gramElemTypeId: 62,
	  		title: 'Verbal',
	  		description: `A Verbal is a form of a verb that is used as some other 
	  					  part of speech. Verbals can be used as a Noun Object, an Adjective Object (in our system
	  					  this means a PreAdjective or PostAdjective Object), or an 
	  					  Adverb Object. They may also be used within a Verbal Phrase.<br><br>
	  					  There are five different types of Verbals, three of which are Infinitives: 
	  					  <ol>
	  					  	<li>Gerund</li>
	  					  	<li>Participle</li>
	  					  	<li>Noun Infinitive</li>
	  					  	<li>Adjective Infinitive</li>
	  					  	<li>Adverb Infinitive</li>
	  					  </ol>
	  					  At times, sentences can be hard to dissect and understand because of the use of Verbals.
	  					  It can be challenging and confusing to determine which verbs in a sentence are used as 
	  					  the Verb Sentence Part within a Predicate vs those that are 
	  					  used as other parts of speech - i.e., Verbals. This is why we specifically identify 
	  					  Verbals and their functions.<br><br>
	  					  Refer to the links below for more information and examples.
	  					  `,
	  		examples: [],
	  		relatedHelpIds: [30, 31, 58, 32, 33, 34, 57],
	  },

	  {
	  		helpId: 57, 
	  		helpCategory: 4, 
	  		gramElemTypeId: 63,
	  		title: 'Verbal Phrase',
	  		description: `A Verbal Phrase is made up of a beginning Verbal and one or more of
	  					  the following possible objects:
	  					  <ol>
	  					  	<li>Indirect Object</li>
	  					  	<li>Direct Object</li>
	  					  	<li>Adverb Object</li>
	  					  	<li>Predicate Adjective</li>
	  					  	<li>Adjective Object Complement</li>
	  					  </ol>
	  					  Not all combinations of these elements are allowed in a Verbal Phrase. For example,
	  					  if it has a Direct Object, then it cannot have a Predicate Adjective. If it has an
	  					  Adjective Object Complement, then it cannot have an Indirect Object.<br><br>
	  					  The rules for the ten different types of Predicates apply to a Verbal Phrase. Any
	  					  combination of elements that is allowed in a given Predicate type is also allowed
	  					  in a Verbal Phrase. See those types for more details.<br><br>
	  					  Refer to the links below for more information and examples of each Verbal Phrase type.
	  					  `,
	  		examples: [],
	  		relatedHelpIds: [56, 35, 36, 59, 37, 38, 39],
	  },

	  {
	  		helpId: 58, 
	  		helpCategory: 1, 
	  		grammarCategory: 16, 
	  		gramElemTypeId: 64,
	  		title: 'Infinitive',
	  		description: `An Infinitive is the use of a verb that doesn't show tense, person, or number.
	  					  It is often preceded by the particle 'to,' but doesn't have to be. When not included,
	  					  we call the Infinitive a 'Bare Infinitive.'<br><br>
	  					  An Infinitive most commonly uses the present tense form of a verb and in that case,
	  					  we call it a 'Present Infinitive'. Other possible types are a 'Perfect Infinitive,' a
	  					  'Continuous Infinitive,' and so on.  In our system, we typically use Present Infinitives.<br><br>
	  					  An Infinitive can be used as a Noun Object, a PostAdjective Object,
	  					  or an Adverb Object. See the related help links for more information
	  					  about those specific types.`,
	  		examples: [],
	  		relatedHelpIds: [32, 33, 34],
	  },

	  {
	  		helpId: 59, 
	  		helpCategory: 1, 
	  		grammarCategory: 16, 
	  		gramElemTypeId: 65,
	  		title: 'Infinitive Phrase',
	  		description: `An Infinitive Phrase begins with an Infinitive and includes at
	  					  least one object or modifier, possibly more. Refer to the general Verbal Phrase
	  					  help item for information on the possible Sentence Parts that may be included. <br><br>
	  					  As with plain Infinitives, an Infinitive Phrase 
	  					  can function as a Noun Object, a PostAdjective Object, or an Adverb Object.
	  					  See the related help links for more information
	  					  about those specific types.`,
	  		examples: [],
	  		relatedHelpIds: [37, 38, 39],
	  },

	  {
	  		helpId: 60,  
	  		helpCategory: 1, 
	  		grammarCategory: 16, 
	  		gramElemTypeId: 66,
	  		title: 'Independent Clause',
	  		description: `An Independent Clause typically has at least two
	  					  Sentence Parts: a Subject and a Predicate. The only exception to this is for 
	  					  the Independent Clause of an Imperative Sentence. In that case, the Subject is 
	  					  an implied 'you,' so it is understood, but does not actually exist in the clause.<br><br>
	  					  An Independent Clause has the following Sentence Parts in the order specified:

	  					  <ol>
	  					  <li>Beginning Adverb Object (Optional)</li>
	  					  <li>Subject (Required except in Imperative Sentence)</li>
	  					  <li>Predicate Object (Required)</li>
	  					  <li>Ending Adverb Object (Optional)</li>
	  					  </ol>
	  					  We distinguish between a Beginning Adverb Object and an Ending Adverb Object in our
	  					  system so we know what the positioning of the part should be within the Grammar Element.<br><br>
	  					  Note that both a Predicate and a Clause can have an Ending Adverb Object, so it can 
	  					  sometimes be unclear which one it should belong to. We typically 
	  					  define it as part of the Predicate. However, if the Clause has a Compound Predicate
	  					  and the Adverb Object applies to both Predicates, then the Ending Adverb is defined 
	  					  as part of the Clause. An example would be:<br><br>
	  					  'The kids ran and skipped happily down the street.'<br><br>
	  					  In this Simple Clause, both 'ran' and 'skipped' are Predicates and together they 
	  					  create a Compound Predicate. The Compound Adverb Object 'happily down the street' 
	  					  applies to both Predicates. - i.e., they ran happily down the street and they 
	  					  skipped happily down the street. 
	  					 `,
	  		examples: ['When the lights began to flicker, they knew it was time to leave.<br>',
	  				   'Everyone on the team lined up to enter the stadium.<br>',
	  				   'Both cats were sleeping on the windowsill right next to each other.<br>'
	  				  ],
	  		relatedHelpIds: [61, 50],
	  },

	  {
	  		helpId: 61, 
	  		helpCategory: 1, 
	  		grammarCategory: 16, 
	  		gramElemTypeId: 67,
	  		title: 'Dependent Clause',
	  		description: `A Dependent Clause, sometimes also called a subordinate clause, is a clause that
	  					  cannot stand on its own because it doesn't express a complete thought. It can 
	  					  function as an Adjective, Adverb, or Noun Object.
	  					  <br><br>
	  					  All clauses have both a Subject and a Predicate and this is true of Dependent Clauses
	  					  as well. Dependent Clauses also have optional Beginning or Ending Adverb Objects just 
	  					  as Independent Clauses do. In many cases, they will have introductory words that link 
	  					  the clause to other parts of the clause they belong to. We cover those details along 
	  					  with examples in each of the specific Dependent Clause help items.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [27, 28, 29, 60],
	  },

	  {
	  		helpId: 62, 
	  		helpCategory: 1, 
	  		grammarCategory: 16, 
	  		gramElemTypeId: 68,
	  		title: 'Compound Grammar Element',
	  		description: `We can have a compound Grammar Element that is created with any of the following object
	  					  types: Noun Object, Adverb Object, PreAdjective Object, PostAdjective Object, Predicate,
	  					  or Independent Clause. The similarities in how we handle these compound types is
	  					  described here.<br><br>
	  					  An example of a compound is a Compound Noun Object which has the following Sentence Parts
	  					  in this order:
	  					  <ol>
	  					  <li>Coordinating Conjunction</li>
	  					  <li>Noun Object 1 (Required)</li>
	  					  <li>Noun Object 2 (Required)</li>
	  					  <li>Noun Object 3 (Optional)</li>
	  					  <li>Noun Object 4 (Optional)</li>
	  					  <li>Correlative Conjunction</li>
	  					  </ol>
	  					  (Note that we are using the Noun Object for this example, but all of the compound types have 
	  					  a similar Sentence Part structure using their corresponding object type.)<br><br>
	  					  Each compound must have at least two objects of the given type. In our system, it can 
	  					  have up to four, though the only real limit in reality is what makes sense in a given
	  					  sentence.<br><br>
	  					  We identify the Coordinating Conjunction first, if there is one. We do this because we
	  					  don't know the actual position of that Sentence Part until we know how many items the 
	  					  compound has. It must be between the last two object Sentence Parts, but that could be
	  					  between objects 1 and 2, 2 and 3, or 3 and 4.<br><br>
	  					  Some compound types don't require a Coordinating Conjunction. For example, often a 
	  					  Compound Adverb will not use one and a Compound PreAdjective may or may not use one. See the 
	  					  specific help item for details.<br><br>
	  					  A Correlative Conjunction is a two-part conjunction. The first part comes before any of 
	  					  the objects and the second part comes between the last two objects, just as a Coordinating
	  					  Conjunction does. Because it is much less common, we handle this as the last Sentence Part.<br><br>
	  					  If a compound requires a conjunction, it must have either a Coordinating Conjunction or 
	  					  Correlative Conjunction, but never both.<br><br>
	  					  See the individual help items for more details and examples of each compound type.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [19, 20, 21, 22, 23, 24, 154, 155],
	  },


	  /* SentencePart Help Items */
	  
	  // Mention that at the sentence level, the independent clause object includes the compound
	  // independent clause if there is one. 
	  {
	  		helpId: 100, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 2,
	  		title: 'Independent Clause Object',
	  		description: `The Independent Clause Object Sentence Part is used only within the 
	  					  Sentence Type Grammar Elements - i.e., a Declarative Sentence, Imperative 
	  					  Sentence, and so on.<br><br>
	  					  There are only two possible types for an Independent Clause Object Sentence
	  					  Part. It is either a Simple Clause (when there is one Independent Clause)
	  					  or a Compound Clause (when there are two or more).<br><br>
	  					  See those help items for more details and examples.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [50, 24, 51, 52, 53, 54, 55],
	  },

	  // We've decided not to use the individual compound help items, but leaving these as is for now.
	  // They won't appear in the Index and aren't referenced by any other help items.
	  {
	  		helpId: 101, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 3,
	  		title: 'Independent Clause 1',
	  		description: `A Compound Clause has two or more Independent Clauses which we also call
	  					  Simple Clauses. They are each identified
	  					  by number according to their position in the compound. This Sentence Part 
	  					  identifies the one that comes first.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [50, 24],
	  },

	  {
	  		helpId: 102, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 4,
	  		title: 'Independent Clause 2',
	  		description: `A Compound Clause has two or more Independent Clauses which we also call
	  					  Simple Clauses. They are each identified
	  					  by number according to their position in the compound. This Sentence Part 
	  					  identifies the second one in the order.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 103, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 5,
	  		title: 'Independent Clause 3',
	  		description: `A Compound Clause has two or more Independent Clauses which we also call
	  					  Simple Clauses. They are each identified
	  					  by number according to their position in the compound. This Sentence Part 
	  					  identifies the third one in the order.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 104, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 6,
	  		title: 'Independent Clause 4',
	  		description: `A Compound Clause has two or more Independent Clauses which we also call
	  					  Simple Clauses. They are each identified
	  					  by number according to their position in the compound. This Sentence Part 
	  					  identifies the fourth one in the order.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 105, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 7,
	  		title: 'Predicate Object',
	  		description: `In our system, a Predicate Object is what some would call a complete predicate. 
	  					  It includes a verb and any other accompanying objects and complements.<br><br>
	  					  Some systems of grammar refer to sentence patterns such as Subject-Verb-Object,
	  					  but we are more specific and identify the ten different types of predicate 
	  					  patterns that can be used:
	  					  <ol>
	  					  <li>Be Verb with an Adverb Object (I)</li>
	  					  <li>Be Verb with a Predicate Adjective (II)</li>
	  					  <li>Be Verb with a Predicate Nominative (III)</li>
	  					  <li>Linking Verb with a Predicate Adjective (IV)</li>
	  					  <li>Linking Verb with a Predicate Nominative (V)</li>
	  					  <li>Intransitive Action Verb (VI)</li>
	  					  <li>Action Verb with a Direct Object (VII)</li>
	  					  <li>Action Verb with both Direct and Indirect Objects (VIII)</li>
	  					  <li>Action Verb with a Direct Object and Noun Object Complement (IX)</li>
	  					  <li>Action Verb with a Direct Object and Adjective Object Complement (X)</li>
	  					  </ol>
	  					  The first five predicate types use a linking verb. The first three specifically use the
	  					  linking verb 'be' and the next two use any other linking verb such as 'feel' or
	  					  'become.'<br><br>
	  					  The Predicate Adjective and Predicate Nominative might also be called a subject 
	  					  complement. We distinguish the two types of complements more specifically according to 
	  					  their function. A Predicate Adjective is, as you would expect, an Adjective Object,
	  					  and a Predicate Nominative is a Noun Object.<br><br>
	  					  The last five predicate types use an action verb. The first uses an intransitive verb, 
	  					  so it requires no object. The others all require at least a Direct Object and all but one  
	  					  uses another object as well.<br><br>
	  					  All of the predicate types can also include Adverb Objects. The first predicate type
	  					  specifically requires an Adverb Object, but for all the remaining types, they are optional.<br><br>
	  					  We describe the details of each predicate type in their specific help items. Refer to those
	  					  for more information and examples. Notice that we shorten the Grammar Element type names
	  					  where we can and only include a few key identifying elements to 
	  					  distinguish them from the other types. We also label them with a Roman numeral to more
	  					  easily identify them.<br><br>
	  					  We also speak to how we handle Verbs and Verb Phrases in particular since there are some 
	  					  custom differences for our system.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [170, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49],
	  },

	  {
	  		helpId: 106, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 8,
	  		title: 'Predicate 1',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 107, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 9,
	  		title: 'Predicate 2',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 108, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 10,
	  		title: 'Predicate 3',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 109, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 11,
	  		title: 'Predicate 4',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 110, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 12,
	  		title: 'Subject',
	  		description: `The Subject Sentence Part is part of an Independent Clause or any of the Dependent
	  					  Clause Grammar Elements. It provides the information regarding who is performing the 
	  					  action of the Clause or tells what the Clause is about.<br><br>
	  					  A Subject is a Noun Object, so any of the Grammar Element types in the Noun Object
	  					  Category can be used as a Subject.<br><br>
	  					  See the related help items for more details and examples.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [60, 61, 27, 28, 29, 200],
	  },

	  {
	  		helpId: 111, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 13,
	  		title: 'Direct Object',
	  		description: `A Direct Object Sentence Part is a Noun Object in a Predicate that is used with a transitive action 
	  					  verb. It typically answers the question 'what?'
	  					  or 'whom?' regarding the Verb. Some examples are:<br><br>
	  					  'She rode the pony.' What did she ride? 'the pony.'<br><br>
	  					  'I took her to the store.' Whom did I take? 'her.'<br><br>
	  					  A Verbal Phrase can also have a Direct Object and it serves the same purpose in terms of
	  					  telling us 'whom' or 'what' in relation to the Verbal.<br><br>
	  					  Because Clauses and Verbal Phrases can be nested within each other, Direct Objects can 
	  					  also be nested. In each case, you can ask 'whom?' or 'what?' to help find the Direct Object.<br><br>
	  					  With some Predicate patterns, a Direct Object is combined with another object such as an 
	  					  Indirect Object or a Noun Object Complement. See those Predicate pattern Grammar Elements 
	  					  for more details and examples.<br><br>
	  					  A Direct Object can be any type of Grammar Element that is a Noun Object. See the 
	  					  possiblities in the Noun Object Category help item.<br><br>
	  					  Some examples of Direct Objects with different Grammar Element types are below. The
	  					  Direct Object in a Predicate is underlined and the Direct Object in a Verbal Phrase
	  					  is highlighted in bold. The Grammar Element types are shown in parentheses.
	  					 `,
	  		examples: ['We like <u>to grill <b>hotdogs and burgers<b></u> during the summer. (Noun Infin Phrase, Compound Noun Object)<br>',
	  				   'I tried <u>taking dance lessons</u>, but it didn\'t work out very well. (Gerund Phrase)<br>',
	  				   'We watched <u>the chipmunks playing in the yard</u>. (Noun Phrase)<br>',
	  				   'I told her <u>the secret</u>. (Noun Phrase)<br>',
	  				   'We learned <u>that he can\'t come on the trip</u> yesterday. (Noun Dependent Clause)<br>',
	  				  ],
	  		relatedHelpIds: [46, 47, 48, 49, 57, 200],
	  },

	  {
	  		helpId: 112, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 14,
	  		title: 'Indirect Object',
	  		description: `An Indirect Object is always combined with a Direct Object. In this case, the 
	  					  Direct Object answers the question 'what?' and the Indirect Object answers the 
	  					  question 'whom?'<br><br>
	  					  Here is a simple example:<br><br>
	  					  'I told them that they could come with us.'<br><br>
	  					  'that they could come with us' is a Noun Dependent Clause that functions as the Direct 
	  					  Object stating what I told. 'them' is who I told.<br><br>
	  					  An Indirect Object is also a Noun Object, but the Grammar Element types that are used 
	  					  for this Sentence Part are much more limited. Because it is telling us information about
	  					  who is involved, it would never be a Noun Dependent Clause, Verbal, or Verbal Phrase.
	  					  It can be a Noun, Pronoun, Noun Phrase or Compound Noun, though. It could also be a 
	  					  Noun Appositive, though that is less common.<br><br>
	  					  In the examples below, the Indirect Object is highlighted in bold and the Direct Object
	  					  is underlined.
	  					 `,
	  		examples: ['I asked <b>her and Jeff</b> <u>to quiet down</u>, but they refused.<br>',
	  				   'The cat was giving <b>herself</b> <u>a bath</u>.<br>',
	  				   'The doctor advised <b>me</b> <u>to get more exercise</u>.<br>',
	  				  ],
	  		relatedHelpIds: [47, 200],
	  },

	  {
	  		helpId: 113, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 15,
	  		title: 'Predicate Nominative',
	  		description: `A Predicate Nominative is used with a linking verb and renames the Subject.
	  					  It is also sometimes called a 'subject complement' because it complements or
	  					  completes the Subject.<br><br>
	  					  A Predicate Nominative can be any type of Grammar Element that is a Noun Object. See the 
	  					  possiblities in the Noun Object Category help item.<br><br>
	  					  In the examples below, the Predicate Nominative is highlighted in bold and the Subject
	  					  is underlined.
	  					 `,
	  		examples: ['<u>This</u> is <b>the best day ever</b>!<br>',
	  				   '<u>She</u> eventually did become <b>a professional dancer</b> after lots of hard work.<br>',
	  				   '<u>One thing I know for sure</u> is <b>that you are incredibly smart</b>.<br>',
	  				  ],
	  		relatedHelpIds: [42, 44, 110, 200],
	  },

	  {
	  		helpId: 114, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 16,
	  		title: 'Predicate Adjective',
	  		description: `A Predicate Adjective is used with a linking verb and describes or modifies (i.e., gives 
	  					  us more information about) the Subject.
	  					  It is also sometimes called a 'subject complement' because it complements or
	  					  completes the Subject.<br><br>
	  					  A Predicate Adjective can be any type of Grammar Element that is a PreAdjective Object. See the 
	  					  possiblities in the PreAdjective Object Category help item. It can also be an Adjective 
	  					  Prepositional Phrase which is a PostAdjective Object,
	  					  though that is less common.<br><br>
	  					  In the examples below, the Predicate Adjective is highlighted in bold and the Subject
	  					  is underlined.
	  					 `,
	  		examples: ['<u>This problem</u> is <b>quite challenging</b>.<br>',
	  				   '<u>I</u> am <b>so encouraged</b> for you!<br>',
	  				   '<u>He</u> felt <b>weak and tired</b> after the long race.<br>',
	  				   '<u>Our shoes</u> got <b>soaking wet</b> in the rain.<br>',
	  				   '<u>My feet</u> felt <b>like lead</b> as I walked up the hill.<br>',
	  				  ],
	  		relatedHelpIds: [41, 43, 110, 201, 202],
	  },

	  {
	  		helpId: 115, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 17,
	  		title: 'Noun Object Complement',
	  		description: `A Noun Object Complement is a Noun Object in a Predicate that qualifies or 
	  					  completes the Direct Object.<br><br>
	  					  A Noun Object Complement is a Noun Object, but the Grammar Element types that are used 
	  					  for this Sentence Part are limited. Because it gives us additional information about
	  					  the Direct Object, it would never be a Noun Dependent Clause, Verbal, or Verbal Phrase.
	  					  It can be a Noun, Pronoun, Noun Phrase or Compound Noun.<br><br>
	  					  In the examples below, the Noun Object Complement is highlighted in bold and the Direct
	  					  Object is underlined.
	  					  `,
	  		examples: ['He declared <u>himself</u> <b>king for a day</b>.<br>',
	  				   'They always call <u>her</u> <b>their dear friend</b>.<br>',
	  				   'The kids named <u>their new puppy</u> <b>Bailey</b>.<br>',
	  				  ],
	  		relatedHelpIds: [48, 200],
	  },

	  {
	  		helpId: 116, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 18,
	  		title: 'Noun Appositive',
	  		description: `A Noun Appositive Sentence Part is a Noun Object that renames the Noun Object 
	  					  next to it to give additional clarifying information. It is used only as a part of 
	  					  the Noun Appositive Grammar Element, along with the main Noun Object that it clarifies.<br><br>
	  					  A Noun Appositive can be any type of Grammar Element that is a Noun Object, though it 
	  					  is more common for it to be a Noun or Noun Phrase. See the 
	  					  possiblities in the Noun Object Category help item.<br><br>
	  					  In the examples below, the Noun Appositive is highlighted in bold and the main Noun
	  					  Object it renames is underlined.
	  					 `,
	  		examples: ['<u>An old friend</u>, <b>someone he knew years before</b>, called him unexpectedly one day.<br>',
	  				   '<u>Andre</u>, <b>the fastest runner on the team</b>, easily took the lead.<br>',
	  				   '<u>Their new kitten</u>, <b>a tiny little ball of fluff</b>, loves chasing his tail.<br>',
	  				  ],
	  		relatedHelpIds: [15, 200],
	  },

	  {
	  		helpId: 117, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 19,
	  		title: 'Gerund',
	  		description: `A Gerund Sentence Part is used in only one Grammar Element and that is a Gerund Phrase.
	  					  Only a Gerund, not any other type of Verbal Grammar Element, can begin a Gerund Phrase.<br><br>
	  					  See the Gerund Phrase Grammar Element help item for more details and examples.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [30, 35],
	  },

	  {
	  		helpId: 118, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 20,
	  		title: 'Noun Infinitive',
	  		description: `A Noun Infinitive Sentence Part is used in only one Grammar Element and that is a Noun Infinitive Phrase.
	  					  Only a Noun Infinitive, not any other type of Verbal Grammar Element, can begin a Noun Infinitive Phrase.<br><br>
	  					  See the Noun Infinitive Phrase Grammar Element help item for more details and examples.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [32, 37],
	  },

	  {
	  		helpId: 119, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 21,
	  		title: 'Noun of Address',
	  		description: `The Noun of Address Sentence Part is used only within the Imperative
	  					  and Imperative Exclamatory Sentence Type Grammar Elements. It is used to specifically address 
	  					  the person or people to whom the speaker is talking.<br><br>
	  					  The Noun of Address is a Noun Object, but since it specifically names a person or people,
	  					  it must be either a Noun or a Compound Noun Object where the elements of the Compound Noun Object
	  					  are Nouns.<br><br>
	  					  Nouns of Address can come at the beginning, end, or even sometimes in the middle of a sentence, 
	  					  but for simplicity in our system, we always place them at the beginning of a sentence.<br><br>
	  					  In the examples below, the Nouns of Address are highlighted in bold.
	  					 `,
	  		examples: ['<b>Alicia</b>, please close the door.<br>',
	  				   '<b>Fernando and Darryl</b>, please get ready to address the team.<br>',
	  				  ],
	  		relatedHelpIds: [52, 55],
	  },

	  {
	  		helpId: 120, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 22,
	  		title: 'Noun Object',
	  		description: `A Noun Object Sentence Part is just a general Noun Object and is used to define 
	  					  multiple Grammar Elements. The types that are allowed for each one are slightly different, 
	  					  so we describe each of them separately here.<br><br>
	  					  The Noun Object in both Noun Phrase and Adverb Noun Phrase Grammar Elements is normally
	  					  very simple because it is being modified by the other elements in that Grammar Element type
	  					  - e.g., the PreAdjective Object, PostAdjective Object, and so on. Given this, the Noun Object
	  					  is typically a Noun, Pronoun, or Compound Noun Object where the elements of the compound are 
	  					  also simple Nouns or Pronouns.<br><br>
	  					  Some examples of the Noun Object in this case, highlighted in bold, are 
	  					  'the first <b>one</b> in line' and 
	  					  'the <b>boys and girls</b> in the class.'<br><br>
	  					  An Adverb or Adjective Prepositional Phrase also uses a Noun Object. In this case, the 
	  					  Noun Object can be any type of Noun Object, though a Noun Infinitive or Noun Infinitive Phrase
	  					  likely would not make sense.<br><br>
	  					  A Noun Appositive Grammar Element also uses a general Noun Object. It is the main Noun Object
	  					  that the Noun Appositive Sentence Part renames.<br><br>
	  					  More details and examples are available in the help items for each of these Grammar Element types.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [14, 17, 25, 26, 15],
	  },

	  {
	  		helpId: 121, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 23,
	  		title: 'Noun Object 1',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 122, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 24,
	  		title: 'Noun Object 2',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 123, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 25,
	  		title: 'Noun Object 3',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 124, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 26,
	  		title: 'Noun Object 4',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 125, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 27,
	  		title: 'Beginning Adverb Object',
	  		description: `Because of the way we build sentences in our system, we identify Sentence Parts by their
	  					  position in a Grammar Element. Given this, and the fact that Adverb Objects can be in 
	  					  multiple positions, we define the Adverb Object Sentence Parts by their position.<br><br>
	  					  A Beginning Adverb Object Sentence Part comes at the beginning of whatever Grammar Element 
	  					  it is used in. This does not necessarily mean that it comes at the beginning of a sentence and
	  					  in fact, it usually doesn't.<br><br>
	  					  When used in an Independent Clause, the Beginning Adverb Object might come at the beginning
	  					  of a sentence. It may not, however, if the sentence has a Compound Independent Clause 
	  					  and the Beginning Adverb Object is not part of the first Clause. A Beginning Adverb 
	  					  Object can also be used at the beginning of a Dependent Clause.<br><br>
	  					  Other Grammar Elements that use a Beginning Adverb Object are a Modified Adverb or Modified
	  					  Adjective. In this case, the Beginning Adverb Object comes before the Adverb or Adjective
	  					  that it modifies.<br><br>
	  					  In the examples below, the Beginning Adverb Object is highlighted in bold. The Grammar 
	  					  Element types they belong to are indicated in parentheses.
	  					 `,
	  		examples: ['We\'ll go to the store and <b>after that</b>, we\'ll go to the park. (Independent Clause)<br>',
	  				   '<b>Next time</b>, I would do the job myself. (Independent Clause)<br>',
	  				   'He is <b>surprisingly</b> agile. (Modified Adjective)<br>',
	  				  ],
	  		relatedHelpIds: [60, 27, 28, 29, 16, 18],
	  },

	  // Speak to an ending adverb belonging to the predicate or the clause
	  {
	  		helpId: 126, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 28,
	  		title: 'Ending Adverb Object',
	  		description: `Because of the way we build sentences in our system, we identify Sentence Parts by their
	  					  position in a Grammar Element. Given this, and the fact that Adverb Objects can be in 
	  					  multiple positions, we define the Adverb Object Sentence Parts by their position.<br><br>
	  					  An Ending Adverb Object Sentence Part comes at the end of whatever Grammar Element 
	  					  it is used in. This does not necessarily mean that it comes at the end of a sentence and
	  					  in fact, it usually doesn't.<br><br>
	  					  When used in an Independent Clause, the Ending Adverb Object might come at the end
	  					  of a sentence. It may not, however, if the sentence has a Compound Independent Clause 
	  					  and the Ending Adverb Object is not part of the last Clause. An Ending Adverb Object can 
	  					  also be used at the end of a Dependent Clause.<br><br>
	  					  An Ending Adverb Object can also be used in any of the 10 Predicate Grammar Elements.
	  					  There is one special case with the Ending Adverb Object that requires special attention
	  					  and that is when it is used in a sentence that has a Compound Predicate. See that help item 
	  					  for details.<br><br>
	  					  The other Grammar Elements that use an Ending Adverb Object are the Modified Adverb and Modified
	  					  Adjective. In this case, the Ending Adverb Object comes after the Adverb or Adjective
	  					  that it modifies.<br><br>
	  					  In the examples below, the Ending Adverb Objects are highlighted in bold. The Grammar 
	  					  Element types they belong to are indicated in parentheses.
	  					 `,
	  		examples: ['The kids jumped <b>up and down</b> and ran <b>around in circles</b>. (Predicates)<br>',
	  				   'The crowd clapped and cheered <b>wildly</b>. (Independent Clause)<br>',
	  				   'I know my glasses are somewhere <b>in the house</b>. (Modified Adverb)<br>',
	  				  ],
	  		relatedHelpIds: [60, 27, 28, 29, 16, 18, 23, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49],
	  },

	  {
	  		helpId: 127, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 29,
	  		title: 'Embedded Verb Adverb Object',
	  		description: `An Embedded Verb Adverb Object is always just a simple Adverb and never any other 
	  					  type of Adverb Object. As with the other Adverb Object Sentence Parts, the type
	  					  tells us where it exists within the sentence. In this case, that means it tells us
	  					  where it exists within a Predicate.<br><br>
	  					  When this Sentence Part is used within a Be Verb Phrase, it will come before the form 
	  					  of the 'be' verb. When embedded with any other verb, it comes between the Aux Verb 
	  					  Phrase and the Verb. See our help item on Verbs and Verb Phrases for more details.<br><br>
	  					  In the examples below, the Embedded Verb Adverb Object is highlighted in bold, the 
	  					  Verb Phrase is underlined, and the Verb is highlighted with italics.
	  					 `,
	  		examples: ['She <u>had</u> <b>rarely</b> <i>seen</i> anything like it.<br>',
	  				   'There <u>was</u> <b>simply</b> <u>not</u> enough to go around.<br>',
	  				   'He <u>must have</u> <b>only</b> <i>known</i> for about a week.<br>',
	  				  ],
	  		relatedHelpIds: [170, 167, 168, 166],
	  },

	  {
	  		helpId: 128, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 30,
	  		title: 'Sentence Adverb Object',
	  		description: `Some adverbs provide more meaning to an entire sentence or, in some cases, an entire 
	  					  independent clause. These are Sentence Adverbs and they are highlighed in bold in the 
	  					  examples below.<br><br>
	  					  Additionally, traditional grammar doesn't have an easily agreed upon category for the
	  					  adverb 'please' when it is used as a polite way to make a request. We classify 
	  					  it as a Sentence Adverb.`,
	  		examples: ['<b>Obviously</b>, we can\'t go swimming now because of the storm.<br>',
	  				   '<b>Regrettably</b>, she will not be able to join us tonight, but she hopes to join us next week.<br>',
	  				   '<b>Surprisingly</b>, in this particular instance, the entire crowd agreed with him.<br>',
	  				   '<b>Please</b> let us go.'
	  				   ],
	  		relatedHelpIds: [51, 53],
	  },

	  {
	  		helpId: 129, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 31,
	  		title: 'Adverb Infinitive',
	  		description: `An Adverb Infinitive Sentence Part is used in only one Grammar Element and that is an Adverb Infinitive Phrase.
	  					  Only an Adverb Infinitive, not any other type of Verbal Grammar Element, can begin an Adverb Infinitive Phrase.<br><br>
	  					  See the Adverb Infinitive Phrase Grammar Element help item for more details and examples.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [34, 39],
	  },

	  {
	  		helpId: 130, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 32,
	  		title: 'Adverb Object',
	  		description: `An Adverb Object Sentence Part is only used in a few of our Grammar Elements since most
	  					  of the time, we want to identify Adverb Objects by their position (e.g., at the beginning 
	  					  or end of a Grammar Element).<br><br>
	  					  An Adverb Object is used in a Modified Adverb to define the main Adverb that is being 
	  					  modified. It is distinguished from the Beginning Adverb Object and the Ending Adverb
	  					  Object. Because the main Adverb is being modified, it will typically be just a simple Adverb 
	  					  or possibly a Compound Adverb containing only simple Adverbs.<br><br>
	  					  An Adverb Object can also be used in any type of Verbal Phrase. We don't need to distinguish
	  					  the position there because the position is already clear, relative to the other parts that 
	  					  are used in the Verbal Phrase.<br><br>
	  					  A Compound Adverb is the final Grammar Element type that uses a general Adverb Object.
	  					  In that element, we reference the parts by number to identify where they exist within
	  					  the compound. <br><br>
	  					  In the examples below, the Adverb Objects are highlighted in bold. The Grammar 
	  					  Element types they belong to are indicated in parentheses. Where there are multiple Adverb
	  					  Objects in a Compound Adverb, we alterate highlighting in bold and italics.
	  					 `,
	  		examples: ['They laughed so <b>hard</b> that they almost fell on the floor. (Modified Adverb)<br>',
	  				   'The kids slid <b>down the hill</b> <i>on their sleds</i>. (Compound Adverb)<br>',
	  				   'I need to go <b>home soon</b>. (Verbal Phrase)<br>',
	  				  ],
	  		relatedHelpIds: [16, 57, 20, 203],
	  },

	  {
	  		helpId: 131, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 33,
	  		title: 'Adverb Object 1',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 132, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 34,
	  		title: 'Adverb Object 2',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 133, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 35,
	  		title: 'Adverb Object 3',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 134, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 36,
	  		title: 'Adverb Object 4',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 135, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 37,
	  		title: 'PrePredicateAdjective',
	  		description: `A Predicate Adjective modifies or complements the Subject of a Clause. It is 
	  					  normally a Grammar Element that is a PreAdjective Object, but it is sometimes a 
	  					  PostAdjective Object such as an Adjective Prepositional Phrase.<br><br> 
	  					  Given this, we must distinguish between these two cases with a PrePredicateAdjective
	  					  vs a PostPredicateAdjective.
	  					  The PrePredicateAdjective is the more common type by far.<br><br>
	  					  See the Predicate help items that use a Predicate Adjective for more details 
	  					  and examples.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [41, 43, 201],
	  },

	  {
	  		helpId: 136, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 38,
	  		title: 'Adjective Object Complement',
	  		description: `An Adjective Object Complement is a PreAdjective Object in a Predicate that modifies 
	  					  or completes the Direct Object.<br><br>
	  					  In the examples below, the Adjective Object Complement is highlighted in bold and the Direct
	  					  Object is underlined.
	  					  `,
	  		examples: ['I find <u>this problem</u> <b>confusing</b>.<br>',
	  				   'The rain made <u>everything on the ground</u> <b>wet</b>.<br>',
	  				   'She has proven <u>herself</u> <b>trustworthy in every respect</b>.<br>',
	  				  ],
	  		relatedHelpIds: [49, 201],
	  },

	  {
	  		helpId: 137, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 39,
	  		title: 'Participle',
	  		description: `A Participle Sentence Part is used in only one Grammar Element and that is a Participial Phrase.
	  					  Only a Participle, not any other type of Verbal Grammar Element, can begin a Participial Phrase.<br><br>
	  					  See the Participial Phrase Grammar Element help item for more details and examples.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [31, 36],
	  },

	  {
	  		helpId: 138, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 40,
	  		title: 'PreAdjective Object',
	  		description: `Some types of Adjective Objects come before the Noun Object they modify and some come 
	  					  after. Because of this, we want to distinguish the types that can be used in each position,
	  					  so we call them PreAdjective and PostAdjective Objects.<br><br>
	  					  The Grammar Element types that use the Adjective Objects before and after a Noun Object are
	  					  the Noun Phrase and the Adverb Noun Phrase.<br><br>
	  					  There are a few other Grammar Element types that also use a PreAdjective Object and even 
	  					  though, in those cases, they don't come before a Noun Object,
	  					  the same Grammar Element types are used, so we still identify them this way.<br><br>
	  					  
	  					  The other Grammar Element types that use a PreAdjective Object Sentence Part are a 
	  					  Modified Adjective and a Compound PreAdjective. In a Modified Adjective, the PreAdjective
	  					  Object identifies the Adjective Object that is being modified. In a Compound PreAdjective, the 
	  					  general PreAdjective Object is used to identify each individual part of the compound.<br><br>
	  					  In the examples below, the PreAdjective Object is highlighted in bold and the Grammar
	  					  Element type it belongs to is shown in parentheses.
	  					 `,
	  		examples: ['The <b>scared, timid</b> cat quickly ran away. (Noun Phrase)<br>',
	  				   'We went the <b>previous</b> year, but not this one. (Adverb Noun Phrase)<br>',
	  				   'She is quite <b>beautiful</b> and very <b>striking</b>. (Modified Adjectives)<br>',
	  				  ],
	  		relatedHelpIds: [14, 17, 18, 21, 201],
	  },

	  {
	  		helpId: 139, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 41,
	  		title: 'PreAdjective Object 1',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 140, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 42,
	  		title: 'PreAdjective Object 2',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 141, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 43,
	  		title: 'PreAdjective Object 3',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 142, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 44,
	  		title: 'PreAdjective Object 4',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 143, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 45,
	  		title: 'PostPredicateAdjective',
	  		description: `A Predicate Adjective modifies or complements the Subject of a Clause. It is 
	  					  normally a Grammar Element that is a PreAdjective Object, but it can also be a 
	  					  PostAdjective Object such as an Adjective Prepositional Phrase.<br><br> 
	  					  Given this, we must distinguish between these two cases with PrePredicateAdjective
	  					  and PostPredicateAdjective Sentence Parts. The PostPredicateAdjective Sentence Part is 
	  					  used when it is a PostAdjective Object.<br><br>
	  					  See the Predicate help items that use a Predicate Adjective for more details. In the 
	  					  examples below, the PostPredicateAdjective is highlighted in bold. 
	  					 `,
	  		examples: ['My feet felt <b>like lead</b> as I walked up the hill.<br>',
	  				   'She was <b>in a surprisingly cheerful mood</b> after hearing the news.<br>',
	  				  ],
	  		relatedHelpIds: [41, 43, 202],
	  },

	  {
	  		helpId: 144, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 46,
	  		title: 'Beginning Participial Phrase',
	  		description: `A Participial Phrase is a PostAdjective Object. If often comes directly after the 
	  					  Noun Object that it modifies, but at times it can be separated from it. In those 
	  					  cases, it will either come at the beginning of the Clause it belongs to, or at the 
	  					  end after other elements that belong to the Predicate.<br><br>
	  					  Because we distinguish the position of each Sentence Part in a Grammar Element, we
	  					  have both Beginning and Ending Participial Phrase Sentence Parts. They
	  					  are only used with a Noun Phrase Grammar Element.<br><br>
	  					  In the examples below, the Beginning Participial Phrases are highlighted in bold.
	  					 `,
	  		examples: ['<b>Trying to catch her breath</b>, she paused before knocking on the door.<br>',
	  				   '<b>Looking up from his work</b>, he saw a stranger walking toward him.<br>',
	  				   '<b>Fearing for their lives</b>, many people fled from the approaching storm.<br>',
	  				  ],
	  		relatedHelpIds: [14, 36, 145],
	  },

	  {
	  		helpId: 145, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 47,
	  		title: 'Ending Participial Phrase',
	  		description: `A Participial Phrase is a PostAdjective Object. If often comes directly after the 
	  					  Noun Object that it modifies, but at times it can be separated from it. In those 
	  					  cases, it will either come at the beginning of the Clause it belongs to, or at the 
	  					  end after other elements that belong to the Predicate.<br><br>
	  					  Because we distinguish the position of each Sentence Part in a Grammar Element, we
	  					  have both Beginning and Ending Participial Phrase Sentence Parts. They
	  					  are only used with a Noun Phrase Grammar Element.<br><br>
	  					  In the examples below, the Ending Participial Phrases are highlighted in bold.
	  					 `,
	  		examples: ['The arrow flew through the air, <b>hitting its target</b>.<br>',
	  				   'He lay on the grass in the warm sun, <b>staring up into the sky</b>.<br>',
	  				   'She smiled bravely, <b>knowing that the next two years would not be easy</b>.<br>',
	  				  ],
	  		relatedHelpIds: [14, 36, 144],
	  },

	  {
	  		helpId: 146, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 48,
	  		title: 'Adjective Infinitive',
	  		description: `An Adjective Infinitive Sentence Part is used in only one Grammar Element and that is an Adjective Infinitive Phrase.
	  					  Only an Adjective Infinitive, not any other type of Verbal Grammar Element, can begin an Adjective Infinitive Phrase.<br><br>
	  					  See the Adjective Infinitive Phrase Grammar Element help item for more details and examples.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [33, 38],
	  },

	  {
	  		helpId: 147, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 49,
	  		title: 'PostAdjective Object',
	  		description: `Some types of Adjective Objects come before the Noun Object they modify and some come 
	  					  after. Because of this, we want to distinguish the types that can be used in each position,
	  					  so we call them PreAdjective and PostAdjective Objects.<br><br>
	  					  The Grammar Element types that use the Adjective Objects before and after a Noun Object are
	  					  the Noun Phrase and the Adverb Noun Phrase.<br><br>
	  					  The only other type that uses a PostAdjective Object Sentence Part is a Compound 
	  					  PostAdjective. We identify those parts by their position in the compound.<br><br>
	  					  In the examples below, the PostAdjective Objects are highlighted in bold and the Grammar
	  					  Element type they belong to are shown in parentheses. When the parts are used in a 
	  					  compound, we alterate highlighting them in bold and italics.
	  					 `,
	  		examples: ['They moved to the area the same year <b>that we left</b>. (Adverb Noun Phrase)<br>',
	  				   'This is the plan <b>we should follow</b>. (Noun Phrase)<br>',
	  				   'I need to go talk to the boys <b>in the back</b> <i>who are talking too loudly</i>. (Compound PostAdjective)<br>',
	  				  ],
	  		relatedHelpIds: [14, 17, 22, 202],
	  },

	  {
	  		helpId: 148, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 50,
	  		title: 'PostAdjective Object 1',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 149, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 51,
	  		title: 'PostAdjective Object 2',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 150, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 52,
	  		title: 'PostAdjective Object 3',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 151, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 53,
	  		title: 'PostAdjective Object 4',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 152, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 54,
	  		title: 'Preposition',
	  		description: `A Preposition Sentence Part is used only within a Prepositional Phrase Grammar Element
	  					  to define the Preposition.<br><br>
	  					  See the help items for the Prepositional Phrase Grammar Elements for more information
	  					  and examples.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [7, 25, 26],
	  },

	  {
	  		helpId: 153, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 55,
	  		title: 'Relative Pronoun',
	  		description: `A Relative Pronoun Sentence Part is used to introduce either a Noun Dependent Clause
	  					  or Adjective Dependent Clause. This Sentence Part can only be satisfied by a Pronoun 
	  					  Grammar Element.<br><br>
	  					  In the examples below, the Relative Pronoun is highlighted in bold and the full 
	  					  Dependent Clause is underlined. See the Dependent Clause help items for more details
	  					  and examples.
	  					 `,
	  		examples: ['You reap <u><b>what</b> you sow</u>.<br>',
	  				   'He didn\'t know <u><b>who</b> had called his name</u>.<br>',
	  				   'This is the plan <u><b>that</b> we will follow</u>.<br>',
	  				  ],
	  		relatedHelpIds: [2, 27, 28],
	  },

	  {
	  		helpId: 154, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 56,
	  		title: 'Coordinating Conjunction',
	  		description: `The Coordinating Conjunction Sentence Part is used in any of the compound 
	  					  Grammar Element types such as a Compound Noun Object, a Compound Clause, and so on.
	  					  This Sentence Part can only be satisfied by a Conjunction Grammar Element.<br><br>
	  					  Some compound elements require the use of a Coordinating Conjunction, while in 
	  					  others, such as a Compound Adverb or Compound PreAdjective, it is optional.
	  					  A compound Grammar Element might also use a Correlative Conjunction. See the  
	  					  general Compound Grammar Element help item for details on how this is handled.<br><br>
	  					  In the examples below, the Coordinating Conjunction is highlighted in bold and the 
	  					  full compound Grammar Element is underlined.
	  					 `,
	  		examples: ['The bricks around the gate are <u>broken <b>and</b> crumbling</u>.<br>',
	  				   'We\'ll bring <u>lots of berries <b>and</b> other fruit</u> to the picnic.<br>',
	  				   'The <u>bride <b>and</b> groom</u> look so happy!<br>',
	  				   '<u>You can come here first <b>or</b> I can just meet you at the party.</u><br>',
	  				  ],
	  		relatedHelpIds: [8, 62, 19, 20, 21, 22, 23, 24],
	  },

	  {
	  		helpId: 155, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 57,
	  		title: 'Correlative Conjunction',
	  		description: `The Correlative Conjunction Sentence Part is always used within a compound Grammar Element
	  					  that has exactly two elements. The Conjunction has two parts and they are separated by the 
	  					  first element of the compound.<br><br>
	  					  A Correlative Conjunction is only used with some of the compound 
	  					  Grammar Element types. These are the Compound Noun Object, Compound Adverb, and Compound PreAdjective.<br><br>
	  					  A Correlative Conjunction Sentence Part is always optional. If the compound uses a 
	  					  conjunction at all, it will use either a Coordinating Conjunction or a Correlative
	  					  Conjunction, but never both. See the
	  					  general Compound Grammar Element help item for details on how this is handled.<br><br>
	  					  In the examples below, the Correlative Conjunction is highlighted in bold and the 
	  					  full compound Grammar Element is underlined.
	  					 `,
	  		examples: ['This news is <u><b>neither</b> helpful <b>nor</b> encouraging</u>.<br>',
	  				   'We will have <u><b>either</b> pizza <b>or</b> spaghetti</u> for dinner.<br>',
	  				   'They raise <u><b>both</b> chickens <b>and</b> cows</u> on their farm.<br>',
	  				  ],
	  		relatedHelpIds: [8, 62, 19, 20, 21],
	  },

	  {
	  		helpId: 156, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 58,
	  		title: 'Subordinating Conjunction',
	  		description: `A Subordinating Conjunction Sentence Part is used to introduce either a Noun Dependent Clause
	  					  or Adverb Dependent Clause. This Sentence Part can only be satisfied by a Conjunction 
	  					  Grammar Element.<br><br>
	  					  In the examples below, the Subordinating Conjunction is highlighted in bold and the full 
	  					  Dependent Clause is underlined. See the Dependent Clause help items for more details
	  					  and examples.
	  					 `,
	  		examples: ['I need to talk to her <u><b>before</b> she leaves</u>.<br>',
	  				   'It was hard to hear him <u><b>because</b> he spoke so softly</u>.<br>',
	  				   'Her arm swelled <u><b>where</b> the bee had stung her</u>.<br>',
	  				  ],
	  		relatedHelpIds: [8, 27, 29],
	  },

	  {
	  		helpId: 157, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 59,
	  		title: 'PreDeterminer',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 158, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 60,
	  		title: 'Main Determiner',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 159, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 61,
	  		title: 'Ordinal Determiner',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 160, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 62,
	  		title: 'Cardinal Determiner',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 161, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 63,
	  		title: 'Determiner Object',
	  		description: `The Determiner Object Sentence Part is used in the Noun Phrase and Adverb Noun Phrase
	  					  Grammar Elements. For now, in the demo version of the application, we only support a 
	  					  simple Determiner, though with the full application we will also support 
	  					  Possessive Determiners and Complex Determiners.<br><br>
	  					  See the Determiner and Noun Phrase Grammar Element help items for more details and 
	  					  examples.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [10, 14, 17], // 12, 13
	  },

	  {
	  		helpId: 162, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 64,
	  		title: 'Interjection',
	  		description: `An Interjection Sentence Part is used only within the 
	  					  Sentence Type Grammar Elements - i.e., a Declarative Sentence, Imperative 
	  					  Sentence, and so on. It can only be satisfied by a simple Interjection Grammar Element.<br><br>
	  					  See the Interjection and Sentence Type Grammar Element help items for more 
	  					  information and examples.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [9, 51, 52, 53, 54, 55],
	  },

	  {
	  		helpId: 163, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 65,
	  		title: 'Aux Verb 1',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 164, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 66,
	  		title: 'Aux Verb 2',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 165, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 67,
	  		title: 'Aux Verb 3',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  {
	  		helpId: 166, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 68,
	  		title: 'Verb',
	  		description: `A Verb Sentence Part specifically refers only to a main verb. It does not include
	  					  any auxillary verbs and it does not include any form of the verb 'be.'<br><br>
	  					  In the examples below, the Verb is highlighted in bold and the corresponding Aux 
	  					  Verb Phrase, if any, is underlined.
	  					 `,
	  		examples: ['She <b>runs</b> faster than anyone on the team.<br>',
	  				   'The idea <u>might</u> <b>seem</b> unusual to some.<br>',
	  				   'I <u>will be</u> <b>going</b> with them.<br>',
	  				   'They <u>should not have been</u> <b>talking</b> so loudly.<br>',
	  				  ],
	  		relatedHelpIds: [170, 168],
	  },

	  {
	  		helpId: 167, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 69,
	  		title: 'Be Verb Phrase',
	  		description: `A Be Verb Phrase in only used in one of the three Predicate types that use a 'be'
	  					  verb. It includes the form of the 'be' verb, along with any auxillary verbs and 
	  					  possibly the adverb 'not.'<br><br>
	  					  The Be Verb Phrases are underlined in the examples below.
	  					 `,
	  		examples: ['I <u>am</u> tired.<br>',
	  				   'They <u>are</u> the winners.<br>',
	  				   'She <u>has</u> brown hair.<br>',
	  				   'They <u>might be</u> ready.<br>',
	  				   'We <u>could have been</u> there by now.<br>',
	  				   'You <u>should not be</u> loud in here.<br>',
	  				   'He <u>must not have been</u> home.<br>',
	  				  ],
	  		relatedHelpIds: [170],
	  },

	  {
	  		helpId: 168, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 70,
	  		title: 'Aux Verb Phrase',
	  		description: `An Aux (short for Auxillary) Verb Phrase includes all of the auxillary verbs that 
	  					  are used with a main non-'be' verb. There may be up to three auxillary verbs in
	  					  the phrase.<br><br>
	  					  We also include 'not' in the verb phrase, because it helps to complete the meaning
	  					  of the verb phrase. It must be understood, however, that 'not' is <b><i>not</i></b> 
	  					  an auxillary verb, but an adverb.<br><br>
	  					  In the examples below, the Aux Verb Phrases are underlined and the Verbs are 
	  					  highlighted in bold.
	  					 `,
	  		examples: ['She <b>runs</b> faster than anyone on the team.<br>',
	  				   'The idea <u>might</u> <b>seem</b> unusual to some.<br>',
	  				   'I <u>will be</u> <b>going</b> with them.<br>',
	  				   'They <u>should not have been</u> <b>talking</b> so loudly.<br>',
	  				  ],
	  		relatedHelpIds: [170, 166],
	  },

	  {
	  		helpId: 169, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 71,
	  		title: 'Sentence',
	  		description: `tbd`,
	  		examples: [],
	  		relatedHelpIds: [],
	  },

	  /* The Sentence Parts below are not used with our Grammar Elements, but are defined 
	   * as help items for clarity.
	   */

	  {
	  		helpId: 170, 
	  		helpCategory: 2, 
	  		sentPartTypeId: 72,
	  		title: 'Verbs and Verb Phrases',
	  		description: `We handle verbs and verb phrases in our system in a particular way because of the 
	  					  way we create and manage sentences. We make a distinction between the verb 'be' and 
	  					  all other verbs.<br><br>
	  					  'Be' verbs are different because there is no 'main' verb. There is a form of the 
	  					  actual verb 'be' and there may be other auxillary or helping verbs. In addition, 
	  					  we include 'not' as part of the verb phrase. It is an adverb rather than an auxillary
	  					  verb, but it helps to complete the meaning of the phrase.<br><br>
	  					  Here are 
	  					  some examples where the entire verb phrase is underlined:<br><br>
	  					  <ul>
	  					  <li>I <u>am</u> tired.</li>
	  					  <li>They <u>are</u> the winners.</li>
	  					  <li>She <u>has</u> brown hair.</li>
	  					  <li>They <u>might be</u> ready.</li>
	  					  <li>We <u>could have been</u> there by now.</li>
	  					  <li>You <u>should not be</u> loud in here.</li>
	  					  <li>He <u>must not have been</u> home.</li>
	  					  </ul>
	  					  In Predicate types that use a 'be' verb, the Sentence Part that defines the Verb 
	  					  Phrase is called a Be Verb Phrase.
	  					  It includes the form of the 'be' verb along with any auxillary verbs and optionally
	  					  the 'not' adverb.<br><br>
	  					  We handle all other verbs, regardless of whether they are linking or action verbs, 
	  					  by defining two Sentence Parts, an Aux Verb Phrase and 
	  					  a Verb. This allows the user to specifically identify the verb and then separately
	  					  identify any auxillary verbs associated with the verb.<br><br>
	  					  As with a Be Verb Phase, an Aux Verb Phrase also includes the adverb 'not' if it exists, 
	  					  even though it is not an auxillary verb.<br><br>
	  					  Keep in mind that a Predicate might also have an Embedded Verb Adverb Object as well and
	  					  that would be found either within a Be Verb Phrase or between an Aux Verb Phrase and 
	  					  a Verb. See that help item for more details.<br><br>
	  					  In the examples below, the 
	  					  Aux Verb Phrase, if any, is underlined and the Verb is highlighted in bold.
	  					  <ul>
	  					  <li>She <b>runs</b> faster than anyone on the team.</li>
	  					  <li>The idea <u>might</u> <b>seem</b> unusual to some.</li>
	  					  <li>I <u>will be</u> <b>going</b> with them.</li>
	  					  <li>They <u>should not have been</u> <b>talking</b> so loudly.</li>
	  					  </ul> 
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [167, 168, 166, 127],
	  },



	  /* GrammarElement Category Help Items */
	  
	  {
	  		helpId: 200, 
	  		helpCategory: 3, 
	  		gramElemCatTypeId: 1,
	  		title: 'Noun Object Category',
	  		description: `In our system, we call any part of a Sentence that functions as a noun a Noun Object.<br><br>

	  					  There are many different types of Grammar Elements that function as Noun Objects:<br>
	  					  <ol>
	  					  <li>Noun</li>
	  					  <li>Pronoun</li>
	  					  <li>Noun Phrase</li>
	  					  <li>Noun Appositive</li>
	  					  <li>Gerund</li>
	  					  <li>Gerund Phrase</li>
	  					  <li>Noun Infinitive</li>
	  					  <li>Noun Infinitive Phrase</li>
	  					  <li>Noun Dependent Clause</li>
	  					  <li>Compound Noun Object</li>
	  					  </ol>

	  					  And there are many Sentence Parts that require a Noun Object:<br>
	  					  <ol>
	  					  <li>The Subject of a Clause</li>
	  					  <li>A Direct Object in a Predicate</li>
	  					  <li>An Indirect Object in a Predicate</li>
	  					  <li>A Predicate Nominative in a Predicate</li>
	  					  <li>A Noun Object Complement in a Predicate</li>
	  					  <li>The Noun Object of a Noun Phrase</li>
	  					  <li>The Noun Object of a Prepositional Phrase</li>
	  					  <li>A Noun Appositive</li>
	  					  <li>A Noun of Address in a Sentence</li>
	  					  <li>A Noun Object in a Compound Noun Object</li>
	  					  </ol>
	  					  Not all of these Grammar Elements can be used for each Sentence Part, so see the
	  					  links below for more details and examples.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [1, 2, 14, 116, 30, 35, 32, 37, 27, 19, 110, 111, 112, 113, 115, 124, 116, 119],
	  },

	  {
	  		helpId: 201, 
	  		helpCategory: 3, 
	  		gramElemCatTypeId: 6,
	  		title: 'PreAdjective Object Category',
	  		description: `In our system, we call any type of adjective that normally comes before the Noun Object
	  					  it modifies a PreAdjective Object.<br><br>

	  					  There are several different types of Grammar Elements that function as PreAdjective Objects:<br>
	  					  <ol>
	  					  <li>Adjective</li>
	  					  <li>Participle</li>
	  					  <li>Modified Adjective</li>
	  					  <li>Cmpound PreAdjective</li>
	  					  </ol>

	  					  A Participle is also an Adjective, but we call it out specifically so we can completely 
	  					  identify all the Verbal types.<br><br>

	  					  There are a number of Grammar Elements that use a PreAdjective Object. The PreAdjective
	  					  is required in some cases and optional in others as noted in parentheses.<br>
	  					  <ol>
	  					  <li>The PreAdjective Object in a Noun Phrase (Optional)</li>
	  					  <li>The PreAdjective Object in an Adverb Noun Phrase (Optional)</li>
	  					  <li>The Predicate Adjective in a Predicate pattern that requires one (Required)</li>
	  					  <li>A Predicate with an Adjective Object Complement (Required)</li>
	  					  <li>The PreAdjective Object in a Compound PreAdjective (Two are required)</li>
	  					  </ol>
	  					  See the links below for more details and examples.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [6, 31, 18, 21, 14, 17, 41, 43, 49],
	  },

	  {
	  		helpId: 202, 
	  		helpCategory: 3, 
	  		gramElemCatTypeId: 7,
	  		title: 'PostAdjective Object Category',
	  		description: `In our system, we call any type of Adjective Object that normally comes after the Noun Object
	  					  it modifies a PostAdjective Object.<br><br>

	  					  There are several different types of Grammar Elements that function as PostAdjective Objects:<br>
	  					  <ol>
	  					  <li>Adjective Prep Phrase</li>
	  					  <li>Adjective Infinitive</li>
	  					  <li>Participial Phrase</li>
	  					  <li>Adjective Infinitive Phrase</li>
	  					  <li>Adjective Dependent Clause</li>
	  					  <li>Compound PostAdjective</li>
	  					  </ol>

	  					  There is only one Grammar Element that optionally uses a PostAdjective Object, but it 
	  					  can use them in multiple Sentence Parts.<br>
	  					  <ol>
	  					  <li>The PostAdjective Object in a Noun Phrase (Optional)</li>
	  					  <li>The Beginning Participial Phrase in a Noun Phrase (Optional)</li>
	  					  <li>The Ending Participial Phrase in a Noun Phrase (Optional)</li>
	  					  <li>A PostAdjective Object in a Compound PostAdjective (Two are required)</li>
	  					  </ol>
	  					  The PostAdjective Object Sentence Part in a Noun Phrase immediately follows the Noun 
	  					  Object and can be any of the Grammar Element types listed above.
	  					  A Beginning or Ending Participial Phrase can only be one specific type, as you might 
	  					  expect, and that is a Participial Phrase.<br><br>
	  					  See those help items for more details and examples<br><br>
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [26, 33, 38, 36, 28, 22, 14],
	  },

	  {
	  		helpId: 203, 
	  		helpCategory: 3, 
	  		gramElemCatTypeId: 8,
	  		title: 'Adverb Object Category',
	  		description: `In our system, we call any part of a Sentence that functions as an adverb an Adverb Object.<br><br>

	  					  There are many different types of Grammar Elements that function as Adverb Objects:<br>
	  					  <ol>
	  					  <li>Adverb</li>
	  					  <li>Modified Adverb</li>
	  					  <li>Adverb Prep Phrase</li>
	  					  <li>Adverb Noun Phrase</li>
	  					  <li>Adverb Infinitive</li>
	  					  <li>Adverb Infinitive Phrase</li>
	  					  <li>Adverb Dependent Clause</li>
	  					  <li>Compound Adverb</li>
	  					  </ol>

	  					  We also define several Sentence Parts that require an Adverb Object:<br>
	  					  <ol>
	  					  <li>Adverb Object</li>
	  					  <li>Beginning Adverb Object</li>
	  					  <li>Ending Adverb Object</li>
	  					  <li>Embedded Adverb Object</li>
	  					  </ol>

	  					  In most cases, because of how we create our sentences, we identify an Adverb Object 
	  					  by its position within a Grammar Element. It is either at the beginning of an element,
	  					  at the end, or embedded within a verb as indicated. <br><br>
	  					  In the case of a Modified Adverb, it has two or possibly three Adverb Objects. See that
	  					  help item for more details and examples.<br><br>
	  					  The Adverb Objects are used within both Predicates and Clauses.
	  					  Any of the Grammar Element types can be used as the Beginning or Ending Adverb Object,
	  					  but only simple Adverbs function as an Embedded Adverb Object. See additional 
	  					  information as well regarding a Modified Adverb.<br><br>
	  					  More details and examples are available in the related help items.
	  					 `,
	  		examples: [],
	  		relatedHelpIds: [5, 16, 17, 25, 34, 39, 29, 20],
	  },

]


