import React from 'react'
import { gameHelpEntries } from './gamesHelpData'


const useGamesTypeHelp = () => {

  const [gamesTypeHelpItems, setGamesTypeHelpItems] = React.useState(new Map())

  if (gamesTypeHelpItems.size === 0) {
  	let gamesMap = new Map()
  	gameHelpEntries.forEach(helpEntry => {
      gamesMap.set(helpEntry.helpId, helpEntry)
  	}) 
  	setGamesTypeHelpItems(gamesMap)
  }

  return gamesTypeHelpItems
}

export default useGamesTypeHelp
