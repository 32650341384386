import React from 'react'
import { helpEntries } from './helpData'


const useSentPartTypeHelp = () => {

  const [sentPartTypeHelpItems, setSentPartTypeHelpItems] = React.useState(new Map())

  if (sentPartTypeHelpItems.size === 0) {
  	let sentPartMap = new Map()
  	helpEntries.forEach(helpEntry => {
  	  if (helpEntry.sentPartTypeId !== undefined) {
  	  	sentPartMap.set(helpEntry.sentPartTypeId, helpEntry)
  	  }
  	}) 
  	setSentPartTypeHelpItems(sentPartMap)
  }

  return sentPartTypeHelpItems
}

export default useSentPartTypeHelp
