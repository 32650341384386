import React from 'react'
import { helpItemName, helpEntries } from './helpData'

const getIdHelpMap = () => {
	let helpMap = new Map()
	helpEntries.forEach(helpEntry => {
	  if (helpEntry.sentPartTypeId !== undefined) {
	  	helpMap.set(helpEntry.sentPartTypeId, helpEntry.sentPartTypeId)
	  }
	})
	return helpMap  	
}

const getNameHelpMap = () => {
	let helpMap = new Map()
	helpEntries.forEach(helpEntry => {
	  if (helpEntry.sentPartTypeId !== undefined) {
	  	// This is a SentencePart help item, so add it to the map indexed by name
	  	helpMap.set(helpItemName(helpEntry), helpEntry.sentPartTypeId)
	  }
	}) 
	return helpMap
}

const useSentPartHelpId = (typeRef) => {

  // A map of only the SentencePart help items indexed by the GramElemTypeId
  const [sentPartTypeIdHelpMap] = React.useState(getIdHelpMap())

  // A map of only the SentencePart help items indexed by the name
  const [sentPartTypeNameHelpMap] = React.useState(getNameHelpMap())

  if (typeRef !== null) {
    if (sentPartTypeIdHelpMap.has(typeRef)) {
	  	return typeRef
    }
    if (sentPartTypeNameHelpMap.has(typeRef)) {
	  	return sentPartTypeNameHelpMap.get(typeRef)
    }
  }

  return 0
}

export default useSentPartHelpId
