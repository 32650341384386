import React from 'react'
import { helpItemName, helpEntries } from './helpData'

const getIdHelpMap = () => {
	let helpMap = new Map()
	helpEntries.forEach(helpEntry => {
	  if (helpEntry.gramElemCatTypeId !== undefined) {
	  	helpMap.set(helpEntry.gramElemCatTypeId, helpEntry.gramElemCatTypeId)
	  }
	})
	return helpMap  	
}

const getNameHelpMap = () => {
	let helpMap = new Map()
	helpEntries.forEach(helpEntry => {
	  if (helpEntry.gramElemCatTypeId !== undefined) {
	  	// This is a GrammarElement Category help item, so add it to the map indexed by name
	  	helpMap.set(helpItemName(helpEntry), helpEntry.gramElemCatTypeId)
	  }
	}) 
	return helpMap
}

const useGramElemCatHelpId = (typeRef) => {

  // A map of only the GrammarElement help items indexed by the GramElemTypeId
  const [gramElemCatTypeIdHelpMap] = React.useState(getIdHelpMap())

  // A map of only the GrammarElement help items indexed by the name
  const [gramElemCatTypeNameHelpMap] = React.useState(getNameHelpMap())


  if (typeRef !== null) {
    if (gramElemCatTypeIdHelpMap.has(typeRef)) {
	  return typeRef
    }
    if (gramElemCatTypeNameHelpMap.has(typeRef)) {
	  return gramElemCatTypeNameHelpMap.get(typeRef)
    }
  }

  return 0
}

export default useGramElemCatHelpId
