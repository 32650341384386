import React from 'react';
import darkpaths from '../../assets/dark-paths.png'
import styled from 'styled-components';


const HeaderImage = styled.div`  
  background-image: url(${darkpaths});
  background-size: auto;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: #c2d5db;
  padding: 15px 0px;
  margin: 5px 0px 10px;
  width: 100%;
`;

const TitleHeader = ({title}) => {

  return (
  	<HeaderImage>
  	  {title}
  	</HeaderImage>
  )
}

export default TitleHeader
