// source: grammar_games.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var grammar_games_enums_pb = require('./grammar_games_enums_pb.js');
goog.object.extend(proto, grammar_games_enums_pb);
goog.exportSymbol('proto.grammar_games_protos.GameQuestion', null, global);
goog.exportSymbol('proto.grammar_games_protos.GetQuesLineReq', null, global);
goog.exportSymbol('proto.grammar_games_protos.GetQuesLineResp', null, global);
goog.exportSymbol('proto.grammar_games_protos.MultiAnswer', null, global);
goog.exportSymbol('proto.grammar_games_protos.QuestionLine', null, global);
goog.exportSymbol('proto.grammar_games_protos.ReportAnswerReq', null, global);
goog.exportSymbol('proto.grammar_games_protos.ReportAnswerResp', null, global);
goog.exportSymbol('proto.grammar_games_protos.SentenceWord', null, global);
goog.exportSymbol('proto.grammar_games_protos.SetGameLevelReq', null, global);
goog.exportSymbol('proto.grammar_games_protos.SetGameLevelResp', null, global);
goog.exportSymbol('proto.grammar_games_protos.SetPlayerReq', null, global);
goog.exportSymbol('proto.grammar_games_protos.SetPlayerResp', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grammar_games_protos.SetPlayerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grammar_games_protos.SetPlayerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grammar_games_protos.SetPlayerReq.displayName = 'proto.grammar_games_protos.SetPlayerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grammar_games_protos.SetPlayerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grammar_games_protos.SetPlayerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grammar_games_protos.SetPlayerResp.displayName = 'proto.grammar_games_protos.SetPlayerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grammar_games_protos.SetGameLevelReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grammar_games_protos.SetGameLevelReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grammar_games_protos.SetGameLevelReq.displayName = 'proto.grammar_games_protos.SetGameLevelReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grammar_games_protos.SetGameLevelResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grammar_games_protos.SetGameLevelResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grammar_games_protos.SetGameLevelResp.displayName = 'proto.grammar_games_protos.SetGameLevelResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grammar_games_protos.GetQuesLineReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grammar_games_protos.GetQuesLineReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grammar_games_protos.GetQuesLineReq.displayName = 'proto.grammar_games_protos.GetQuesLineReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grammar_games_protos.GetQuesLineResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grammar_games_protos.GetQuesLineResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grammar_games_protos.GetQuesLineResp.displayName = 'proto.grammar_games_protos.GetQuesLineResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grammar_games_protos.ReportAnswerReq = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grammar_games_protos.ReportAnswerReq, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grammar_games_protos.ReportAnswerReq.displayName = 'proto.grammar_games_protos.ReportAnswerReq';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grammar_games_protos.ReportAnswerResp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grammar_games_protos.ReportAnswerResp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grammar_games_protos.ReportAnswerResp.displayName = 'proto.grammar_games_protos.ReportAnswerResp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grammar_games_protos.QuestionLine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grammar_games_protos.QuestionLine.repeatedFields_, null);
};
goog.inherits(proto.grammar_games_protos.QuestionLine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grammar_games_protos.QuestionLine.displayName = 'proto.grammar_games_protos.QuestionLine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grammar_games_protos.GameQuestion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grammar_games_protos.GameQuestion.repeatedFields_, null);
};
goog.inherits(proto.grammar_games_protos.GameQuestion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grammar_games_protos.GameQuestion.displayName = 'proto.grammar_games_protos.GameQuestion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grammar_games_protos.MultiAnswer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grammar_games_protos.MultiAnswer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grammar_games_protos.MultiAnswer.displayName = 'proto.grammar_games_protos.MultiAnswer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grammar_games_protos.SentenceWord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grammar_games_protos.SentenceWord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grammar_games_protos.SentenceWord.displayName = 'proto.grammar_games_protos.SentenceWord';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grammar_games_protos.SetPlayerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.grammar_games_protos.SetPlayerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grammar_games_protos.SetPlayerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.SetPlayerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accessKey: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grammar_games_protos.SetPlayerReq}
 */
proto.grammar_games_protos.SetPlayerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grammar_games_protos.SetPlayerReq;
  return proto.grammar_games_protos.SetPlayerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grammar_games_protos.SetPlayerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grammar_games_protos.SetPlayerReq}
 */
proto.grammar_games_protos.SetPlayerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccessKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grammar_games_protos.SetPlayerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grammar_games_protos.SetPlayerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grammar_games_protos.SetPlayerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.SetPlayerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccessKey();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string player_id = 1;
 * @return {string}
 */
proto.grammar_games_protos.SetPlayerReq.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grammar_games_protos.SetPlayerReq} returns this
 */
proto.grammar_games_protos.SetPlayerReq.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 access_key = 2;
 * @return {number}
 */
proto.grammar_games_protos.SetPlayerReq.prototype.getAccessKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.SetPlayerReq} returns this
 */
proto.grammar_games_protos.SetPlayerReq.prototype.setAccessKey = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grammar_games_protos.SetPlayerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.grammar_games_protos.SetPlayerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grammar_games_protos.SetPlayerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.SetPlayerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accessKey: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grammar_games_protos.SetPlayerResp}
 */
proto.grammar_games_protos.SetPlayerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grammar_games_protos.SetPlayerResp;
  return proto.grammar_games_protos.SetPlayerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grammar_games_protos.SetPlayerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grammar_games_protos.SetPlayerResp}
 */
proto.grammar_games_protos.SetPlayerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccessKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grammar_games_protos.SetPlayerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grammar_games_protos.SetPlayerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grammar_games_protos.SetPlayerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.SetPlayerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccessKey();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string player_id = 1;
 * @return {string}
 */
proto.grammar_games_protos.SetPlayerResp.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grammar_games_protos.SetPlayerResp} returns this
 */
proto.grammar_games_protos.SetPlayerResp.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 access_key = 2;
 * @return {number}
 */
proto.grammar_games_protos.SetPlayerResp.prototype.getAccessKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.SetPlayerResp} returns this
 */
proto.grammar_games_protos.SetPlayerResp.prototype.setAccessKey = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grammar_games_protos.SetGameLevelReq.prototype.toObject = function(opt_includeInstance) {
  return proto.grammar_games_protos.SetGameLevelReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grammar_games_protos.SetGameLevelReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.SetGameLevelReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accessKey: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gameType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    gameLevel: jspb.Message.getFieldWithDefault(msg, 4, 0),
    restart: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grammar_games_protos.SetGameLevelReq}
 */
proto.grammar_games_protos.SetGameLevelReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grammar_games_protos.SetGameLevelReq;
  return proto.grammar_games_protos.SetGameLevelReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grammar_games_protos.SetGameLevelReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grammar_games_protos.SetGameLevelReq}
 */
proto.grammar_games_protos.SetGameLevelReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccessKey(value);
      break;
    case 3:
      var value = /** @type {!proto.grammar_games_protos.GameType} */ (reader.readEnum());
      msg.setGameType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGameLevel(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRestart(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grammar_games_protos.SetGameLevelReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grammar_games_protos.SetGameLevelReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grammar_games_protos.SetGameLevelReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.SetGameLevelReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccessKey();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getGameType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getGameLevel();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getRestart();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional string player_id = 1;
 * @return {string}
 */
proto.grammar_games_protos.SetGameLevelReq.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grammar_games_protos.SetGameLevelReq} returns this
 */
proto.grammar_games_protos.SetGameLevelReq.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 access_key = 2;
 * @return {number}
 */
proto.grammar_games_protos.SetGameLevelReq.prototype.getAccessKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.SetGameLevelReq} returns this
 */
proto.grammar_games_protos.SetGameLevelReq.prototype.setAccessKey = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional GameType game_type = 3;
 * @return {!proto.grammar_games_protos.GameType}
 */
proto.grammar_games_protos.SetGameLevelReq.prototype.getGameType = function() {
  return /** @type {!proto.grammar_games_protos.GameType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.grammar_games_protos.GameType} value
 * @return {!proto.grammar_games_protos.SetGameLevelReq} returns this
 */
proto.grammar_games_protos.SetGameLevelReq.prototype.setGameType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 game_level = 4;
 * @return {number}
 */
proto.grammar_games_protos.SetGameLevelReq.prototype.getGameLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.SetGameLevelReq} returns this
 */
proto.grammar_games_protos.SetGameLevelReq.prototype.setGameLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool restart = 5;
 * @return {boolean}
 */
proto.grammar_games_protos.SetGameLevelReq.prototype.getRestart = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grammar_games_protos.SetGameLevelReq} returns this
 */
proto.grammar_games_protos.SetGameLevelReq.prototype.setRestart = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grammar_games_protos.SetGameLevelResp.prototype.toObject = function(opt_includeInstance) {
  return proto.grammar_games_protos.SetGameLevelResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grammar_games_protos.SetGameLevelResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.SetGameLevelResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gameType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gameLevel: jspb.Message.getFieldWithDefault(msg, 3, 0),
    refreshPlayer: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    errCode: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grammar_games_protos.SetGameLevelResp}
 */
proto.grammar_games_protos.SetGameLevelResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grammar_games_protos.SetGameLevelResp;
  return proto.grammar_games_protos.SetGameLevelResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grammar_games_protos.SetGameLevelResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grammar_games_protos.SetGameLevelResp}
 */
proto.grammar_games_protos.SetGameLevelResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 2:
      var value = /** @type {!proto.grammar_games_protos.GameType} */ (reader.readEnum());
      msg.setGameType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGameLevel(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRefreshPlayer(value);
      break;
    case 5:
      var value = /** @type {!proto.grammar_games_protos.GramGamesErrorCode} */ (reader.readEnum());
      msg.setErrCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grammar_games_protos.SetGameLevelResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grammar_games_protos.SetGameLevelResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grammar_games_protos.SetGameLevelResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.SetGameLevelResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGameType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getGameLevel();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getRefreshPlayer();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getErrCode();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional string player_id = 1;
 * @return {string}
 */
proto.grammar_games_protos.SetGameLevelResp.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grammar_games_protos.SetGameLevelResp} returns this
 */
proto.grammar_games_protos.SetGameLevelResp.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GameType game_type = 2;
 * @return {!proto.grammar_games_protos.GameType}
 */
proto.grammar_games_protos.SetGameLevelResp.prototype.getGameType = function() {
  return /** @type {!proto.grammar_games_protos.GameType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.grammar_games_protos.GameType} value
 * @return {!proto.grammar_games_protos.SetGameLevelResp} returns this
 */
proto.grammar_games_protos.SetGameLevelResp.prototype.setGameType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 game_level = 3;
 * @return {number}
 */
proto.grammar_games_protos.SetGameLevelResp.prototype.getGameLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.SetGameLevelResp} returns this
 */
proto.grammar_games_protos.SetGameLevelResp.prototype.setGameLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool refresh_player = 4;
 * @return {boolean}
 */
proto.grammar_games_protos.SetGameLevelResp.prototype.getRefreshPlayer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grammar_games_protos.SetGameLevelResp} returns this
 */
proto.grammar_games_protos.SetGameLevelResp.prototype.setRefreshPlayer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional GramGamesErrorCode err_code = 5;
 * @return {!proto.grammar_games_protos.GramGamesErrorCode}
 */
proto.grammar_games_protos.SetGameLevelResp.prototype.getErrCode = function() {
  return /** @type {!proto.grammar_games_protos.GramGamesErrorCode} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.grammar_games_protos.GramGamesErrorCode} value
 * @return {!proto.grammar_games_protos.SetGameLevelResp} returns this
 */
proto.grammar_games_protos.SetGameLevelResp.prototype.setErrCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grammar_games_protos.GetQuesLineReq.prototype.toObject = function(opt_includeInstance) {
  return proto.grammar_games_protos.GetQuesLineReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grammar_games_protos.GetQuesLineReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.GetQuesLineReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accessKey: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gameType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    gameLevel: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grammar_games_protos.GetQuesLineReq}
 */
proto.grammar_games_protos.GetQuesLineReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grammar_games_protos.GetQuesLineReq;
  return proto.grammar_games_protos.GetQuesLineReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grammar_games_protos.GetQuesLineReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grammar_games_protos.GetQuesLineReq}
 */
proto.grammar_games_protos.GetQuesLineReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccessKey(value);
      break;
    case 3:
      var value = /** @type {!proto.grammar_games_protos.GameType} */ (reader.readEnum());
      msg.setGameType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGameLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grammar_games_protos.GetQuesLineReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grammar_games_protos.GetQuesLineReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grammar_games_protos.GetQuesLineReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.GetQuesLineReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccessKey();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getGameType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getGameLevel();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional string player_id = 1;
 * @return {string}
 */
proto.grammar_games_protos.GetQuesLineReq.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grammar_games_protos.GetQuesLineReq} returns this
 */
proto.grammar_games_protos.GetQuesLineReq.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 access_key = 2;
 * @return {number}
 */
proto.grammar_games_protos.GetQuesLineReq.prototype.getAccessKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.GetQuesLineReq} returns this
 */
proto.grammar_games_protos.GetQuesLineReq.prototype.setAccessKey = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional GameType game_type = 3;
 * @return {!proto.grammar_games_protos.GameType}
 */
proto.grammar_games_protos.GetQuesLineReq.prototype.getGameType = function() {
  return /** @type {!proto.grammar_games_protos.GameType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.grammar_games_protos.GameType} value
 * @return {!proto.grammar_games_protos.GetQuesLineReq} returns this
 */
proto.grammar_games_protos.GetQuesLineReq.prototype.setGameType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 game_level = 4;
 * @return {number}
 */
proto.grammar_games_protos.GetQuesLineReq.prototype.getGameLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.GetQuesLineReq} returns this
 */
proto.grammar_games_protos.GetQuesLineReq.prototype.setGameLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.toObject = function(opt_includeInstance) {
  return proto.grammar_games_protos.GetQuesLineResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grammar_games_protos.GetQuesLineResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.GetQuesLineResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gameType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gameLevel: jspb.Message.getFieldWithDefault(msg, 3, 0),
    progressPercent: jspb.Message.getFieldWithDefault(msg, 4, 0),
    refreshPlayer: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    errCode: jspb.Message.getFieldWithDefault(msg, 6, 0),
    questionLine: (f = msg.getQuestionLine()) && proto.grammar_games_protos.QuestionLine.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grammar_games_protos.GetQuesLineResp}
 */
proto.grammar_games_protos.GetQuesLineResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grammar_games_protos.GetQuesLineResp;
  return proto.grammar_games_protos.GetQuesLineResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grammar_games_protos.GetQuesLineResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grammar_games_protos.GetQuesLineResp}
 */
proto.grammar_games_protos.GetQuesLineResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 2:
      var value = /** @type {!proto.grammar_games_protos.GameType} */ (reader.readEnum());
      msg.setGameType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGameLevel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgressPercent(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRefreshPlayer(value);
      break;
    case 6:
      var value = /** @type {!proto.grammar_games_protos.GramGamesErrorCode} */ (reader.readEnum());
      msg.setErrCode(value);
      break;
    case 7:
      var value = new proto.grammar_games_protos.QuestionLine;
      reader.readMessage(value,proto.grammar_games_protos.QuestionLine.deserializeBinaryFromReader);
      msg.setQuestionLine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grammar_games_protos.GetQuesLineResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grammar_games_protos.GetQuesLineResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.GetQuesLineResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGameType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getGameLevel();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getProgressPercent();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getRefreshPlayer();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getErrCode();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getQuestionLine();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.grammar_games_protos.QuestionLine.serializeBinaryToWriter
    );
  }
};


/**
 * optional string player_id = 1;
 * @return {string}
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grammar_games_protos.GetQuesLineResp} returns this
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GameType game_type = 2;
 * @return {!proto.grammar_games_protos.GameType}
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.getGameType = function() {
  return /** @type {!proto.grammar_games_protos.GameType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.grammar_games_protos.GameType} value
 * @return {!proto.grammar_games_protos.GetQuesLineResp} returns this
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.setGameType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 game_level = 3;
 * @return {number}
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.getGameLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.GetQuesLineResp} returns this
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.setGameLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 progress_percent = 4;
 * @return {number}
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.getProgressPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.GetQuesLineResp} returns this
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.setProgressPercent = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool refresh_player = 5;
 * @return {boolean}
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.getRefreshPlayer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grammar_games_protos.GetQuesLineResp} returns this
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.setRefreshPlayer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional GramGamesErrorCode err_code = 6;
 * @return {!proto.grammar_games_protos.GramGamesErrorCode}
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.getErrCode = function() {
  return /** @type {!proto.grammar_games_protos.GramGamesErrorCode} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.grammar_games_protos.GramGamesErrorCode} value
 * @return {!proto.grammar_games_protos.GetQuesLineResp} returns this
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.setErrCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional QuestionLine question_line = 7;
 * @return {?proto.grammar_games_protos.QuestionLine}
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.getQuestionLine = function() {
  return /** @type{?proto.grammar_games_protos.QuestionLine} */ (
    jspb.Message.getWrapperField(this, proto.grammar_games_protos.QuestionLine, 7));
};


/**
 * @param {?proto.grammar_games_protos.QuestionLine|undefined} value
 * @return {!proto.grammar_games_protos.GetQuesLineResp} returns this
*/
proto.grammar_games_protos.GetQuesLineResp.prototype.setQuestionLine = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grammar_games_protos.GetQuesLineResp} returns this
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.clearQuestionLine = function() {
  return this.setQuestionLine(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grammar_games_protos.GetQuesLineResp.prototype.hasQuestionLine = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.toObject = function(opt_includeInstance) {
  return proto.grammar_games_protos.ReportAnswerReq.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grammar_games_protos.ReportAnswerReq} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.ReportAnswerReq.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accessKey: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gameType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    gameLevel: jspb.Message.getFieldWithDefault(msg, 4, 0),
    questionLineId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    gameQuesType: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isCorrect: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grammar_games_protos.ReportAnswerReq}
 */
proto.grammar_games_protos.ReportAnswerReq.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grammar_games_protos.ReportAnswerReq;
  return proto.grammar_games_protos.ReportAnswerReq.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grammar_games_protos.ReportAnswerReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grammar_games_protos.ReportAnswerReq}
 */
proto.grammar_games_protos.ReportAnswerReq.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccessKey(value);
      break;
    case 3:
      var value = /** @type {!proto.grammar_games_protos.GameType} */ (reader.readEnum());
      msg.setGameType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGameLevel(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setQuestionLineId(value);
      break;
    case 6:
      var value = /** @type {!proto.grammar_games_protos.GameQuestionType} */ (reader.readEnum());
      msg.setGameQuesType(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCorrect(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grammar_games_protos.ReportAnswerReq.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grammar_games_protos.ReportAnswerReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.ReportAnswerReq.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccessKey();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getGameType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getGameLevel();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getQuestionLineId();
  if (f !== 0) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getGameQuesType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getIsCorrect();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string player_id = 1;
 * @return {string}
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grammar_games_protos.ReportAnswerReq} returns this
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 access_key = 2;
 * @return {number}
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.getAccessKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.ReportAnswerReq} returns this
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.setAccessKey = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional GameType game_type = 3;
 * @return {!proto.grammar_games_protos.GameType}
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.getGameType = function() {
  return /** @type {!proto.grammar_games_protos.GameType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.grammar_games_protos.GameType} value
 * @return {!proto.grammar_games_protos.ReportAnswerReq} returns this
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.setGameType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 game_level = 4;
 * @return {number}
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.getGameLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.ReportAnswerReq} returns this
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.setGameLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint64 question_line_id = 5;
 * @return {number}
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.getQuestionLineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.ReportAnswerReq} returns this
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.setQuestionLineId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional GameQuestionType game_ques_type = 6;
 * @return {!proto.grammar_games_protos.GameQuestionType}
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.getGameQuesType = function() {
  return /** @type {!proto.grammar_games_protos.GameQuestionType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.grammar_games_protos.GameQuestionType} value
 * @return {!proto.grammar_games_protos.ReportAnswerReq} returns this
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.setGameQuesType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional bool is_correct = 7;
 * @return {boolean}
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.getIsCorrect = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grammar_games_protos.ReportAnswerReq} returns this
 */
proto.grammar_games_protos.ReportAnswerReq.prototype.setIsCorrect = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.toObject = function(opt_includeInstance) {
  return proto.grammar_games_protos.ReportAnswerResp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grammar_games_protos.ReportAnswerResp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.ReportAnswerResp.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gameType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gameLevel: jspb.Message.getFieldWithDefault(msg, 3, 0),
    questionLineId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gameQuesType: jspb.Message.getFieldWithDefault(msg, 5, 0),
    progressPercent: jspb.Message.getFieldWithDefault(msg, 6, 0),
    refreshPlayer: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    errCode: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grammar_games_protos.ReportAnswerResp}
 */
proto.grammar_games_protos.ReportAnswerResp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grammar_games_protos.ReportAnswerResp;
  return proto.grammar_games_protos.ReportAnswerResp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grammar_games_protos.ReportAnswerResp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grammar_games_protos.ReportAnswerResp}
 */
proto.grammar_games_protos.ReportAnswerResp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerId(value);
      break;
    case 2:
      var value = /** @type {!proto.grammar_games_protos.GameType} */ (reader.readEnum());
      msg.setGameType(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGameLevel(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setQuestionLineId(value);
      break;
    case 5:
      var value = /** @type {!proto.grammar_games_protos.GameQuestionType} */ (reader.readEnum());
      msg.setGameQuesType(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProgressPercent(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRefreshPlayer(value);
      break;
    case 8:
      var value = /** @type {!proto.grammar_games_protos.GramGamesErrorCode} */ (reader.readEnum());
      msg.setErrCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grammar_games_protos.ReportAnswerResp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grammar_games_protos.ReportAnswerResp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.ReportAnswerResp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGameType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getGameLevel();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getQuestionLineId();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = message.getGameQuesType();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getProgressPercent();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getRefreshPlayer();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getErrCode();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string player_id = 1;
 * @return {string}
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.getPlayerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grammar_games_protos.ReportAnswerResp} returns this
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.setPlayerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GameType game_type = 2;
 * @return {!proto.grammar_games_protos.GameType}
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.getGameType = function() {
  return /** @type {!proto.grammar_games_protos.GameType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.grammar_games_protos.GameType} value
 * @return {!proto.grammar_games_protos.ReportAnswerResp} returns this
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.setGameType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 game_level = 3;
 * @return {number}
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.getGameLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.ReportAnswerResp} returns this
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.setGameLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 question_line_id = 4;
 * @return {number}
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.getQuestionLineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.ReportAnswerResp} returns this
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.setQuestionLineId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional GameQuestionType game_ques_type = 5;
 * @return {!proto.grammar_games_protos.GameQuestionType}
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.getGameQuesType = function() {
  return /** @type {!proto.grammar_games_protos.GameQuestionType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.grammar_games_protos.GameQuestionType} value
 * @return {!proto.grammar_games_protos.ReportAnswerResp} returns this
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.setGameQuesType = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional uint32 progress_percent = 6;
 * @return {number}
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.getProgressPercent = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.ReportAnswerResp} returns this
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.setProgressPercent = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool refresh_player = 7;
 * @return {boolean}
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.getRefreshPlayer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grammar_games_protos.ReportAnswerResp} returns this
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.setRefreshPlayer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional GramGamesErrorCode err_code = 8;
 * @return {!proto.grammar_games_protos.GramGamesErrorCode}
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.getErrCode = function() {
  return /** @type {!proto.grammar_games_protos.GramGamesErrorCode} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.grammar_games_protos.GramGamesErrorCode} value
 * @return {!proto.grammar_games_protos.ReportAnswerResp} returns this
 */
proto.grammar_games_protos.ReportAnswerResp.prototype.setErrCode = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grammar_games_protos.QuestionLine.repeatedFields_ = [5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grammar_games_protos.QuestionLine.prototype.toObject = function(opt_includeInstance) {
  return proto.grammar_games_protos.QuestionLine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grammar_games_protos.QuestionLine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.QuestionLine.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionLineId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sentPatternId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gameType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    gameLevel: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sentWordsList: jspb.Message.toObjectList(msg.getSentWordsList(),
    proto.grammar_games_protos.SentenceWord.toObject, includeInstance),
    gameQuestionsList: jspb.Message.toObjectList(msg.getGameQuestionsList(),
    proto.grammar_games_protos.GameQuestion.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grammar_games_protos.QuestionLine}
 */
proto.grammar_games_protos.QuestionLine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grammar_games_protos.QuestionLine;
  return proto.grammar_games_protos.QuestionLine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grammar_games_protos.QuestionLine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grammar_games_protos.QuestionLine}
 */
proto.grammar_games_protos.QuestionLine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuestionLineId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSentPatternId(value);
      break;
    case 3:
      var value = /** @type {!proto.grammar_games_protos.GameType} */ (reader.readEnum());
      msg.setGameType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGameLevel(value);
      break;
    case 5:
      var value = new proto.grammar_games_protos.SentenceWord;
      reader.readMessage(value,proto.grammar_games_protos.SentenceWord.deserializeBinaryFromReader);
      msg.addSentWords(value);
      break;
    case 6:
      var value = new proto.grammar_games_protos.GameQuestion;
      reader.readMessage(value,proto.grammar_games_protos.GameQuestion.deserializeBinaryFromReader);
      msg.addGameQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grammar_games_protos.QuestionLine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grammar_games_protos.QuestionLine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grammar_games_protos.QuestionLine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.QuestionLine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionLineId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSentPatternId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getGameType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getGameLevel();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getSentWordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.grammar_games_protos.SentenceWord.serializeBinaryToWriter
    );
  }
  f = message.getGameQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.grammar_games_protos.GameQuestion.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 question_line_id = 1;
 * @return {number}
 */
proto.grammar_games_protos.QuestionLine.prototype.getQuestionLineId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.QuestionLine} returns this
 */
proto.grammar_games_protos.QuestionLine.prototype.setQuestionLineId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 sent_pattern_id = 2;
 * @return {number}
 */
proto.grammar_games_protos.QuestionLine.prototype.getSentPatternId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.QuestionLine} returns this
 */
proto.grammar_games_protos.QuestionLine.prototype.setSentPatternId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional GameType game_type = 3;
 * @return {!proto.grammar_games_protos.GameType}
 */
proto.grammar_games_protos.QuestionLine.prototype.getGameType = function() {
  return /** @type {!proto.grammar_games_protos.GameType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.grammar_games_protos.GameType} value
 * @return {!proto.grammar_games_protos.QuestionLine} returns this
 */
proto.grammar_games_protos.QuestionLine.prototype.setGameType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 game_level = 4;
 * @return {number}
 */
proto.grammar_games_protos.QuestionLine.prototype.getGameLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.QuestionLine} returns this
 */
proto.grammar_games_protos.QuestionLine.prototype.setGameLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated SentenceWord sent_words = 5;
 * @return {!Array<!proto.grammar_games_protos.SentenceWord>}
 */
proto.grammar_games_protos.QuestionLine.prototype.getSentWordsList = function() {
  return /** @type{!Array<!proto.grammar_games_protos.SentenceWord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grammar_games_protos.SentenceWord, 5));
};


/**
 * @param {!Array<!proto.grammar_games_protos.SentenceWord>} value
 * @return {!proto.grammar_games_protos.QuestionLine} returns this
*/
proto.grammar_games_protos.QuestionLine.prototype.setSentWordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.grammar_games_protos.SentenceWord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grammar_games_protos.SentenceWord}
 */
proto.grammar_games_protos.QuestionLine.prototype.addSentWords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.grammar_games_protos.SentenceWord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grammar_games_protos.QuestionLine} returns this
 */
proto.grammar_games_protos.QuestionLine.prototype.clearSentWordsList = function() {
  return this.setSentWordsList([]);
};


/**
 * repeated GameQuestion game_questions = 6;
 * @return {!Array<!proto.grammar_games_protos.GameQuestion>}
 */
proto.grammar_games_protos.QuestionLine.prototype.getGameQuestionsList = function() {
  return /** @type{!Array<!proto.grammar_games_protos.GameQuestion>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grammar_games_protos.GameQuestion, 6));
};


/**
 * @param {!Array<!proto.grammar_games_protos.GameQuestion>} value
 * @return {!proto.grammar_games_protos.QuestionLine} returns this
*/
proto.grammar_games_protos.QuestionLine.prototype.setGameQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.grammar_games_protos.GameQuestion=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grammar_games_protos.GameQuestion}
 */
proto.grammar_games_protos.QuestionLine.prototype.addGameQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.grammar_games_protos.GameQuestion, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grammar_games_protos.QuestionLine} returns this
 */
proto.grammar_games_protos.QuestionLine.prototype.clearGameQuestionsList = function() {
  return this.setGameQuestionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grammar_games_protos.GameQuestion.repeatedFields_ = [4,6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grammar_games_protos.GameQuestion.prototype.toObject = function(opt_includeInstance) {
  return proto.grammar_games_protos.GameQuestion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grammar_games_protos.GameQuestion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.GameQuestion.toObject = function(includeInstance, msg) {
  var f, obj = {
    gameQuestionType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    questionType: jspb.Message.getFieldWithDefault(msg, 2, 0),
    booleanAnswer: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    multiAnswersList: jspb.Message.toObjectList(msg.getMultiAnswersList(),
    proto.grammar_games_protos.MultiAnswer.toObject, includeInstance),
    multiAnswerInd: jspb.Message.getFieldWithDefault(msg, 5, 0),
    highlightWordsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    inputWordsList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grammar_games_protos.GameQuestion}
 */
proto.grammar_games_protos.GameQuestion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grammar_games_protos.GameQuestion;
  return proto.grammar_games_protos.GameQuestion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grammar_games_protos.GameQuestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grammar_games_protos.GameQuestion}
 */
proto.grammar_games_protos.GameQuestion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.grammar_games_protos.GameQuestionType} */ (reader.readEnum());
      msg.setGameQuestionType(value);
      break;
    case 2:
      var value = /** @type {!proto.grammar_games_protos.QuestionType} */ (reader.readEnum());
      msg.setQuestionType(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBooleanAnswer(value);
      break;
    case 4:
      var value = new proto.grammar_games_protos.MultiAnswer;
      reader.readMessage(value,proto.grammar_games_protos.MultiAnswer.deserializeBinaryFromReader);
      msg.addMultiAnswers(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMultiAnswerInd(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addHighlightWords(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addInputWords(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grammar_games_protos.GameQuestion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grammar_games_protos.GameQuestion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grammar_games_protos.GameQuestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.GameQuestion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGameQuestionType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getQuestionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getBooleanAnswer();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMultiAnswersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.grammar_games_protos.MultiAnswer.serializeBinaryToWriter
    );
  }
  f = message.getMultiAnswerInd();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getHighlightWordsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      6,
      f
    );
  }
  f = message.getInputWordsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      7,
      f
    );
  }
};


/**
 * optional GameQuestionType game_question_type = 1;
 * @return {!proto.grammar_games_protos.GameQuestionType}
 */
proto.grammar_games_protos.GameQuestion.prototype.getGameQuestionType = function() {
  return /** @type {!proto.grammar_games_protos.GameQuestionType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.grammar_games_protos.GameQuestionType} value
 * @return {!proto.grammar_games_protos.GameQuestion} returns this
 */
proto.grammar_games_protos.GameQuestion.prototype.setGameQuestionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional QuestionType question_type = 2;
 * @return {!proto.grammar_games_protos.QuestionType}
 */
proto.grammar_games_protos.GameQuestion.prototype.getQuestionType = function() {
  return /** @type {!proto.grammar_games_protos.QuestionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.grammar_games_protos.QuestionType} value
 * @return {!proto.grammar_games_protos.GameQuestion} returns this
 */
proto.grammar_games_protos.GameQuestion.prototype.setQuestionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool boolean_answer = 3;
 * @return {boolean}
 */
proto.grammar_games_protos.GameQuestion.prototype.getBooleanAnswer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grammar_games_protos.GameQuestion} returns this
 */
proto.grammar_games_protos.GameQuestion.prototype.setBooleanAnswer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated MultiAnswer multi_answers = 4;
 * @return {!Array<!proto.grammar_games_protos.MultiAnswer>}
 */
proto.grammar_games_protos.GameQuestion.prototype.getMultiAnswersList = function() {
  return /** @type{!Array<!proto.grammar_games_protos.MultiAnswer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grammar_games_protos.MultiAnswer, 4));
};


/**
 * @param {!Array<!proto.grammar_games_protos.MultiAnswer>} value
 * @return {!proto.grammar_games_protos.GameQuestion} returns this
*/
proto.grammar_games_protos.GameQuestion.prototype.setMultiAnswersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.grammar_games_protos.MultiAnswer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grammar_games_protos.MultiAnswer}
 */
proto.grammar_games_protos.GameQuestion.prototype.addMultiAnswers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.grammar_games_protos.MultiAnswer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grammar_games_protos.GameQuestion} returns this
 */
proto.grammar_games_protos.GameQuestion.prototype.clearMultiAnswersList = function() {
  return this.setMultiAnswersList([]);
};


/**
 * optional uint32 multi_answer_ind = 5;
 * @return {number}
 */
proto.grammar_games_protos.GameQuestion.prototype.getMultiAnswerInd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.GameQuestion} returns this
 */
proto.grammar_games_protos.GameQuestion.prototype.setMultiAnswerInd = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated uint32 highlight_words = 6;
 * @return {!Array<number>}
 */
proto.grammar_games_protos.GameQuestion.prototype.getHighlightWordsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grammar_games_protos.GameQuestion} returns this
 */
proto.grammar_games_protos.GameQuestion.prototype.setHighlightWordsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grammar_games_protos.GameQuestion} returns this
 */
proto.grammar_games_protos.GameQuestion.prototype.addHighlightWords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grammar_games_protos.GameQuestion} returns this
 */
proto.grammar_games_protos.GameQuestion.prototype.clearHighlightWordsList = function() {
  return this.setHighlightWordsList([]);
};


/**
 * repeated uint32 input_words = 7;
 * @return {!Array<number>}
 */
proto.grammar_games_protos.GameQuestion.prototype.getInputWordsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grammar_games_protos.GameQuestion} returns this
 */
proto.grammar_games_protos.GameQuestion.prototype.setInputWordsList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grammar_games_protos.GameQuestion} returns this
 */
proto.grammar_games_protos.GameQuestion.prototype.addInputWords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grammar_games_protos.GameQuestion} returns this
 */
proto.grammar_games_protos.GameQuestion.prototype.clearInputWordsList = function() {
  return this.setInputWordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grammar_games_protos.MultiAnswer.prototype.toObject = function(opt_includeInstance) {
  return proto.grammar_games_protos.MultiAnswer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grammar_games_protos.MultiAnswer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.MultiAnswer.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: jspb.Message.getFieldWithDefault(msg, 1, ""),
    intValue: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grammar_games_protos.MultiAnswer}
 */
proto.grammar_games_protos.MultiAnswer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grammar_games_protos.MultiAnswer;
  return proto.grammar_games_protos.MultiAnswer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grammar_games_protos.MultiAnswer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grammar_games_protos.MultiAnswer}
 */
proto.grammar_games_protos.MultiAnswer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setIntValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grammar_games_protos.MultiAnswer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grammar_games_protos.MultiAnswer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grammar_games_protos.MultiAnswer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.MultiAnswer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIntValue();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.grammar_games_protos.MultiAnswer.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grammar_games_protos.MultiAnswer} returns this
 */
proto.grammar_games_protos.MultiAnswer.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 int_value = 2;
 * @return {number}
 */
proto.grammar_games_protos.MultiAnswer.prototype.getIntValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grammar_games_protos.MultiAnswer} returns this
 */
proto.grammar_games_protos.MultiAnswer.prototype.setIntValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grammar_games_protos.SentenceWord.prototype.toObject = function(opt_includeInstance) {
  return proto.grammar_games_protos.SentenceWord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grammar_games_protos.SentenceWord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.SentenceWord.toObject = function(includeInstance, msg) {
  var f, obj = {
    word: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modWord: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isPunctuation: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grammar_games_protos.SentenceWord}
 */
proto.grammar_games_protos.SentenceWord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grammar_games_protos.SentenceWord;
  return proto.grammar_games_protos.SentenceWord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grammar_games_protos.SentenceWord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grammar_games_protos.SentenceWord}
 */
proto.grammar_games_protos.SentenceWord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setWord(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModWord(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPunctuation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grammar_games_protos.SentenceWord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grammar_games_protos.SentenceWord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grammar_games_protos.SentenceWord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grammar_games_protos.SentenceWord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getWord();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModWord();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsPunctuation();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string word = 1;
 * @return {string}
 */
proto.grammar_games_protos.SentenceWord.prototype.getWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grammar_games_protos.SentenceWord} returns this
 */
proto.grammar_games_protos.SentenceWord.prototype.setWord = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mod_word = 2;
 * @return {string}
 */
proto.grammar_games_protos.SentenceWord.prototype.getModWord = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grammar_games_protos.SentenceWord} returns this
 */
proto.grammar_games_protos.SentenceWord.prototype.setModWord = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_punctuation = 3;
 * @return {boolean}
 */
proto.grammar_games_protos.SentenceWord.prototype.getIsPunctuation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grammar_games_protos.SentenceWord} returns this
 */
proto.grammar_games_protos.SentenceWord.prototype.setIsPunctuation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


goog.object.extend(exports, proto.grammar_games_protos);
