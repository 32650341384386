
const gameHelpMap = new Map();

const initGameHelpMap = () =>  {
	gameHelpEntries.forEach(helpItem => {
		gameHelpMap.set(helpItem.answerStr, helpItem)
	}) 
}

export const getGameHelpId = (answerStr) => {

	if (gameHelpMap.size === 0) {
		initGameHelpMap()
	}

	return gameHelpMap.get(answerStr).helpId
}

export const gameHelpEntries = [

	  {
	  		helpId: 1, 
	  		helpCategory: 5, 
	  		title: 'Simple Sentence',
	  		answerStr: 'Simple',
	  		description: `A simple sentence has one independent clause and no dependent clauses.`,
	  		examples: ['The cat jumped onto the table.<br>',
	  				   'The issue was complex and difficult to understand.<br>',
	  				  ],
	  },

	  {
	  		helpId: 2, 
	  		helpCategory: 5, 
	  		title: 'Compound Sentence',
	  		answerStr: 'Compound',
	  		description: `A compound sentence has two or more independent clauses and no dependent clauses.<br><br>
	  					  In the examples below, each sentence has two independent clauses. The first one is 
	  					  underlined and the second is highlighted in italics.`,
	  		examples: ['<u>His father is very wise</u>, so <i>I welcomed his advice</i>.<br>',
	  				   '<u>Did you solve that problem yourself</u> or <i>did someone help you</i>?<br>',
	  				   '<u>Their dog continually chased the cat</u>, but <i>he never caught her</i>.<br>',
	  				  ],
	  },

	  {
	  		helpId: 3, 
	  		helpCategory: 5, 
	  		title: 'Complex Sentence',
	  		answerStr: 'Complex',
	  		description: `A complex sentence has only one independent clause and one or more dependent clauses.<br><br>
	  					  In the examples below, the full independent clause is underlined and the dependent clause
	  					  is highlighted in bold.`,
	  		examples: ['<u>We got home late <b>because there was a lot of traffic.</b></u><br>',
	  				   '<u>The boy <b>who was walking down the street</b> tripped and fell.</u>',
	  				  ],
	  },

	  {
	  		helpId: 4, 
	  		helpCategory: 5, 
	  		title: 'Compound/Complex Sentence',
	  		answerStr: 'Compound/Complex',
	  		description: `A compound/complex sentence has two or more independent clauses and one or more dependent clauses.<br><br>
	  					  In the examples below, the independent clauses are underlined and the dependent clauses are highlighted
	  					  in bold.`,
	  		examples: ['<u>We wanted to get some ice cream</u>, but <u>we couldn\'t <b>because the shop was closed</b></u>.<br>',
	  				   '<u>The flowers <b>they gave me</b> were beautiful</u>, so <u>I put them in a vase on the table</u>.<br>',
	  				  ],
	  },

	  {
	  		helpId: 5, 
	  		helpCategory: 5, 
	  		title: 'Declarative Sentence',
	  		answerStr: 'Declarative',
	  		description: `A declarative sentence expresses a statement or idea.`,
	  		examples: ['Tomorrow is Sunday.<br>',
	  				   'They stopped talking when the teacher came into the room.<br>',
	  				   'Everyone knows the correct answer.<br>',
	  				  ],
	  },

	  {
	  		helpId: 6, 
	  		helpCategory: 5, 
	  		title: 'Imperative Sentence',
	  		answerStr: 'Imperative',
	  		description: `An imperative sentence expresses a command, a request, or advice. There is no subject in 
	  					  the sentence because the subject is implied to be 'you' - i.e. the person the speaker
	  					  is talking to.`,
	  		examples: ['Leave the note on the desk.<br>',
	  				   'Please stop doing that.<br>',
	  				   'Don\'t leave until you are finished.<br>',
	  				  ],
	  },

	  {
	  		helpId: 7, 
	  		helpCategory: 5, 
	  		title: 'Interrogative Sentence',
	  		answerStr: 'Interrogative',
	  		description: `An interrogative sentence asks a question and ends in a question mark.`,
	  		examples: ['Why are you so happy?<br>',
	  				   'Will your friends be coming to visit soon?<br>',
	  				   'How are we supposed to fit all these pieces together?<br>',
	  				  ],
	  },

	  {
	  		helpId: 8, 
	  		helpCategory: 5, 
	  		title: 'Exclamatory Sentence',
	  		answerStr: 'Exclamatory',
	  		description: `An exclamatory sentence expresses strong emotion and ends in an exclamation point.
	  					  Many sentences can be either a declarative or an exclamatory sentence so the 
	  					  presence of an ending exclamation or period helps the reader understand the meaning
	  					  more clearly.`,
	  		examples: ['I can\'t believe we won the game!<br>',
	  				   'There is a snake under the porch!<br>',
	  				   'We can\'t stay here any longer!<br>',
	  				  ],

	  },

	  {
	  		helpId: 9, 
	  		helpCategory: 5, 
	  		title: 'Imperative and Exclamatory Sentence',
	  		answerStr: 'Imperative and Exclamatory',
	  		description: `An imperative exclamatory sentence is one that is both an imperative sentence 
	  					  and an exclamatory sentence combined. It has no subject and ends in an exclamation
	  					  point. Because it expresses strong emotion, it typically expresses a command.<br><br>
	  					  Given the nature of it being a strong command, it isn't unusual for an imperative
	  					  exclamatory sentence to be very short, even just one word as in: "Stop!"`,
	  		examples: ['Be quiet this instant!<br>',
	  				   'Watch out!<br>',
	  				   'Help!<br>',
	  				  ],
	  },

	  {
	  		helpId: 10, 
	  		helpCategory: 5, 
	  		title: 'Linking Verb',
	  		answerStr: 'Linking',
	  		description: `A linking verb typically connects a subject with either an adjective or a noun
	  					  that describes the subject. A form of the verb 'be' is the most common 
	  					  linking verb, but there are others as well such as look, feel, and become.<br><br>
	  					  In the examples below, the linking verbs are underlined.`,
	  		examples: ['They <u>are</u> so happy!<br>',
	  				   'The team <u>seems</u> eager to get on with the game.<br>',
	  				   'My sister <u>became</u> an excellent musician after years of hard work.<br>'
	  				  ],
	  },

	  {
	  		helpId: 11, 
	  		helpCategory: 5, 
	  		title: 'Action Verb',
	  		answerStr: 'Action',
	  		description: `An action verb expresses something that someone or something can do. It may be a
	  					  physical action such as run, bark, or roll or it may express some other sort of 'doing'
	  					  such as think or change.<br><br>
	  					  In the examples below, the action verbs are underlined.`,
	  		examples: ['The dogs <u>barked</u> incessantly all night long!<br>',
	  				   'Many large stones suddenly <u>rolled</u> down the hill.<br>',
	  				   'The kids <u>thought</u> the story was over, but it wasn\'t.<br>',
	  				   'The colors of the leaves should be <u>changing</u> soon.<br>',
	  				  ],
	  },

	  {
	  		helpId: 12, 
	  		helpCategory: 5, 
	  		title: 'Intransitive Verb',
	  		answerStr: 'Intransitive',
	  		description: `An intransitive verb is an action verb that does not need an object to complete its meaning.<br><br>
	  					  In the examples below, the intransitive verbs are underlined.`,
	  		examples: ['We couldn\'t stop <u>laughing</u>!<br>',
	  				   'We <u>knocked</u> on the door, but no one <u>answered</u>.<br>',
	  				   'The mouse <u>slid</u> through the crack in the wall.<br>',
	  				  ],
	  },

	  {
	  		helpId: 13, 
	  		helpCategory: 5, 
	  		title: 'Transitive Verb',
	  		answerStr: 'Transitive',
	  		description: `A transitive verb is an action verb that must have an object to complete its meaning. It will 
	  					  have at least a direct object and may have other objects as well such as an indirect object 
	  					  or an adjective object complement.<br><br>
	  					  In the examples below, the transitive verbs are underlined.`,
	  		examples: ['The kids <u>climbed</u> the large tree in the backyard.<br>',
	  				   'She <u>gave</u> him a haircut last night.<br>',
	  				   'We <u>painted</u> the house a light shade of blue.<br>'
	  				  ],
	  },

	  {
	  		helpId: 14, 
	  		helpCategory: 5, 
	  		title: 'Predicate with Adverb Only',
	  		answerStr: 'Adverb Only',
	  		description: `This predicate pattern has only an adverb object that follows a linking verb.<br><br>
	  					  In the examples below, the verb phrase with linking verb is highlighted in italics and 
	  					  the adverb object is underlined.`,
	  		examples: ['Everyone <i>might be</i> <u>in the backyard</u>.<br>',
	  				   'The last show <i>will be</i> <u>on Sunday</u></u>.<br>',
	  				  ],
	  },

	  {
	  		helpId: 15, 
	  		helpCategory: 5, 
	  		title: 'Predicate with Predicate Adjective',
	  		answerStr: 'Predicate Adjective',
	  		description: `This predicate pattern includes a predicate adjective which is an adjective that follows 
	  					  a linking verb and modifies the subject. It may also include adverb objects.<br><br>
	  					  In the examples below, the verb phrase with linking verb is highlighted in italics and 
	  					  the complete predicate adjective is underlined.`,
	  		examples: ['The puppy <i>looked</i> <u>so sad</u> after they left.<br>',
	  				   'The sunset <i>is</i> <u>beautiful</u> today!<br>',
	  				   'The kids <i>had remained</i> <u>quiet and calm</u> until they saw the surprise.<br>',
	  				  ],
	  },

	  {
	  		helpId: 16, 
	  		helpCategory: 5, 
	  		title: 'Predicate with Predicate Nominative',
	  		answerStr: 'Predicate Nominative',
	  		description: `This predicate pattern includes a predicate nominative which is a noun object that 
	  					  follows a linking verb and renames the subject. It may also include adverb objects.<br><br>
	  					  In the examples below, the verb phrase with linking verb is highlighted in italics and 
	  					  the complete predicate nominative is underlined.`,
	  		examples: ['Her dog <i>is</i> <u>a golden retriever</u>.<br>',
	  				   'My best friend from elementary school <i>became</i> <u>a well-known artist</u>.<br>',
	  				   'She <i>is</i> <u>the one I told you about</u>.<br>',
	  				  ],
	  },

	  {
	  		helpId: 17, 
	  		helpCategory: 5, 
	  		title: 'Predicate with Intransitive Action Verb',
	  		answerStr: 'Intransitive Action Verb',
	  		description: `This predicate pattern includes only an intransitive action verb. It may also include adverb 
	  					  objects.<br><br>
		  				  In the examples below, the verb phrase with action verb is highlighted in italics.`,
	  		examples: ['<i>Stop</i> right now!<br>',
	  				   'They <i>dance</i> really well together.<br>',
	  				   'The door <i>had slammed</i> because of a strong gust of wind.<br>',
	  				  ],
	  },

	  {
	  		helpId: 18, 
	  		helpCategory: 5, 
	  		title: 'Predicate with Direct Object',
	  		answerStr: 'Direct Object',
	  		description: `This predicate pattern has an action verb followed by a direct object. 
	  					  It may also include adverb objects.<br><br>
	  					  In the examples below, the verb phrase with the action verb is highlighted in italics and the
	  					  direct object is underlined.`,
	  		examples: ['He accidentally <i>broke</i> <u>the glass</u>.<br>',
	  				   'The kids <i>climbed</i> <u>the large tree in the backyard</u>.<br>',
	  				   'My family <i>likes</i> <u>skiing in the mountains</u> every winter.<br>',
	  				  ],
	  },

	  {
	  		helpId: 19, 
	  		helpCategory: 5, 
	  		title: 'Predicate with Indirect Object and Direct Object',
	  		answerStr: 'Indirect Object and Direct Object',
	  		description: `This predicate pattern has an action verb followed by both an indirect object and a direct object. 
	  					  It may also include adverb objects.<br><br>
	  					  In the examples below, the verb phrase with the action verb is highlighted in italics, the
	  					  indirect object is highlighted in bold, and the direct object is underlined.`,
	  		examples: ['She <i>baked</i> <b>her father</b> <u>a cake</u> for his birthday.<br>',
	  				   'My father <i>gave</i> <b>him</b> <u>a haircut</u> last night.<br>',
	  				   'The doorman <i>handed</i> <b>me</b> <u>the wrong package</u> by mistake.<br>',
	  				  ],
	  },

	  {
	  		helpId: 20, 
	  		helpCategory: 5, 
	  		title: 'Predicate with Direct Object and Adjective Object Complement',
	  		answerStr: 'Direct Object and Adjective Object Complement',
	  		description: `This predicate pattern has an action verb followed by a direct object and an adjective  
	  					  object complement. It may also include adverb objects.<br><br>
	  					  In the examples below, the verb phrase with the action verb is highlighted in italics, the
	  					  direct object is underlined, and the complete adjective object complement is highlighted in bold.`,
	  		examples: ['They usually <i>keep</i> <u>their home</u> <b>spotless</b>.<br>',
	  				   'I <i>find</i> <u>this problem</u> <b>the most challenging of all</b>.<br>',
	  				   'He <i>makes</i> <u>me</u> <b>so mad</b> sometimes!<br>',
	  				  ],
	  },

	  {
	  		helpId: 21, 
	  		helpCategory: 5, 
	  		title: 'Predicate with Direct Object and Noun Object Complement',
	  		answerStr: 'Direct Object and Noun Object Complement',
	  		description: `This predicate pattern has an action verb followed by a direct object and a noun  
	  					  object complement. It may also include adverb objects.<br><br>
	  					  In the examples below, the verb phrase with the action verb is highlighted in italics, the
	  					  direct object is underlined, and the noun object complement is highlighted in bold.`,
	  		examples: ['The team <i>elected</i> <u>Tyrone</u> <b>captain</b>.<br>',
	  				   'They <i>named</i> <u>this movie</u> <b>the top picture of the year</b>.<br>',
	  				   'He <i>had called</i> <u>them</u> <b>a lot of harsh names</b> because he was angry.<br>',
	  				  ],
	  },

]
