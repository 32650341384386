import React, { useContext } from 'react';
import { GlobalContext } from 'contexts/GlobalContext'
import HelpItem from 'components/Help/HelpItem'
import Modal from 'styled-react-modal'
import styled from 'styled-components'


const GotItButton = styled.button`
  background-color: ${props => props.backgroundColor};
  width: 100px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  border-radius: 100px 100px 100px 100px;
  border: 1px solid #666666;
  padding: 5px 4px;
  margin: 10px 2px 10px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);  
  outline: none;
`;

const StyledModal = Modal.styled`
  max-width: 80%;
  max-height: 80%;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  border-radius: 5px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
`;

const HelpModal = ({showHelp, clearHelp, helpType, helpId}) => {

  const globals = useContext(GlobalContext)
  const color = globals.colors.get(Math.floor(Math.random() * globals.colors.size))
  const helpItemMargin = 2;

  return (
	  <StyledModal
	    isOpen={(showHelp)} 
	    onBackgroundClick={clearHelp}
	    onEscapeKeydown={clearHelp}
	  >
	    <HelpItem
	      helpType={helpType} 
	      helpId={helpId}
        addHistory={true}
        margin={helpItemMargin}
	    />
	    <GotItButton
	      backgroundColor={color}
	      onClick={clearHelp}
	    >
	      {"OK"}
	    </GotItButton>
	  </StyledModal>
  )
}

export default HelpModal