import React from 'react';
import HeaderNav from 'components/Main/HeaderNav'
import Home from 'components/Main/Home';
import Games from 'components/Main/Games';
import GoFundMe from 'components/Main/GoFundMe';
import Help from 'components/Help/Help';
import { useTracking } from 'hooks/useTracking'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { ModalProvider } from 'styled-react-modal'
import styled from 'styled-components'


const AppStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

function App() {
  useTracking()

  return (
    <ModalProvider>
      <HeaderNav />
      <AppStyle>
        <Switch>
          <Route path="/games" component={Games} />
          <Route path="/help" component={Help} />
          <Route path="/gofundme" component={GoFundMe} />
          <Route path="/" component={Home} />
        </Switch>
      </AppStyle>
    </ModalProvider>
  );
}

// eslint-disable-next-line  
export default () => (
  <Router>
    <App />
  </Router>
)
