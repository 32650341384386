import React from 'react';
import DisplaySentWord from './DisplaySentWord';
import { observer } from "mobx-react-lite";
import styled from 'styled-components';

const WordLineStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
`;

const DisplayWordLine = observer(({wordSet, lineNum, mouseDown, wordHandler}) => {

  const grammarWords = () => {
    // Linenum is zero-based
    if (lineNum < wordSet.lineBounds.length-1) {
      let [begInd, endInd] = wordSet.lineBounds.slice(lineNum, lineNum+2)
      let words = wordSet.sentenceWords.slice(begInd, endInd)
      return words.map((word) => 
        <DisplaySentWord key={word.position} word={word} mouseDown={mouseDown} wordHandler={wordHandler} />
      )
    }
  }

  return (
  	<WordLineStyle>
  	  {grammarWords()}
  	</WordLineStyle>
  )
})

export default DisplayWordLine;
