import React, { useContext, useCallback } from 'react';
import { GlobalContext } from 'contexts/GlobalContext'
import DisplayWordLine from './DisplayWordLine';
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import styled from 'styled-components';


const AllGrammarWordsStyle = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const DisplaySentWords = observer(({wordSet}) => {

  const globals = useContext(GlobalContext)
  const noHighlightColor = globals.noHighlightColor

  const invalidMouseDownPos = 500
  const [mouseDownPos, setMouseDownPos] = React.useState(invalidMouseDownPos)


  // Listen for a mouseUp event from anywhere in the document
  React.useEffect(() => {
    const handleDocumentMouseUp = event => {
      if (mouseDownPos !== invalidMouseDownPos) {
        if (event.button !== 2) {
          setTimeout(() => setMouseDownPos(invalidMouseDownPos), 10)

          // Checkpoint the color for all SentenceWords
          wordSet.sentenceWords.map(sentWord => sentWord.setCheckpoint1())

          // NOT SURE WE WANT TO SAVE THE WORDS HERE. MAYBE ONLY AT GAME QUESTION BOUNDARY INSTEAD
          // Use the defined callback if any to save the current state of the SentenceWords 
          if (wordSet.saveGrammarWords !== undefined) {
            wordSet.saveGrammarWords()
          }
        }
      }
    }

    document.addEventListener('mouseup', handleDocumentMouseUp)
    return () => {
      document.removeEventListener('mouseup', handleDocumentMouseUp)
    }
  })

  const wordHandler = useCallback((wordPos, eventType) => {

    const selectWord = action((wordPos, eventType) => {
      if (eventType === "down") {
        setMouseDownPos(wordPos)
      }

      let newColor = wordSet.curHighlightColor 
      if (wordSet.sentenceWords[wordPos].currentColor === wordSet.curHighlightColor) {
        // The word was already highlighted, so we're clearing the highlight instead
        newColor = undefined
      }

      wordSet.sentenceWords.forEach((word, ind) => {
        if (ind === wordPos) {
          if (newColor === undefined) {
            word.restoreCheckpoint2()
            word.isUnderlined = false
          } else {
            word.currentColor = newColor
            word.isUnderlined = true
          }
          if (eventType === "click") {
            word.setCheckpoint1()
          }
        }
      })
    })

    const setWordHighlight = action((endWordPos) => {

      const highlightColor = wordSet.curHighlightColor

      // The highlight at the mouseDownPos tells us if the player is setting the highlight or
      // restoring the previous colors since it has already been updated. The words that are included
      // in the dragging motion will all be highlighted or restored based on the status of that first
      // word that was selected. The words that are dragged over may be in different states, some 
      // highlighted and some not, but they will all be consistent with the first word once the 
      // mouse is lifted.
      let setHighlight = false
      if (wordSet.sentenceWords[mouseDownPos].currentColor === highlightColor) {
        setHighlight = true
      }

      wordSet.sentenceWords.forEach((word, ind) => {
        if (endWordPos > mouseDownPos) {
          if (ind > mouseDownPos && ind <= endWordPos) {
            // This word is covered by the dragging motion, so set it according to the first word.
            if (setHighlight) {
              word.currentColor = highlightColor
              word.isUnderlined = true
            } else {
              word.clearHighlight(highlightColor)
            }
          } else if (ind !== mouseDownPos) {
            // This word is not currently covered by the dragging motion, but it may have been previously, 
            // so we set it to what it was before the player started the current dragging motion, which may
            // or may not be a change. This does not affect the initial mouseDownPos in any case.
            word.restoreCheckpoint1()
          }
        } else if (endWordPos < mouseDownPos) {
            // This word is covered by the dragging motion, so set it according to the first word.
          if (ind < mouseDownPos && ind >= endWordPos) {
            if (setHighlight) {
              word.currentColor = highlightColor
              word.isUnderlined = true
            } else {
              word.clearHighlight(highlightColor)
            }
          } else if (ind !== mouseDownPos) {
            // This word is not currently covered by the dragging motion, but it may have been previously, 
            // so we set it to what it was before the player started the current dragging motion, which may
            // or may not be a change. This does not affect the initial mouseDownPos in any case.
            word.restoreCheckpoint1()      
          }
        } else if (endWordPos === mouseDownPos) {
          if (ind !== mouseDownPos) {
            // Only the initial mouseDownPos is now covered by the dragging motion. This word is not that
            // position, but it may have been previously affected by the dragging motion, so we set it to 
            // what it was before the player started the current dragging motion, which may or may not be 
            // a change. This does not affect the initial mouseDownPos in any case.
            word.restoreCheckpoint1()
          }
        }
      })
    })

    switch (eventType) {
      case "down":
      case "click":
        if (mouseDownPos === invalidMouseDownPos) {
          selectWord(wordPos, eventType)
        }
        break
      case "enter":
        setWordHighlight(wordPos)
        break
      default:
        break
    }
  // eslint-disable-next-line
  }, [mouseDownPos, noHighlightColor, wordSet.curHighlightColor, wordSet.sentenceWords])

  const grammarLines = () => {
    const lines = []
    for (let i=0; i < wordSet.lineBounds.length-1; i++) {
      lines.push(
           <DisplayWordLine 
            key={i} 
            wordSet={wordSet}
            lineNum={i}
            mouseDown={(mouseDownPos < wordSet.sentenceWords.length)}
            wordHandler={wordHandler}
          />
        )
    }
    return lines
  }

  return (
	  <AllGrammarWordsStyle>
	    {grammarLines()}
	  </AllGrammarWordsStyle>
  )
})

export default DisplaySentWords;
