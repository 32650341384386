// source: grammar_games_enums.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.grammar_games_protos.GameQuestionType', null, global);
goog.exportSymbol('proto.grammar_games_protos.GameType', null, global);
goog.exportSymbol('proto.grammar_games_protos.GramGamesErrorCode', null, global);
goog.exportSymbol('proto.grammar_games_protos.QuestionType', null, global);
/**
 * @enum {number}
 */
proto.grammar_games_protos.GramGamesErrorCode = {
  ERR_UNSPECIFIED: 0,
  ERR_REFRESH_REQUIRED: 1,
  ERR_SET_GAME_LEVEL_REQUIRED: 2,
  ERR_SERVER_ERR: 3
};

/**
 * @enum {number}
 */
proto.grammar_games_protos.GameType = {
  GAME_TYPE_UNSPECIFIED: 0,
  DISSECT_IT: 1,
  MAKE_THEM_AGREE: 2,
  FIND_THE_PATTERN: 3
};

/**
 * @enum {number}
 */
proto.grammar_games_protos.QuestionType = {
  QUESTION_TYPE_UNSPECIFIED: 0,
  BOOLEAN: 1,
  MULTICHOICE_BUTTON: 2,
  MULTICHOICE_RADIO: 3,
  HIGHLIGHT: 4,
  SYSTEM_HIGHLIGHT: 5,
  INPUT: 6
};

/**
 * @enum {number}
 */
proto.grammar_games_protos.GameQuestionType = {
  GAME_QUESTION_TYPE_UNSPECIFIED: 0,
  MTA_HIGHLIGHT_SUBJECT: 1,
  MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN: 2,
  MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_PRONOUN: 3,
  MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_PHRASE: 4,
  MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_COMPOUND_NOUN: 5,
  MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_APPOSITIVE: 6,
  MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_GERUND: 7,
  MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_INFINITIVE: 8,
  MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_GERUND_PHRASE: 9,
  MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_INFINITIVE_PHRASE: 10,
  MTA_IDENTIFY_SUBJECT_GRAM_ELEM_TYPE_NOUN_DEP_CLAUSE: 11,
  MTA_IS_SUBJECT_SING_OR_PLURAL_SING: 12,
  MTA_IS_SUBJECT_SING_OR_PLURAL_PLURAL: 13,
  MTA_IS_SUBJECT_1ST_2ND_3RD_1ST_SING: 14,
  MTA_IS_SUBJECT_1ST_2ND_3RD_2ND_SING: 15,
  MTA_IS_SUBJECT_1ST_2ND_3RD_3RD_SING: 16,
  MTA_IS_PREDICATE_COMPOUND_YES: 17,
  MTA_IS_PREDICATE_COMPOUND_NO: 18,
  MTA_WHAT_VERB_PHRASE_TYPE_BE: 19,
  MTA_WHAT_VERB_PHRASE_TYPE_AUX: 20,
  MTA_WHAT_VERB_PHRASE_TYPE_VERB: 21,
  MTA_HIGHLIGHT_VERB_PHRASE: 22,
  MTA_HIGHLIGHT_ALL_VERB_PHRASES: 23,
  MTA_IS_VERB_PHRASE_ADJUSTABLE_YES: 24,
  MTA_IS_VERB_PHRASE_ADJUSTABLE_NO: 25,
  MTA_IS_ANY_VERB_PHRASE_ADJUSTABLE_1_YES: 26,
  MTA_IS_ANY_VERB_PHRASE_ADJUSTABLE_2_YES: 27,
  MTA_IS_ANY_VERB_PHRASE_ADJUSTABLE_BOTH_YES: 28,
  MTA_IS_ANY_VERB_PHRASE_ADJUSTABLE_NO: 29,
  MTA_DO_SUBJECT_VERB_PHRASE_AGREE_YES: 30,
  MTA_DO_SUBJECT_VERB_PHRASE_AGREE_NO: 31,
  MTA_DO_ALL_SUBJECT_VERB_PHRASES_AGREE_YES: 32,
  MTA_DO_ALL_SUBJECT_VERB_PHRASES_AGREE_1_NO: 33,
  MTA_DO_ALL_SUBJECT_VERB_PHRASES_AGREE_2_NO: 34,
  MTA_DO_ALL_SUBJECT_VERB_PHRASES_AGREE_BOTH_NO: 35,
  MTA_HIGHLIGHT_INCORRECT_VERB: 36,
  MTA_INPUT_CORRECT_VERB: 37,
  MTA_HIGHLIGHT_ALL_INCORRECT_VERB: 38,
  MTA_INPUT_CORRECT_VERB_COMPOUND_1: 39,
  MTA_INPUT_CORRECT_VERB_COMPOUND_2: 40,
  MTA_SYSTEM_HIGHLIGHT_CLAUSE: 41,
  MTA_WHAT_TYPE_CLAUSE_INDEP: 42,
  MTA_WHAT_TYPE_CLAUSE_NOUN: 43,
  MTA_WHAT_TYPE_CLAUSE_ADJ: 44,
  MTA_WHAT_TYPE_CLAUSE_ADV: 45,
  MTA_HIGHLIGHT_DEP_CLAUSE_NOUN: 46,
  MTA_HIGHLIGHT_DEP_CLAUSE_ADJ: 47,
  MTA_HIGHLIGHT_DEP_CLAUSE_ADV: 48,
  MTA_HIGHLIGHT_INDEP_CLAUSE: 49,
  MTA_HIGHLIGHT_INDEP_CLAUSE_1: 50,
  MTA_HIGHLIGHT_INDEP_CLAUSE_2: 51,
  MTA_HAS_NOUN_DEP_CLAUSE_YES: 52,
  MTA_HAS_NOUN_DEP_CLAUSE_NO: 53,
  MTA_HAS_ADJ_DEP_CLAUSE_YES: 54,
  MTA_HAS_ADJ_DEP_CLAUSE_NO: 55,
  MTA_HAS_ADV_DEP_CLAUSE_YES: 56,
  MTA_HAS_ADV_DEP_CLAUSE_NO: 57,
  MTA_IS_COMPOUND_SENTENCE_YES: 58,
  MTA_IS_COMPOUND_SENTENCE_NO: 59,
  MTA_ADJ_DEP_CLAUSE_HAS_SUBJECT_YES: 60,
  MTA_ADJ_DEP_CLAUSE_HAS_SUBJECT_NO: 61,
  MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_INDEP_1: 62,
  MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_INDEP_2: 63,
  MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_NOUN: 64,
  MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_ADJ: 65,
  MTA_HIGHLIGHT_CLAUSE_AGREEMENT_ERR_ADV: 66,
  MTA_IS_THERE_AGREEMENT_ERR_YES: 67,
  MTA_IS_THERE_AGREEMENT_ERR_NO: 68,
  FTP_WHAT_SENT_STRUCTURE_SIMPLE: 100,
  FTP_WHAT_SENT_STRUCTURE_COMPOUND: 101,
  FTP_WHAT_SENT_STRUCTURE_COMPLEX: 102,
  FTP_WHAT_SENT_STRUCTURE_COMPOUND_COMPLEX: 103,
  FTP_WHAT_SENT_PURPOSE_DECLARATIVE: 104,
  FTP_WHAT_SENT_PURPOSE_EXCLAMATORY: 105,
  FTP_WHAT_SENT_PURPOSE_INTERROGATIVE: 106,
  FTP_WHAT_SENT_PURPOSE_IMPERATIVE: 107,
  FTP_WHAT_SENT_PURPOSE_IMPERATIVE_EXCLAMATORY: 108,
  FTP_HIGHLIGHT_INDEP_CLAUSE: 109,
  FTP_HIGHLIGHT_FIRST_INDEP_CLAUSE: 110,
  FTP_HIGHLIGHT_SECOND_INDEP_CLAUSE: 111,
  FTP_HIGHLIGHT_DEP_CLAUSE__NOUN: 112,
  FTP_HIGHLIGHT_DEP_CLAUSE__ADJ: 113,
  FTP_HIGHLIGHT_DEP_CLAUSE__ADV: 114,
  FTP_HIGHLIGHT_NOUN_DEP_CLAUSE: 115,
  FTP_HIGHLIGHT_ADJ_DEP_CLAUSE: 116,
  FTP_HIGHLIGHT_ADV_DEP_CLAUSE: 117,
  FTP_HIGHLIGHT_FIRST_NOUN_DEP_CLAUSE: 118,
  FTP_HIGHLIGHT_SECOND_NOUN_DEP_CLAUSE: 119,
  FTP_HIGHLIGHT_FIRST_ADJ_DEP_CLAUSE: 120,
  FTP_HIGHLIGHT_SECOND_ADJ_DEP_CLAUSE: 121,
  FTP_HIGHLIGHT_FIRST_ADV_DEP_CLAUSE: 122,
  FTP_HIGHLIGHT_SECOND_ADV_DEP_CLAUSE: 123,
  FTP_HIGHLIGHT_SENT_SUBJECT: 124,
  FTP_HIGHLIGHT_CLAUSE_SUBJECT: 125,
  FTP_IS_COMPOUND_PRED__YES: 126,
  FTP_IS_COMPOUND_PRED__NO: 127,
  FTP_HIGHLIGHT_SENT_PREDICATE: 128,
  FTP_HIGHLIGHT_CLAUSE_PREDICATE: 129,
  FTP_HIGHLIGHT_COMPOUND_1ST_PREDICATE: 130,
  FTP_HIGHLIGHT_COMPOUND_2ND_PREDICATE: 131,
  FTP_HIGHLIGHT_VERB_PHRASE: 132,
  FTP_VERB_ACTION_OR_LINKING__LINKING: 133,
  FTP_VERB_ACTION_OR_LINKING__ACTION: 134,
  FTP_VERB_TRANS_OR_INTRANS__INTRANS: 135,
  FTP_VERB_TRANS_OR_INTRANS__TRANS: 136,
  FTP_LINKING_PRED_BE_HAS__ADVERB_ONLY: 137,
  FTP_LINKING_PRED_BE_HAS__PRED_ADJ: 138,
  FTP_LINKING_PRED_NONBE_HAS__PRED_ADJ: 139,
  FTP_LINKING_PRED_BE_HAS__PRED_NOM: 140,
  FTP_LINKING_PRED_NONBE_HAS__PRED_NOM: 141,
  FTP_SINGLE_NOUN_PRED_HAS__PRED_NOM: 142,
  FTP_SINGLE_NOUN_PRED_HAS__DO: 143,
  FTP_ACTIVE_PRED_HAS__DO: 144,
  FTP_ACTIVE_PRED_HAS__IO: 145,
  FTP_ACTIVE_PRED_HAS__ADJ_COMP: 146,
  FTP_ACTIVE_PRED_HAS__NOUN_COMP: 147,
  FTP_ANY_PRED_BE_HAS__ADVERB_ONLY: 148,
  FTP_ANY_PRED_BE_HAS__PRED_ADJ: 149,
  FTP_ANY_PRED_NONBE_HAS__PRED_ADJ: 150,
  FTP_ANY_PRED_BE_HAS__PRED_NOM: 151,
  FTP_ANY_PRED_NONBE_HAS__PRED_NOM: 152,
  FTP_ANY_PRED_HAS__INTRANS: 153,
  FTP_ANY_PRED_HAS__DO: 154,
  FTP_ANY_PRED_HAS__IO: 155,
  FTP_ANY_PRED_HAS__ADJ_COMP: 156,
  FTP_ANY_PRED_HAS__NOUN_COMP: 157,
  FTP_HIGHLIGHT_PRED_ADJ: 158,
  FTP_HIGHLIGHT_PRED_NOM: 159,
  FTP_HIGHLIGHT_INDIR_OBJ: 160,
  FTP_HIGHLIGHT_DIR_OBJ_ONLY: 161,
  FTP_HIGHLIGHT_DIR_OBJ: 162,
  FTP_HIGHLIGHT_ADJ_COMPLEMENT: 163,
  FTP_HIGHLIGHT_NOUN_COMPLEMENT: 164
};

goog.object.extend(exports, proto.grammar_games_protos);
